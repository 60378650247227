import { Box } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import CaseService from "api/services/caseService";
import { useMaterialTable } from "../providers/hooks/useMaterialTable";
import CustomMaterialTable from "./table/CustomMaterialTable";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const HospitalVisitRecords = ({ account_no, unsetModal }) => {

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.getAccountHospitalVisitRecords(params).then((response) => {
          console.log(response);
					resolve({
						status: response.status,
						data: response.data.debtor_hospital_visits,
						count: response.data?.count,
						pagination: response?.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Hospital Visit Records</Title>
            </Box>
            
            <Box>
                <CustomMaterialTable
                    title={null}
                    tableRef={tableRef}
                    columns={[
                        
                        {
                            title: "Visit Number",
                            field: "visit_number",
                            render: (rowData) => rowData.visit_number 
                        },
                        {
                            title: "Amount",
                            field: "amount_owing",
                        },
                        {
                            title: "Date",
                            field: "date_modified",
                        },

                    ]}
                    data={remoteDataSource}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                    }}
                />
            </Box>
        </Box>
    );
};

export default HospitalVisitRecords;