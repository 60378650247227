import * as ACTION_TYPES from '../types/auth.types';

const loginSuccess = (payload) => {
    return {
        type: ACTION_TYPES.LOGIN_SUCCESS,
        payload: payload,
    }
}

const unsetPermissions = () => {
    return {
        type: ACTION_TYPES.UNSET_PERMISSIONS,
    };
};

export { loginSuccess, unsetPermissions }