import * as ACTION_TYPES from "../types/nav.types";

const initialState = {
    modal: {
        Component: null,
        size: "full",
        canClickAway: true,
    },
    toast: { message: null, type: null },
    loading: false,
    persistent: {
        Component: null,
        position: { vertical: "bottom", horizontal: "right" },
    },
};

const navReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_MODAL:
            return { ...state, modal: { ...initialState.modal, ...action.payload } };
        case ACTION_TYPES.UNSET_MODAL:
            return { ...state, modal: initialState.modal };
        case ACTION_TYPES.SET_TOAST:
            return { ...state, toast: action.payload };
        case ACTION_TYPES.UNSET_TOAST:
            return { ...state, toast: { message: null, type: null } };
        case ACTION_TYPES.SET_PERSISTENT:
            return { ...state, persistent: action.payload };
        case ACTION_TYPES.TOGGLE_LOADING:
            return { ...state, loading: !state.loading };
        case ACTION_TYPES.UNSET_PERSISTENT:
            return {
                ...state,
                persistent: initialState.persistent,
            };
        default:
            return state;
    }
};

export default navReducer;
