import React from "react";

import BluePillButton from "components/buttons/BluePillButton";
import phone_icon from "../../../assets/icons/FontAwsome (phone-alt).png";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { MTableCell } from "material-table";
import VoipCall from "components/modal/content/VoipCall";
import { useModal } from "providers/hooks/useModal";
import { MaterialTableCurrencySettings } from "constants/locale";
import { useHistory, useLocation } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../../constants/pathNames";
import { useSelector } from "react-redux";


function NextCallTable({ data, tableRef, client}) {
  const history = useHistory();
  const { pathname, state: locationState } = useLocation();
  const { setPersistent } = useModal();
	const { id: agent_id } = useSelector((state) => state.auth);

  const handleRowClick = (event, row) => {
    const location = {
      pathname: `${DASHBOARD}${DEBTOR}/${row.debtor_name}`,
      state: {
        debtor_name: row.debtor_name,
        account_number: row.account_number,
        client_id: client,
        campaign_summary: true,
      },
    };
    history.push(location);
  };

  const handlePhone = (account_no, phone_number) => {
		setPersistent({
			Component: (
				<VoipCall
					phone_number={phone_number}
					account_number={account_no}
				/>
			),
		});
	};

  return (
    <CustomMaterialTable
      tableRef={tableRef}
      columns={[
        { title: "ID", field: "id", hidden: true },
        { title: "Next Call", field: "debtor_name" },
        { field: "account_type", align: "left" },
        { field: "account_number" },
        {
          field: "reference_number",
        },
        {
          field: "capital",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
        },
        {
          field: "interest",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
        },
        {
          field: "fees",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
        },
        {
          field: "legal_fees",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
        },
        {
          field: "settlement",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
        },
        { field: "" },
        {
          disableClick: true,
          render: (props) => (
            <BluePillButton
              style={{ height: "28px" }}
              onClick={() => handlePhone(props.account_no, props.phone_number, agent_id)}
            >
              <img
                src={phone_icon}
                style={{ height: "20px", margin: "0.2rem" }}
                alt="phone"
              />
              Call Debtor
            </BluePillButton>
          ),
        },
      ]}
      data={data}
      onRowClick={handleRowClick}
      options={{
        paging: false,
        pageSize: 0,
        search: true,
        toolbar: false,
        padding: "dense",
        doubleHorizontalScroll: false,
        headerStyle: { padding: "0px" },
        rowStyle: {
          backgroundColor: "#d4f5d7",
        },
      }}
      components={{
        Container: (props) => <div {...props} style={{ padding: 0 }} />,
        Cell: (props) => <MTableCell {...props} style={{ padding: 0 }} />,
      }}
    />
  );
}

export default NextCallTable;
