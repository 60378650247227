import { object } from "yup";

const TwilioService = {
	_url: process.env.REACT_APP_API_BASE_URL,

	_token(token) {
		if (typeof token !== "undefined") {
			sessionStorage.setItem("token", token);

			return;
		}

		return sessionStorage.getItem("token");
	},

	getTaskRouterToken(worker_id) {
		return new Promise((resolve, reject) => {
			// dont return the fetch, you should use the resolve/reject functions
			fetch(`${this._url}/twilio/task-router-token`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({worker_id: worker_id}),
				
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						const returnData = {
							token: res.token,
						};
						resolve(returnData);
					} else {
						resolve(res);
					}
				});
		});
	},

	getToken(worker_id) {
		return new Promise((resolve, reject) => {
			// dont return the fetch, you should use the resolve/reject functions
			fetch(`${this._url}/twilio/token`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({worker_id: worker_id}),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						const returnData = {
							token: res.token,
						};
						resolve(returnData);
					} else {
						resolve(res);
					}
				});
		});
	},

	call(object) {
		// the promise gives you 2 functions to call on success or failure
		return new Promise((resolve, reject) => {
			// dont return the fetch, you should use the resolve/reject functions
			fetch(`${this._url}/twilio/outbound-call`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(object),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						const returnData = {
							data: res.data,
						};
						resolve(returnData);
					} else {
						resolve(res);
					}
				});
		});
	},

	recordCall(params){
		console.log("logging params: ", params);
		return new Promise((resolve, reject) => {
			// dont return the fetch, you should use the resolve/reject functions
			fetch(`${this._url}/twilio/call-record`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify(params),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						const returnData = {
							data: res.data,
						};
						resolve(returnData);
					} else {
						resolve(res);
					}
				});
		});
	},

	cancelTask(task){
		console.log("task...:", task);
		return new Promise((resolve, reject) => {
			// dont return the fetch, you should use the resolve/reject functions
			fetch(`${this._url}/twilio/cancel-task`, {
				method: "POST",
				headers: {
					"content-type": "application/json",
				},
				body: JSON.stringify({task: task}),
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						const returnData = {
							data: res.data,
						};
						resolve(returnData);
					} else {
						resolve(res);
					}
				});
		});
	}
};

export default TwilioService;
