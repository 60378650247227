import * as ACTION_TYPES from "../types/nav.types";

const setModal = (payload) => {
    return {
        type: ACTION_TYPES.SET_MODAL,
        payload: payload,
    };
};

const unsetModal = () => {
    return {
        type: ACTION_TYPES.UNSET_MODAL,
    };
};

const setPersistent = (payload) => {
    return {
        type: ACTION_TYPES.SET_PERSISTENT,
        payload: payload,
    };
};

const unsetPersistent = () => {
    return {
        type: ACTION_TYPES.UNSET_PERSISTENT,
    };
};

const setToast = ({ ...payload }) => {
    return {
        type: ACTION_TYPES.SET_TOAST,
        payload: payload,
    };
};

const unsetToast = () => {
    return {
        type: ACTION_TYPES.UNSET_TOAST,
    };
};

const toggleLoading = () => {
    return {
        type: ACTION_TYPES.TOGGLE_LOADING,
    };
};

export { setModal, unsetModal, setToast, unsetToast, setPersistent, unsetPersistent, toggleLoading };
