import EditSelect from "components/table/components/EditSelect";
import { useModal } from "providers/hooks/useModal";
import React from "react";
import {
	MuiThemeProvider,
	TextField,
} from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import AddEvent from "components/modal/content/AddEvent";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import cancel_icon from "../../assets/icons/Cancel.png";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import delete_icon from "../../assets/icons/Layer -14.png";
import save_icon_orange from "../../assets/icons/Layer -16.png";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import calendar_icon from "../../assets/icons/FontAwsome (calendar-alt).png";
import { MaterialTableCurrencySettings } from "../../constants/locale";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import CaseService from "../../services/caseService";
import { dateFormat } from "../../utils/dateFormat";
import tableTheme from "../../components/table/Styles/TableTheme";

const schema = Yup.object().shape({
	category: Yup.object()
		.shape({ id: Yup.string() })
		.typeError("Select a category.")
		.required("Select a category."),
	status: Yup.object()
		.shape({ id: Yup.string() })
		.typeError("Select a status.")
		.required("Select a status."),
	type: Yup.object()
		.shape({ id: Yup.string() })
		.typeError("Select a type.")
		.required("Select a type."),
	note_date: Yup.date(),
	agent_name: Yup.string(),
	amount: Yup.number()
		.typeError("Please enter a number value.")
		.required("Amount is required."),
	note: Yup.string().required("Note is required."),
	// attachment: Yup.string(),
});

function Notes({ account_status, account_no, agent_id, debtor_id, client_id, dispatch, tracingUpdate, activeItem }) {
	const { setModal } = useModal();
	const { state: locationState } = useLocation();
	const [categories, setCategories] = React.useState([]);
	const [statuses, setStatuses] = React.useState([]);
	const [types, setTypes] = React.useState([]);

	const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.debtorCaseNotes(params).then((response) => {
					if (response.data.categories != null) {
						setCategories([...response.data.categories]);
					}
					if (response.data.status != null) {
						setStatuses([...response.data.status]);
					}
					if (response.data.types != null) {
						setTypes([...response.data.types]);
					}
					resolve({
						status: response.status,
						data: response.data.notes,
						count: response.data.count,
						pagination: response.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

	const EDITABLE = {
		onRowAdd: (newRow) =>
			CaseService.addDebtorCaseNote({
				account_no,
				agent_id,
				...newRow,
			}),
	};

	const ICONS = {
		Add: (props, ref) => (
			<BluePillButton ref={ref} variant='contained'>
				+ Note
			</BluePillButton>
		),
		Check: forwardRef((props, ref) => (
			<img
				src={save_icon_orange}
				alt='save'
				ref={ref}
				{...props}
			/>
		)),
		Clear: forwardRef((props, ref) => (
			<img
				src={cancel_icon}
				alt='save'
				ref={ref}
				{...props}
			/>
		)),
		Edit: forwardRef((props, ref) => (
			<img src={edit_icon} alt='edit' ref={ref} {...props} />
		)),
		Delete: forwardRef((props, ref) => (
			<img
				src={delete_icon}
				alt='delete'
				ref={ref}
				{...props}
			/>
		)),
	}

	React.useEffect(() => {
		if (tableRef.current != null) {
			setParams({ account_no })
		}
	}, [locationState]);

	React.useEffect(() => {
		if(tracingUpdate && activeItem === "notes"){
			setParams({ account_no })
			dispatch();
		}
	}, [activeItem, tracingUpdate]);

	return (
		<MuiThemeProvider theme={tableTheme}>
			<MaterialTable
				tableRef={tableRef}
				title={null}
				columns={[
					{
						cellStyle: {
							paddingLeft: '25px',
						},
						headerStyle: {
							paddingLeft: '25px',
						},
						title: "Date",
						field: "note_date",
						render: (row) =>
							row?.note_date != null
								? dateFormat(row.note_date)
								: "-",
						// type: "date",
						// initialEditValue: new Date(),
						editable: false
					},
					{
						title: "Category",
						field: "category",
						render: (row) => row.category?.name ?? "-",
						editComponent: (params) => (
							<EditSelect params={params} options={categories} />
						),
						width: "200px",
					},
					{
						title: "Status",
						field: "status",
						render: (row) => row.status?.name ?? "-",
						editComponent: (params) => (
							<EditSelect params={params} options={statuses} />
						),
						width: "200px",
					},
					{
						title: "Type",
						field: "type",
						render: (row) => row.type?.name ?? "-",
						editComponent: (params) => (
							<EditSelect params={params} options={types} />
						),
						width: "200px",
					},
					{
						title: "Amount",
						field: "amount",
						type: "currency",
						align: "left",
						initialEditValue: 0,
						currencySetting: MaterialTableCurrencySettings,

					},
					{
						title: "Agents Name",
						field: "agent_name",
						editable: false,
					},
					{
						title: "Note",
						field: "note",
						type: "textArea",
					},
				]}
				validationSchema={schema}
				data={remoteDataSource}
				actions={[
					{
						icon: () => <img alt="calendar icon" src={calendar_icon} width={25} />,
						tooltip: 'Add Note to Calendar',
						onClick: (event, rowData) => {
							const data = {
								note: rowData.note,
								client_id: client_id,
								debtor_id: debtor_id,
								title: rowData.note_date.split('T')[0] + '-' + rowData.category?.name
							}
							setModal({
								Component: <AddEvent source={'notes'} notes_data={data} />,
								size: "sml",
							})
						}
					}
				]}
				editable={account_status === 1 && EDITABLE}
				options={{
					search: false,
					toolbarButtonAlignment: "left",
					actionsColumnIndex: -1,
				}}
				icons={account_status === 1 && ICONS}

				components={{

					EditField: (props) => {
						switch (props.columnDef.type) {
							case "textArea":
								return (
									<TextField
										{...props}
										onChange={(e) =>
											props.onChange(e.target.value)
										}
										multiline
										rows={2}
										maxRows={4}
										variant='outlined'
									/>
								);
							default:
								return (
									<TextField
										{...props}
										onChange={(e) =>
											props.onChange(e.target.value)
										}
										variant='outlined'
									/>
								);
						}
					},
				}}
			/>
		</MuiThemeProvider>
	);
}

export default Notes;
