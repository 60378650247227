import React from "react";
import { Menu, MenuItem, Toolbar } from "@material-ui/core";
import { useModal } from "providers/hooks/useModal";
import ReportsDownload from "./modal/content/ReportsDownload";
import BluePillButton from "./buttons/BluePillButton";
import { ArrowDropDown } from "@material-ui/icons";
import BulkEmailTemplate from "./modal/BulkEmailTemplate";
import BulkSms from "./modal/BulkSms";
import AssignDebtors from "./modal/content/AssignDebtors";
import { useAuth } from "providers/hooks/useAuth";

function DebtorRegisterActionMenu({ client_id, accounts }) {
    const { setModal, unsetModal, setToast } = useModal();
    const { agent_id } = useAuth(); 
    const [menuAnchor, setMenuAnchor] = React.useState();
    const [selection, setSelection] = React.useState(false); // have to manually control table selection state (use header dispatch for control)


    const handleModalEvent = ({ type, payload = undefined }) => {
        console.log("handleModalEvent", type);
        switch (type) {
            case "preparing_download":
                unsetModal();
                setToast("Preparing download...", "info");
                break;
            case "download_error":
                unsetModal();
                setToast("We ran into an error fetching this report.", "error");
                break;
            case "download":
                setToast("Downloading...", "info");
                break;
            default:
                unsetModal();
        }
    };
    const handleNewPtp = (e) => {
        setMenuAnchor(e.target);
    };
    const handleReportTypeSelect = (e) => {
        setMenuAnchor(false);
        switch (e.target.id) {
            case "bulk-email":
                setModal({
                    Component: <BulkEmailTemplate accounts={accounts} />,
                });
                break;
            case "bulk-sms":
                setModal({
                    Component: <BulkSms accounts={accounts} />,
                });
                break;
            case "other":
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Toolbar>
                <BluePillButton variant="contained" onClick={handleNewPtp}>
                    Select Option <ArrowDropDown />
                </BluePillButton>
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    keepMounted
                    onClose={() => setMenuAnchor(undefined)}
                >
                    <MenuItem id="bulk-email" onClick={handleReportTypeSelect}>
                        Bulk Email
                    </MenuItem>
                    <MenuItem id="bulk-sms" onClick={handleReportTypeSelect}>
                        Bulk SMS
                    </MenuItem>
                </Menu>
            </Toolbar>
        </div>
    );
}

export default DebtorRegisterActionMenu;
