import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { AgentService } from "../../../api/services";
import { useModal } from "../../../providers/hooks/useModal";
import ButtonComponent from "../../ButtonComponent";

const AgentAssignedClients = ({ agent, onUnassign }) => {
    const { unsetModal, setToast } = useModal();
    const [selected, setSelected] = useState([]);

    const toggleItemSelected = (item) => {
        let s = [...selected];
        if (selected.filter((i) => i.id === item.id).length) {
            const temp = s.filter((i) => i.id !== item.id);
            s = temp;
        } else {
            s.push(item);
        }
        setSelected(s);
    };

    const unassignClients = async () => {
        const resp = await AgentService.unassignClients({ agent_id: agent.id, client_ids: selected });
        if (resp.status === "success") {
            unsetModal();
            onUnassign();
        }
        setToast(resp.message, "success");
    };

    return (
        <div className={"flex col jc-sb h-100 p-2"} style={{ minWidth: 300 }}>
            <div>
                <h2 className={"bold m-0 color-primary_dark"}>Agents' Clients</h2>
                <h3>
                    {agent.name} {agent.surname}
                </h3>
            </div>
            <div>
                {agent.clients?.map((client) => (
                    <div className={"flex roc ai-c"}>
                        <Checkbox onClick={() => toggleItemSelected(client)} />
                        <h5 className={"m-0"} style={{ paddingTop: 5 }}>
                            {client.name}
                        </h5>
                    </div>
                ))}
            </div>
            <div className={"w-100 ta-c mt-2"}>
                <ButtonComponent
                    variant={"outlined"}
                    width={280}
                    title={"Unassign"}
                    disable={selected.length ? false : true}
                    handler={unassignClients}
                />
            </div>
        </div>
    );
};

export default AgentAssignedClients;
