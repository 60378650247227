import React, { useEffect, useState } from "react";
import { AgentService } from "../../../api/services";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { DEBTOR, DASHBOARD } from "../../../constants/pathNames";
import { useHistory, useLocation } from "react-router-dom";

import { CAMPAIGN_FIELDS } from "../../../constants/tableColumns";

function CampaignSummary({ params }) {
  const history = useHistory();

  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) =>
      new Promise((resolve, reject) => {
        AgentService.getAgentsCampaignSummary(params).then((response) => {
          resolve({
            status: response.status,
            data: response.data?.debtors,
            count: response.data?.count,
            pagination: response.pagination,
          });
        });
      }),
    initParams: {},
  });

  const handleRowClick = (event, row) => {
    const location = {
      pathname: `${DASHBOARD}${DEBTOR}/${row.debtor_name}`,
      state: {
        debtor_name: row.debtor_name,
        account_number: row.account_number,
        // client_id: getClientById(currentTable, clients),
        // campaign_summary: true, // for debtor-detail to know where user is coming from
      },
    };
    history.push(location);
  };

  useEffect(() => {
    setParams(params);
  }, [tableRef]);

  return (
    <div>
      <CustomMaterialTable
        title={null}
        tableRef={tableRef}
        columns={CAMPAIGN_FIELDS}
        data={remoteDataSource}
        onRowClick={handleRowClick}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          search: true,
        }}
      />
    </div>
  );
}

export default CampaignSummary;
