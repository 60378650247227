import React, {useState} from 'react'
import styled from 'styled-components'
import { colors } from '../constants/styles';
import ButtonComponent from './ButtonComponent';
import SelectDate from './SelectDate';
import { format } from 'date-fns'; 

const SwitcherWrap = styled.div`
  width: 400px;
  border-radius: 16px 16px 0px 0px; 
  border-top: 1px solid ${colors.blue}; 
  border-left: 1px solid ${colors.blue}; 
  border-right: 1px solid ${colors.blue}; 
  display: flex; 
  align-items: center;
  font-family: 'Avenir Next';
  justify-content: space-around;
  cursor: pointer;
  margin-right: 5px;
  @media (min-width:1920px) {
    width: 468px;
    margin-right: 10px;
  }
`;

const SectionCalendar=styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0px 5px;
  div{
    display: flex;
    align-items: center;
  }
`;


function TimeFilter({handler, disable=false}) {
  const [listDates, setListDates] = useState({from: new Date(), to: new Date()});

  return (
    <SwitcherWrap>
      <SectionCalendar>
        <div>
          <SelectDate 
            title={`From ${format(listDates.from, 'do MMM')}`} 
            updateTitle={(date) => setListDates({...listDates, from: date})}/>
        </div>
        <div>
         <SelectDate 
           title={`To ${format(listDates.to, "d MMM")}`}
           updateTitle={(date) => setListDates({...listDates, to: date})}/>
        </div>
        <div>
          <ButtonComponent
            height={30}
            width={89} 
            fontSize={20}
            disable={disable}
            title="Filter"
            handler={() => handler(listDates)}/>
        </div>

      </SectionCalendar>
    </SwitcherWrap>
  )
}

export default TimeFilter
