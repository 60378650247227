import * as api from "..";
import { CLIENT } from "../config/endpoints";

// const ClientService = {
//     _url: process.env.REACT_APP_API_BASE_URL,

//     _token(token) {
//         if (typeof token !== 'undefined') {
//             sessionStorage.setItem('token', token);

//             return;
//         }

//         return sessionStorage.getItem('token');
//     },

//     register(params) {
//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/clients/client_register`, {
//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data.clients,
//                             count: res.data.count,
//                             status: res.status,
//                             date:  Date.now()
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });
//     },

//     details(clientId) {

//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/clients/get/${clientId}`, {
//                 method: 'GET',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 }
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         res.data.address = nl2br(res.data.address);
//                         const returnData = {
//                             data: res.data,
//                             status: res.status
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }

//                 });
//         });
//     },

//     fees(params) {
//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/clients/fees/crud_list`, {
//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data.fees,
//                             count: res.data.count,
//                             status: res.status
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });
//     },

//     fees_crud(params, crud) {
//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/clients/fees/${crud}`, {
//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data,
//                             status: res.status,
//                             message: res.message
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });
//     },

//     actions(params, crud) {
//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/clients/${crud}`, {
//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data,
//                             status: res.status
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });
//     },

//     update_client(params) {
//         let result = axios.post(`${this._url}/clients/update`, params, { headers: { Authorization: `Bearer ${this._token()}` } })
//         .then(function (response) {
//           if(response.data?.status === "success") {
//               return {
//                   data: response.data?.data,
//                   message: response.data?.message,
//                   status: response.data?.status,
//               }
//           }
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//         return result;
//       },

//     create(params) {
//       let result = axios.post(`${this._url}/clients/create`, params, { headers: { Authorization: `Bearer ${this._token()}` } })
//       .then(function (response) {
//         if(response.data?.status === "success") {
//             return {
//                 data: response.data?.data,
//                 message: response.data?.message
//             }
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//       return result;
//     }

// }

const ClientService = {
  async getClient(id) {
    const resp = await api.get(`${CLIENT.GET_CLIENT}/${id}`);
    return resp;
  },
  async getClientManager({ filter, pagination, sort }) {
    const resp = await api.post(CLIENT.CLIENT_REGISTER, ...arguments);
    return resp;
  },
  async getClientDebtorRegister({ client_id, pagination, filter, sort }) {
    const resp = await api.post(CLIENT.DEBTOR_REGISTER, ...arguments);
    return resp;
  },
  async create(formData) {
    const resp = await api.asFormData(CLIENT.CREATE, formData);
    return resp;
  },
  async update(formData) {
    const resp = await api.asFormData(CLIENT.UPDATE, formData);
    return resp;
  },
  async delete({ client_id }) {
      const resp = await api.post(CLIENT.DELETE, ...arguments);
      return resp;
  },
};

export default ClientService;
