import React, { useState } from "react";
import TextInput from "components/inputs/TextInput";
import ButtonLoader from "components/buttons/ButtonLoader";
import { AuthService } from "api/services";
import { useModal } from '../../../providers/hooks/useModal';
import ButtonComponent from '../../ButtonComponent';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { unsetModal } = useModal();

  const submit = async () => {
    setSubmitting(true);
    const resp = await AuthService.sendResetPasswordLink(email);
    if (resp.status === "success") {
      setSubmitSuccess(true);
    } else {
      setError(resp.message);
    }
    setSubmitting(false);
  };

  return (
    <div className={"flex col ai-c jc-sb p-1 ph-3"} style={{width: 400}}>
      {!submitSuccess ? (
        <>
          <h1 className={'mv-2 colour-blue bold'}>Forgot Password</h1>
          <h3 className={'mt-1 mb-3 colour-blue'}>Please enter your email address below</h3>
          <TextInput
            name={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={error}
            placeholder={'Email'}
            className={'mb-2 w-100'}
          />
          <ButtonLoader
            title={"Send Email"}
            handler={submit}
            loading={submitting}
          />
        </>
      ) : <>
        <h1 className={'mv-2 colour-blue bold'}>Thank You</h1>
        <h3 className={'mt-1 mb-3 colour-blue ta-c'}>We have sent you an email with a password reset link, please follow the instrutions in the mail.</h3>
        <ButtonComponent title={'Continue'} handler={unsetModal} />
      </>}
    </div>
  );
};

export default ForgotPassword;
