import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import clsx from "clsx";
import { useModal } from "providers/hooks/useModal";
import { useVoip } from "providers/hooks/useVoip";
import React, { useState } from "react";
import close_icon from "../../../../assets/icons/Group 414.png";
import call_icon from "../../../../assets/icons/Group_call.png";
import end_icon from "../../../../assets/icons/Group_call2.png";
import mute_icon from "../../../../assets/icons/Layer -28.png";
import styles from "./styles.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../../../constants/pathNames";
import BluePillButton from "components/buttons/BluePillButton";

const DebtorButton = styled(BluePillButton)`
  overflow: hidden;
  float: right;
  fontweight: bold;
  margin-bottom: 10px;
`;

const Inbound = ({ callConnection, callReservation }) => {

  const {
    deviceState,
    workerState,
  } = useVoip();

  const { unsetPersistent } = useModal();
  const [connection, setConnection] = useState(callConnection);
  const [callInfo, setCallInfo] = React.useState();
  const [reservation, setReservation] = useState(callReservation);
  // const [taskSid, setTaskSid] = React.useState(null);
  const { pathname, state: locationState } = useLocation();
  const history = useHistory();

  const disconnect = () => {
    workerState?.completeTask(reservation.task.sid, function (error, completedTask) {
      if (error) {
        console.log(error.code);
        console.log(error.message);
        return;
      }
      console.log("Completed Task: " + completedTask.assignmentStatus);
    });
    if (connection != null) {
      connection.disconnect();
      setCallInfo((state) => ({
        ...state,
        status: "Call Ended.",
      }));
    }
    deviceState.disconnectAll();
    setConnection(null);
    setReservation(null);
  };

  const reject = () => {
    if (connection != null) {
      connection.reject();
      setCallInfo((state) => ({
        ...state,
        status: "Call Ended.",
      }));
    }
  };

  const mute = () => {
    if (connection != null) {
      connection.mute(!connection.isMuted());
    }
  };

  const callResponse = () => {
    connection?.accept();
  };

  const closeCall = () => {
    disconnect();
    unsetPersistent();
  };

  const showActionButtons = (conn) => {
    let call_status = conn?.status();
    switch (call_status) {
      case "pending":
        return (
          <>
            <IconButton
              className={styles.voipActionsButton}
              onClick={reject}
            >
              <img src={end_icon} alt="end call" width={50} />
            </IconButton>

            <IconButton
              disabled={!(conn != null)}
              className={styles.voipActionsButton}
              onClick={callResponse}
            >
              <img src={call_icon} alt="answer call" width={50} />
            </IconButton>
          </>
        );
      case "open":
        return (
          <>
            <IconButton
              className={styles.voipActionsButton}
              onClick={closeCall}
            >
              <img src={end_icon} alt="end call" width={50} />
            </IconButton>
          </>
        );
      case "closed":
        // disconnect();
        unsetPersistent();
        break;
      default:
        break;
    }
  };

  const handleRedirectClick = (account_number, debtor_name) => {
    const location = {
      pathname: `${DASHBOARD}${DEBTOR}/${debtor_name}`,
      state: {
        debtor_name: debtor_name,
        account_number: account_number,
      },
    };
    history.push(location);
  };

  React.useEffect(() => {
    var timer = null;
    if (connection != null) {
      timer = setInterval(() => {
        setCallInfo((state) => {
          let call_status = connection.status();
          let new_state = {
            seconds: 0,
            duration: null,
            status: "",
          };

          switch (call_status) {
            case "pending":
              new_state.status = "Incoming Call...";
              break;
            case "connecting":
              new_state.status = "Connecting...";
              break;
            case "open":
              new_state.status = "Ongoing Call...";
              new_state.seconds =
                state?.seconds != null ? state.seconds + 1 : 0;
              break;
            case "closed":
              new_state.status = "Call Ended.";
              connection.disconnect();
              setConnection(null);
              clearInterval(timer);
              break;
            default:
              return null;
          }
          return {
            ...new_state,
            duration: `${Math.floor((new_state.seconds % 3600) / 60)}:${String(
              Math.round(new_state.seconds % 60)
            ).padStart(2, "0")}`,
          };
        });
      }, 1000);

      connection.on("disconnect", () => console.log("WE HAVE DISCONNECTED!"));
      connection.on("reject", () => console.log("WE HAVE BEEN REJECTED!"));
    }

    return () => {
      if (timer != null) {
        clearInterval(timer);
      }
    };
  }, [connection]);

  return (
    <>
      {reservation?.task.attributes.handoner_acc > 0 && (
        <DebtorButton
          onClick={() =>
            handleRedirectClick(
              reservation?.task.attributes.handoner_acc,
              reservation?.task.attributes.name
            )
          }
        >
          Go to Debtor
        </DebtorButton>
      )}
      <div className={styles.voipContainer}>
        {connection == null && (
          <IconButton className={styles.voipClose} onClick={closeCall}>
            <img src={close_icon} alt="close" width={10} />
          </IconButton>
        )}

        <div>
          <p>{callInfo?.status ?? "Incoming Call"}</p>
          <p>
            {reservation?.task.attributes.from}{"   "}
            {reservation?.task.attributes.name}</p>
        </div>
        <div>
          <span>{callInfo?.duration ?? "0:00"}</span>
        </div>
        <div className={styles.voipActionsContainer}>
          <IconButton
            disabled={!(connection != null)}
            className={clsx(styles.voipActionsButton, {
              [styles.voipActionBackground]: connection?.isMuted(),
            })}
            onClick={mute}
          >
            <img src={mute_icon} alt="mute" width={35} />
          </IconButton>
          {showActionButtons(connection) ? showActionButtons(connection) : null}
        </div>
      </div>
    </>
  );
};

export default Inbound;
