import React, { useState } from "react";
import { Chip } from "@material-ui/core";
import styles from "./styles.module.css";

const TagInput = ({
  style = {},
  tags = [],
  onTagAdd = (val) => {},
  onTagDelete = (val) => {},
  regEx = null,
  regExMsg = null,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleTagAdd = () => {
    if (regEx) {
      if (String(value).match(regEx)) {
        onTagAdd(value);
        setValue("");
        setError(null);
      } else {
        setError(regExMsg ? regExMsg : "Invalid value");
      }
    } else {
      onTagAdd(value);
      setValue("");
      setError(null);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 9) {
        e.preventDefault();
      handleTagAdd(value);
    }
  };

  return (
    <>
      <div
        className={`${styles.tagInputContainer} flex row ai-c jc-fs w-100 pl-1 pr-1`}
        style={{ backgroundColor: "#fff", ...style }}
      >
        {tags.map((tag) => (
          <Chip label={tag} onDelete={() => onTagDelete(tag)} style={{marginRight: 6}} />
        ))}
        <input
          className={"pl-1 pr-1 h-100 w-100"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </div>
      {error ? <p>{error}</p> : null}
    </>
  );
};

export default TagInput;
