import * as api from "..";
import * as validation from "../../utils/validation";
import { AUTH } from "../config/endpoints";

const AuthService = {
  register: async ({name, email, password}) => {
    const resp = await api.post(AUTH.REGISTER, { name, email, password });
    return resp;
  },
  async login({email, password}) {
    const resp = await api.post(AUTH.LOGIN, ...arguments);
    console.log(...arguments);
    return resp;
  },
  getUserFromToken: async () => {
    const resp = await api.get(AUTH.GET_USER_FROM_TOKEN);
    return resp;
  },
  logout: async () => {
    const resp = await api.post(AUTH.LOGOUT);
    return resp;
  },
  sendResetPasswordLink: async (email) => {
    const resp = await api.post(AUTH.RESET_PASS_INIT, { email });
    return resp;
  },
  resetPassword: async (email, password, token) => {
    const resp = await api.post(AUTH.RESET_PASS, { email, password, token });
    return resp;
  },
  isLoggedIn: () => {
    const resp = !!(validation.validateForSessionStorage('userId') && validation.validateForSessionStorage('token'));
    return resp;
  },
};

export default AuthService;
