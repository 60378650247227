import CustomMaterialTable from "../../table/CustomMaterialTable";
import styles from "./styles.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../../constants/pathNames";
import { useModal } from "providers/hooks/useModal";

function RelatedAccounts({ account_name, linked_accounts, view_linked_account }) {
	const { unsetModal } = useModal();
	const history = useHistory();
	const handleRowClick = (event, row) => {
		const location = {
			pathname: `${DASHBOARD}${DEBTOR}/${account_name}`,
			state: {
			debtor_name: account_name,
			account_number: row.account_number,
			},
		};
		// history.push(location);
		view_linked_account(location);
		unsetModal();
	};
	
	console.log(...arguments);
	return (
		<div className={styles.modal_content}>
			<span className='title'>Linked Accounts - {account_name}</span>
			<div>
				<CustomMaterialTable
					columns={[
						{ title: "Acc. No.", field: "account_number" },
						{ title: "Acc. Status", field: "status", },
						{ title: "Acc. Type", field: "account_type" },
						{ title: "Capital", field: "capital" },
						{ title: "Interest", field: "interest" },
						{ title: "Fees", field: "fees" },
						{ title: "Legal Fees", field: "legal_fees" },
						{ title: "Settlement", field: "settlement" },
						{
							title: "Agent",
							render: (row) =>
								Array.isArray(row.agents) &&
								row.agents.length > 0
									? row.agents[0].name +
									  " " +
									  row.agents[0].surname
									: "-",
						},
					]}
					onRowClick={handleRowClick}
					data={linked_accounts}
					options={{
						toolbar: false,
					}}
				/>
			</div>
		</div>
	);
}

export default RelatedAccounts;
