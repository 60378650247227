import React from "react";
import Echo from "laravel-echo";
import { useModal } from "./useModal";
import EventNotification from "components/modal/content/EventNotification";

const useNotification = () => {
  const { setPersistent } = useModal();

  React.useEffect(() => {
    try{
      window.Pusher = require("pusher-js");

      window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.REACT_APP_LARAVEL_WEBSOCKET_PUBLIC_KEY,
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        wsHost: process.env.REACT_APP_PUSHER_HOST,
        wsPort: process.env.REACT_APP_PUSHER_PORT,
        wssPort: process.env.REACT_APP_PUSHER_PORT,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
      });
    }catch(e){
      console.log(e + 'pusher and laravel echo issue');
    }
   
  }, []);

  const handleCalendarEventNotification = (data) => {
    setPersistent({
      Component: <EventNotification data={data} />,
    });
  };

  return {
    handleCalendarEventNotification,
  };
};

export { useNotification };
