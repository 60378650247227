import React, { useEffect, useState } from "react";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useModal } from "../../../providers/hooks/useModal";
import { PTP_OVERRIDE_FIELDS, PTP_DISCOUNT_FIELD } from "../../../constants/tableColumns";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import { Button, Grid, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../../constants/pathNames";

function PtpOverrideRequests({ params }) {
    const { setToast, unsetModal } = useModal();
    const history = useHistory();

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve) => {
                DebtorCasePtpService.managerPtpOverrides(params).then((response) => {
                    resolve({
                        status: response.status,
                        data: response.data?.overrides,
                        count: response.data?.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {},
    });

    useEffect(() => {
        setParams(params);
    }, []);

    const handleAction = async (decision, event, row) => {
        try {
            const payload = { approved: decision, id: row.id };
            const response = await DebtorCasePtpService.updatePtpOverride(payload);

            if (response.status === "success") {
                if (tableRef.current != null) {
                    tableRef.current.onQueryChange();
                }
                setToast("updated ptp override", "success");
                return;
            }
        } catch (error) {
            setToast("error updating ptp override", "error");
        }
    };

    const handleUpdate = async (newRow) => {
        try {
            const response = await DebtorCasePtpService.updatePtpOverride({
                approved: false,
                ...newRow,
            });

            if (response.status === "success") {
                if (tableRef.current != null) {
                    tableRef.current.onQueryChange();
                }
                setToast("updated ptp override", "success");
                return;
            }
        } catch (error) {
            setToast("error updating ptp override", "error");
        }
    };

    const handleRowClick = (event, row) => {
        const location = {
            pathname: `${DASHBOARD}${DEBTOR}/${row.debtor_name}`,
            state: {
                debtor_name: row.debtor_name,
                account_number: row.debtor_case_id,
                // TODO: get client id
            },
        };
        history.push(location);
        unsetModal();
    };

    return (
        <div className={"p-2 flex col ai-c jc-sb"} style={{ minWidth: 300 }}>
            <Typography variant="h4">
                {" "}
                {params.ptp_status_id === 6
                    ? "PTP Minimum Amount Override Requests"
                    : "PTP Discount Override Requests"}{" "}
            </Typography>
            <CustomMaterialTable
                title={null}
                tableRef={tableRef}
                columns={
                    params.ptp_status_id === 6 ? PTP_OVERRIDE_FIELDS : [...PTP_OVERRIDE_FIELDS, ...PTP_DISCOUNT_FIELD]
                }
                data={remoteDataSource}
                onRowClick={handleRowClick}
                editable={{
                    onRowUpdate: (newRow) => {
                        return handleUpdate(newRow);
                    },
                }}
                icons={{
                    Edit: () => (
                        <>
                            <Button
                                size="small"
                                color="secondary"
                                variant="contained"
                                style={{ overflow: "hidden", hover: "none" }}
                            >
                                Reject
                            </Button>
                        </>
                    ),
                    tooltip: "Reject",
                    // onClick: (event, rowData) => handleAction(false, event, rowData),
                }}
                actions={[
                    {
                        icon: () => (
                            <>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    style={{ overflow: "hidden", hover: "none" }}
                                >
                                    Accept
                                </Button>
                            </>
                        ),
                        tooltip: "Accept",
                        onClick: (event, rowData) => handleAction(true, event, rowData),
                    },
                ]}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    search: false,
                }}
            />
        </div>
    );
}

export default PtpOverrideRequests;
