import * as api from "..";
import { EVENT } from "../config/endpoints";

const EventService = {
  async getEventList() {
    const resp = await api.get(EVENT.LIST);
    return resp;
  },
  async create({
    start_time,
    end_time,
    clients,
    debtors,
    note,
    title,
    users,
  }) {
    const resp = await api.post(EVENT.CREATE, ...arguments);
    return resp;
  },
  async update({
    start_time,
    end_time,
    clients,
    debtors,
    note,
    title,
    users,
  }) {
      const resp = await api.post(EVENT.UPDATE, ...arguments);
      return resp;
  },
  async delete({ client_id }) {
      const resp = await api.post(EVENT.DELETE, { client_id });
      return resp;
  },
};

export default EventService;
