import PlayAudioButton from "components/buttons/PlayAudioButton";
import usePersistentTransistion from "providers/hooks/usePersistentTransition";
import React from "react";
import CaseService from "services/caseService";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import { dateFormat, timeFormat } from "../../../utils/dateFormat";

const CallsTable = ({ account_no }) => {
	const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.debtorCaseCalls(params).then((response) => {
					resolve({
						status: response.status,
						data: response.data.calls,
						count: response.data.count,
						pagination: response.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});
	const { status: voip_status } = usePersistentTransistion();

	React.useEffect(() => {
		if (voip_status === "closed") {
			if (tableRef.current != null) {
				tableRef.current.onQueryChange();
			}
		}
	}, [voip_status]);

	return (
		<CustomMaterialTable
			title={null}
			tableRef={tableRef}
			columns={[
				{
					title: 'Debtor Name',
					field: 'debtor_name',
				},
				{
					title: "Date",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? dateFormat(row.date_time) : "-",
				},
				{
					title: "Time",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? timeFormat(row.date_time) : "-",
				},
				{ title: "Description", field: "description" },
				{
					render: (row) =>
						!!row.recording ? (
							<PlayAudioButton url={row.recording} />
						) : (
							""
						),
				},
				{ title: "Duration", field: "duration" },
			]}
			data={remoteDataSource}
			options={{
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default CallsTable;
