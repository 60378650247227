import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { EventService } from "../../api/services";
import { useModal } from "../../providers/hooks/useModal";
import styles from "./Calendar.module.css";
import { TitleSection } from "../../common/Layouts";
import ButtonComponent from "components/ButtonComponent";
import moment from "moment";
import AddEvent from "components/modal/content/AddEvent";
import SelectDate from "components/SelectDate";
import { default as MultiCarousel } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { IconButton, Popper, ClickAwayListener } from "@material-ui/core";
import SearchIcon from "../../assets/icons/search_icon.png";

const Calendar = () => {
    const [events, setEvents] = useState([]);
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment());
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [firstStart, setFirstStart] = useState(true);
    const popperAnchorRef = React.createRef();
    const calendarRef = React.createRef(null);
    const carouselRef = React.createRef(null);
    const { setModal, setToast } = useModal();

    const createDaysInMonth = () => {
        const currentMonthDates = new Array(moment(currentDate).daysInMonth())
            .fill(null)
            .map((x, i) => moment().startOf("month").add(i, "days"));
        setDaysInMonth(currentMonthDates);
    };

    const getEvents = async () => {
        const resp = await EventService.getEventList();
        if (resp.status === "success") {
            setEvents(resp.data.events);
        } else {
            setToast("Error fetching events", "error");
        }
    };

    const onEventClick = (e) => {
        console.log("event click", e.event.id, events);
        const event = events.filter((ev) => ev.id === Number(e.event.id));
        console.log("found event", event);
        if (event.length) {
            setModal({
                Component: (
                    <AddEvent event={event[0]} onSuccess={() => setToast("Event updated successfully", "success")} />
                ),
                size: "lrg",
            });
        } else {
            setToast("Error viewing event", "error");
        }
    };

    useEffect(() => {
        getEvents();
        createDaysInMonth();
    }, []);

    useEffect(() => {
        createDaysInMonth();
        if (!firstStart) {
            const today = moment().toISOString();
            calendarRef.current?.getApi().gotoDate(today);
            setFirstStart(true);
        }
    }, [currentDate]);

    const onAddClick = () => {
        setModal({
            Component: <AddEvent onSuccess={getEvents} source="calendar" />,
            size: "lrg",
        });
    };

    const goToDate = (date) => {
        const to = moment(date);
        calendarRef.current.getApi().gotoDate(to.toISOString());
        carouselRef.current.goToSlide(to.date() - 5);
        setCurrentDate(to);
    };

    const goToDay = (day) => {
        const to = moment(day).set({ month: currentDate.month() });
        calendarRef.current.getApi().gotoDate(to.toISOString());
        setCurrentDate(to);
    };

    const onSelectDate = (date) => {
        goToDate(moment(date));
    };

    const goToToday = () => {
        const today = moment();
        goToDate(today);
    };

    const onSearchChange = (e) => {
        if (e.target.value && e.target.value !== "") {
            const results = events.filter(
                (item) =>
                    item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    item.note?.toLowerCase().includes(e.target.value.toLowerCase()),
            );
            if (!popperAnchorEl) {
                setPopperAnchorEl(popperAnchorRef.current);
            }
            setSearchResults(results);
        } else {
            setPopperAnchorEl(null);
        }
    };

    useEffect(() => {
        carouselRef.current?.goToSlide(currentDate.date() - 8);
    }, [carouselRef]);

    const onClickAwayFromPopper = () => {
        if (Boolean(popperAnchorEl)) {
            setPopperAnchorEl(null);
        }
    };

    return (
        <div className={styles.calendarContainer}>
            <TitleSection>
                <p
                    style={{
                        marginRight: 10,
                        marginBottom: 0,
                        marginTop: 5,
                        color: "var(--colour-blue)",
                    }}
                >
                    Calendar
                </p>
                <div>
                    <ButtonComponent
                        title={"Add Event"}
                        handler={onAddClick}
                        width={140}
                        style={{
                            "> span": {
                                marginLeft: 0,
                            },
                        }}
                    />
                </div>
            </TitleSection>
            <div className={styles.calendar}>
                <div className={styles.toolBar}>
                    <div className={styles.toolBarDate}>
                        <SelectDate
                            updateTitle={onSelectDate}
                            title={`${currentDate.format("MMM")} ${currentDate.year()}`}
                        />
                        <ButtonComponent title={"Today"} handler={goToToday} style={{ marginLeft: 10 }} />
                    </div>
                    <div ref={popperAnchorRef} className={styles.search}>
                        <input
                            style={{ paddingLeft: 10 }}
                            onChange={onSearchChange}
                            // onBlur={() => setPopperAnchorEl(null)}
                            placeholder={"Search..."}
                        />
                        <img src={SearchIcon} alt={"search_icon"} />
                    </div>
                    <ClickAwayListener onClickAway={onClickAwayFromPopper}>
                        <Popper open={Boolean(popperAnchorEl)} anchorEl={popperAnchorEl} style={{ zIndex: 10 }}>
                            <div className={styles.searchResults}>
                                {searchResults.length ? (
                                    searchResults.map((item) => (
                                        <div className={styles.result} onClick={() => onEventClick({ event: item })}>
                                            <h3>{item.title}</h3>
                                            <h4>Start: {moment(item.start).format("DD/MM/yyyy HH:mm")}</h4>
                                            <h4>End: {moment(item.end).format("DD/MM/yyyy HH:mm")}</h4>
                                        </div>
                                    ))
                                ) : (
                                    <div className={styles.result}>
                                        <h3>No results found</h3>
                                    </div>
                                )}
                            </div>
                        </Popper>
                    </ClickAwayListener>
                </div>
                <div className={styles.days}>
                    <IconButton onClick={() => carouselRef.current.previous()} style={{ marginRight: 10 }}>
                        <ChevronLeft />
                    </IconButton>
                    <MultiCarousel
                        ref={carouselRef}
                        className={styles.days}
                        autoPlay={false}
                        infinite={false}
                        transitionDuration={500}
                        arrows={false}
                        slidesToSlide={3}
                        responsive={{
                            desktop: {
                                breakpoint: { max: 4000, min: 0 },
                                items: 14,
                            },
                        }}
                    >
                        {daysInMonth.map((day, index) => {
                            const date = day.toDate();
                            const today = moment();
                            return (
                                <div
                                    className={`${styles.day} ${
                                        moment(currentDate).date() === moment(day).date() ? styles.active : ""
                                    } clickable`}
                                    onClick={() => goToDay(day)}
                                >
                                    {today.isSame(date, "day") &&
                                    moment(currentDate.toISOString()).isSame(date, "month") &&
                                    moment(currentDate.toISOString()).isSame(date, "year") ? (
                                        <p>Today</p>
                                    ) : null}
                                    <h2>{moment(day).date()}</h2>
                                    <p>{moment(day).set({ month: currentDate.month() }).format("dddd")}</p>
                                </div>
                            );
                        })}
                    </MultiCarousel>
                    <IconButton onClick={() => carouselRef.current.next()} style={{ marginLeft: 10 }}>
                        <ChevronRight />
                    </IconButton>
                </div>
                <div style={{ height: 1085, position: "relative" }}>
                    <FullCalendar
                        events={events}
                        eventClick={onEventClick}
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="timeGridDay"
                        slotDuration={"00:20:00"}
                        slotLabelInterval={"01:00:00"}
                        slotMinTime={"06:00:00"}
                        slotMaxTime={"23:00:00"}
                        initialDate={moment().toDate().toISOString()}
                        eventBackgroundColor={"var(--colour-blue)"}
                        headerToolbar={false}
                        height={1085}
                        eventContent={(item) => {
                            return (
                                <div className={styles.eventCard}>
                                    <p className={styles.eventTime}>
                                        {item.event.title} |{" "}
                                        <span>
                                            {moment(item.event.start).format("HH:mm")} -{" "}
                                            {moment(item.event.end).format("HH:mm")}
                                        </span>
                                    </p>
                                    <p className={styles.eventNote}>{item.event.extendedProps?.note}</p>
                                </div>
                            );
                        }}
                    />
                    <div className={styles.calendarSummaryCard}>
                        <div>
                            <h3>Meetings Today</h3>
                            <h2>
                                {
                                    events.filter(
                                        (item) =>
                                            currentDate.isSame(item.start, "date") &&
                                            currentDate.isSame(item.start, "month"),
                                    ).length
                                }
                            </h2>
                        </div>
                        <div>
                            <h3>Meetings This Week</h3>
                            <h2>
                                {
                                    events.filter(
                                        (item) =>
                                            currentDate.isSame(item.start, "week") &&
                                            currentDate.isSame(item.start, "month"),
                                    ).length
                                }
                            </h2>
                        </div>
                        <div>
                            <h3>Meetings This Month</h3>
                            <h2>
                                {
                                    events.filter(
                                        (item) =>
                                            currentDate.isSame(item.start, "month") &&
                                            currentDate.isSame(item.start, "year"),
                                    ).length
                                }
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
