import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "./useModal";
import { format } from "date-fns";
import PtpOverride from "../../components/modal/content/PtpOverride";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import ConfirmAction from "components/modal/ConfirmAction";
import {
  setToast
} from "../../redux/actions/navActions";

const submitValues = async (values, account_no,type, statusId) => {

  const start_date = values.start_date && {start_date: format(values.start_date, "yyyy-MM-dd")};

  const res = await DebtorCasePtpService.create({
    account_no,
    type,
    status: statusId ? statusId : 1,
    ...values,
    ...start_date,
    due_date: format(values.due_date, "yyyy-MM-dd"),
  });

  return res;
}

const checkPtpAllowedAmount = (amount, account_settlement, ptp_minimum, discount) => {
  
  const allowedAmount = ptp_minimum;
  let settlement = account_settlement;
  
  if(discount){
    settlement = settlement - (settlement * discount / 100);
  }
  const paymentPercentage = (amount/settlement) * 100;
  if (paymentPercentage > allowedAmount) {
    return true;
  }
  return false;
};

const usePtpOverride = (ptp_minimum) => {

  const { setModal } = useModal();
  const [override, setOverrideState] = useState(null);

  const handleSetOverride = useCallback((response) => {
    setModal({
      Component: <PtpOverride response={response}/>,
      size: "sml",
      canClickAway: false,
    });

  }, []);
  

  const openConfirmPtpOverrideModal = (values, account_no, type) => {

    setModal({
      Component: (
        <ConfirmAction
          onConfirm={ () => handlePtpOverrideConfirmation(values, account_no, type)}
          message={`PTP amount must be more than ${ptp_minimum}% of the account settlement. Would you like to make a PTP override?`}
        />
      ),
      size: "sml",
    });

  };

  const handlePtpOverrideConfirmation = async (values, account_no, type) => {
    const statusId = 6;
    
    const response = await submitValues(values, account_no, type, statusId);

    handleSetOverride(response);
    
  }
 
  const handlePtpDiscountOverride = async (values, account_no, type) => {
    const statusId = 7;

    const response = await submitValues(values, account_no, type, statusId);
    
    handleSetOverride(response);
  }

  const handleSetOverrideState = useCallback(() => {
    setOverrideState( (override) => true);
  }, [override]);

  return {
    openConfirmPtpOverrideModal,
    submitValues,
    override,
    handlePtpDiscountOverride,
    handleSetOverrideState,
    handleSetOverride,
    checkPtpAllowedAmount,
  };
};

export { usePtpOverride };
