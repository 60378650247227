import { useVoip } from 'providers/hooks/useVoip';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { AgentService } from '../../../api/services';
import { useActivityTracker } from '../../../providers/hooks/useActivityTracker';
import { useModal } from '../../../providers/hooks/useModal';
import ButtonComponent from '../../ButtonComponent';

const ActiveBreak = ({ breakType }) => {
    const { updateWorkerAvailability } = useVoip();
    const { unsetModal } = useModal();
    let t = { id: 8, naame: 'Idle User' }
    const {
        seconds,
        minutes,
        hours,
      } = useActivityTracker();
    const agentId = useSelector(state => state.auth.id);
    let interval = null;

    const startBreak = async () => {
        const resp = await AgentService.timeLogStart({ type: breakType.id });
        if (resp.status === 'success') {
            
        }
    } 

    const endBreak = async () => {
        const resp = await AgentService.timeLogStop({ type: breakType.id });
        if (resp.status === 'success') {
            updateWorkerAvailability("Online");
            unsetModal();
        }
    }

    useEffect(() => {
        startBreak();
        return () => {
            if (minutes > 0 || seconds > 0) {
                endBreak();
            }
        }
    }, []);

    return (
        <div className={'p-3 flex col ai-c jc-sb'} style={{minWidth: 300}}>
            <h1>{breakType ? breakType.name : t.name}</h1>
            <p style={{fontSize: 36}}>{hours < 10 ? '0' : null}{hours}:{minutes < 10 ? '0' : null}{minutes}:{seconds < 10 ? '0' : null}{seconds}</p>
            <ButtonComponent width={280} variant={'outlined'} title={'End Break'} handler={endBreak} />
        </div>
    )
}

export default ActiveBreak
