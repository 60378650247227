import React from "react";
import { Device } from "twilio-client";
import { useSelector } from "react-redux";
import TwilioService from "services/twilioService";
import { useModal } from "./useModal";
import Inbound from "components/modal/content/VoipCall/Inbound";
// import { useAuth } from "./useAuth";

const useVoip = () => {
  const { setPersistent } = useModal();
  const worker_id = useSelector((state) => state.auth.worker_id);
  const user_type_id = useSelector((state) => state.auth.user_type_id);
  const [reservation, setReservation] = React.useState(null);
  const [connection, setConnection] = React.useState(null);
  const [deviceState, setDeviceState] = React.useState();
  const [workerState, setWorkerState] = React.useState(null);
  const [workerActivities, setWorkerActivities] = React.useState(null);


  const getActivityByFriendlyName = (friendlyName) => {
    const activity = workerActivities?.find(
      (item) => item.FriendlyName === friendlyName
    );
    return activity;
  };

  const initWorker = async (worker_id) => {

    const response = await TwilioService.getTaskRouterToken(
      worker_id
    );

    try {
      console.log("worker getting ready to connect.....");
      let worker = await new window.Twilio.TaskRouter.Worker(response.token);
      setWorkerState(worker);
      setActivities(worker);
    
    } catch {
      console.log("waiting on worker sdk to load up");
    }
  };

  const handlePhone = () => {
    setPersistent({
      Component: <Inbound callConnection={connection} callReservation={reservation} />,
    });
  };

  React.useEffect(() => {
    
    let active = true;
    if (active === true && user_type_id === 4) {
      initWorker(worker_id)
    }
    return () => {
      active = false;
    };
  }, [worker_id]);

  const setActivities = (worker) => {
    let data = {};
    worker?.activities?.fetch(function (error, activityList) {
      if (error) {
        console.log(error.code);
        console.log(error.message);
        return;
      }
      data = activityList?.data?.map((activity) => {
        return {
          ActivitySid: activity.sid,
          FriendlyName: activity.friendlyName,
        };
      });
      setWorkerActivities(data);
    });
  };

  React.useEffect(() => {
    let active = true;
    if (worker_id && user_type_id === 4) {
      TwilioService.getToken(worker_id).then((res) => {
        if (active == true) {
          let device = new Device(res.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            debug: true,
          });
          setDeviceState(device);
          initWorker(worker_id)
        }
      });
    }
    return () => {
      active = false;
    };
  }, [worker_id]);

  React.useEffect(()=>{
    if (workerState != null) {
      workerState?.on("reservation.created", function (worker_reservation) {
        if(reservation === null){
          setReservation(worker_reservation);
        }
      });
    }
  },[workerState])

  React.useEffect(()=>{
    if (deviceState != null) {
      deviceState.on("incoming", (connection) => {
        if (connection) {
          setConnection(connection);
        }
      });
    }
  },[deviceState])

  const updateWorkerAvailability = (activity) => {
    const workerAvailable = getActivityByFriendlyName(activity);
    workerState?.update(
      "ActivitySid",
      workerAvailable?.ActivitySid,
      function (error, worker) {
        if (error) {
          console.log(error.code);
          console.log(error.message);
        } else {
          console.log(worker?.activityName);
        }
      }
    );
  };


  return {
    handlePhone,
    connection,
    reservation,
    deviceState,
    workerState,
    updateWorkerAvailability,
    getActivityByFriendlyName,
  };
};

export { useVoip };
