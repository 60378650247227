import * as api from "..";
import { CLIENT_FEE } from "../config/endpoints";

const ClientFeeService = {
	async listClientFees({
		client_id,
		sort = null,
		pagination = null,
		filter = null,
	}) {
		const resp = await api.post(CLIENT_FEE.LIST, ...arguments);
		return resp;
	},
	async create({ client_id, price, code, activity_description }) {
		const resp = await api.post(CLIENT_FEE.CREATE, ...arguments);
		return resp;
	},
	async update({ id, price, code, activity_description }) {
		const resp = await api.post(CLIENT_FEE.UPDATE, ...arguments);
		return resp;
	},
	async delete({ id }) {
		const resp = await api.post(CLIENT_FEE.DELETE, { id });
		return resp;
	},
};

export default ClientFeeService;
