import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import SwitchComponent from "../../components/SwitchComponent";
import TimeFilter from "../../components/TimeFilter";
import styled from "styled-components";
import { useModal } from "../../providers/hooks/useModal";
import { AgentService } from "../../api/services";
import AgentLogoutBreaks from "./Tables/AgentLogoutBreaks";
import AgentSummary from "./Tables/AgentSummary";
import CampaignSummary from "./Tables/CampaignSummary";
import { Section, TitleSection } from "../../common/Layouts";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import PtpOverrideRequests from "./Tables/PtpOverrideRequests";
import ButtonComponent from "components/ButtonComponent";

const SectionTable = styled.div`
  margin: 20px 0px;
  .MuiPaper-rounded {
    border-radius: 15px !important;
    box-sizing: border-box;
  }
`;


const getClientById = (name, clients) => {
  let client = clients.find((item) => item.name === name);
  return { client_id: client.id };
};

function ManagerDashboard() {
  const { setModal } = useModal();
  let isBack = false;
  const [configList, setConfigList] = useState([
    "agents summary",
    "logout/breaks summary",
  ]);
  const [currentTable, setCurrentTable] = useState(configList[0]);
  const [clients, setClients] = useState(null);
  const [ptpOverrides, setPtpOverrides] = useState(null);
  const [ptpDiscountOverrides, setPtpDiscountOverrides] = useState(null);

  const [paramLogout, setParamLogout] = useState({});
  const [params, setParams] = useState({});
 
  const getClients = async () => {
    const resp = await AgentService.getAvailableClientsFromActiveCampaigns();
    if (resp.status === "success") {
      setClients(resp.data.clients);
    }
  };

  const getListOfPtpOverrides = async () => {
    // const param = {ptp_status_id: 6};
    const resp = await DebtorCasePtpService.checkOverrides({ptp_status_id: 6});
    if (resp.status === "success") {
       setPtpOverrides(resp.data.ptp_override_available ? 'PTP Overrides' : null);
    }
  }

  const getListOfPtpDiscountOverrides = async () => {
    const param = {ptp_status_id: 7};
    const resp = await DebtorCasePtpService.checkOverrides({ptp_status_id: 7});
    if (resp.status === "success") {
       setPtpDiscountOverrides(resp.data.ptp_override_available ? 'PTP Overrides' : null);
    }
  }

  useEffect(() => {
    getClients();
    getListOfPtpOverrides();
    getListOfPtpDiscountOverrides();
  }, []);

  useEffect(() => {
    if (clients !== null) {
      setConfigList((configList) => ([
        ...configList,
        ...clients.map((item) => item.name),
      ]));
    }
  }, [clients]);

  const openPtpOverride = (ptp_override_type) => {
    setModal({
      Component: <PtpOverrideRequests params={ptp_override_type}  />,
      size: "sml",
    });

  };
  
  const handlerSelectTable = (data) => {
    setCurrentTable(data);
  };

  useEffect(() => {
    if (currentTable === configList[0] || currentTable === configList[1]) {
      setParams({});
    } else {
      setParams(getClientById(currentTable, clients));
    }
  }, [currentTable]);


  const selectTime = (time) => {
    let from = format(time?.from, "yyyy-MM-dd");
    let to = format(time?.to, "yyyy-MM-dd");
    let newParams = {
      ...paramLogout,
      filter: [
        {
          column_name: "date_time",
          search_term: { from: `${from}`, to: to },
        },
      ],
    };
    setParamLogout(newParams);
    setParams(newParams);
  };

  const handleTimerFilerVisibilty = (tabIndex) => {
    switch (tabIndex) {
      case configList[0]:
        return false;
      case configList[1]:
        return false;
      default:
        return true;
    }
  }

  const showTable = (tabIndex) => {
    switch (tabIndex) {
      case configList[0]:
        return <AgentSummary params={params} />;
      case configList[1]:
        return <AgentLogoutBreaks params={params} />;
      default:
        return <CampaignSummary params={params} />;
    }
  };

  return (
    <div>
      <TitleSection>
        {isBack && <h4>Back</h4>}
        <p
          style={{
            marginRight: 10,
            marginBottom: 0,
            marginTop: 5,
          }}
        >
        Manager Dashboard
        </p>
        
        { ptpOverrides && <div style={{  marginRight: 10 }}>
          <ButtonComponent
            title={"Ptp Overrides"}
            handler={()=>openPtpOverride({ptp_status_id: 6})}
            // width={240}
            style={{
              '> span': {
                marginLeft: '60px',
              }
            }}
          />
        </div>}

        { ptpDiscountOverrides  && <div>
          <ButtonComponent
            title={"Ptp Discount Overrides"}
            handler={()=>openPtpOverride({ptp_status_id: 7})}
            // width={240}
            style={{
              '> span': {
                marginLeft: '60px',
              }
            }}
          />
          </div>
        }
      </TitleSection>
      <SectionTable>
        <Section>
          <SwitchComponent
            currentItem={currentTable}
            configList={configList}
            handler={handlerSelectTable}
          />
          <TimeFilter
            disable={handleTimerFilerVisibilty(currentTable)}
            handler={selectTime}
          />
        </Section>
        {showTable(currentTable) }
      </SectionTable>
    </div>
  );
}

export default ManagerDashboard;
