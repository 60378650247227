import { Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { colors } from "../../constants/styles";

const BluePillButton = styled(Button)({
	textTransform: "capitalize",
	borderRadius: "50px",
	padding: "3px 30px",
	backgroundColor: `${colors.blue}`,
	color: `${colors.white}`,
	overflow: "hidden",
	"&:hover": {
		backgroundColor: `${colors.blue}`,
	},
});

export default BluePillButton;
