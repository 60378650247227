import BluePillButton from "components/buttons/BluePillButton";
import EditSelect from "components/table/components/EditSelect";
import { MaterialTableCurrencySettings } from "constants/locale";
import React from "react";
import * as Yup from "yup";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import HospitalVisitRecords from "../../../components/HospitalVisitRecords";
import CaseService from "../../../services/caseService";
import { dateFormat } from "../../../utils/dateFormat";
import { useModal } from "providers/hooks/useModal";
import { useAuth } from "providers/hooks/useAuth";

const schema = Yup.object().shape({
    date: Yup.date().required(),
    code: Yup.string().required(),
    description: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .required("Please select an description"),
    duration: Yup.string(),
    amount: Yup.string(),
    running_total: Yup.string(),
    type: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .required("Please select an type."),
    capture_date: Yup.string(),
});

function FeesTable({ account_status, account_no, agent_id, accountDetailsList }) {
    const { user_type_id } = useAuth();
    const { setModal, unsetModal, setToast } = useModal();
    const [descriptions, setDescriptions] = React.useState([]);
    const [menuAnchor, setMenuAnchor] = React.useState();
    const [types, setTypes] = React.useState([]);
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                CaseService.debtorCaseFees(params).then((response) => {
                    if (response.data?.descriptions != null) {
                        setDescriptions([...response.data.descriptions]);
                    }
                    if (response.data?.types != null) {
                        setTypes([...response.data.types]);
                    }

                    // resolve promise with table data
                    resolve({
                        status: response.status,
                        data: response.data.fees,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {
            account_no,
        },
    });


    const ACTIONS = [
        {
            icon: (ref) => (
                <>
                    <BluePillButton ref={ref} variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                        Hospital Visit Records
                    </BluePillButton>
                </>
            ),
            tooltip: "Check hospital visit records",
            isFreeAction: true,
            onClick: () =>
                setModal({
                    Component: (
                        <HospitalVisitRecords account_no={account_no} unsetModal={unsetModal} />
                    ),
                    size: "sml",
                }),
        },
    ];

    const ICONS = {
        Add: (props, ref) => (
            <>
                <BluePillButton ref={ref} variant="contained" style={{ overflow: "hidden", marginRight: "10px" }}>
                    + Fee
                </BluePillButton>
            </>
        ),
    };

    const EDITABLE = {
        onRowUpdate: (newRow) =>
            CaseService.updateDebtorCaseFee({
                account_no,
                agent_id,
                ...newRow,
            }),
        onRowDelete: (oldRow) =>
            CaseService.deleteDebtorCaseFee({
                id: oldRow.id,
            }),
        onRowAdd: (newRow) =>
            CaseService.addDebtorCaseFee({
                account_no,
                agent_id,
                ...newRow,
            }),
    };

    const actions = (account_status === 1 && accountDetailsList.client_id == 2) ? ACTIONS : []; //client_id should be patient debt

    return (
        <>
            <CustomMaterialTable
                title={null}
                tableRef={tableRef}
                columns={[
                    { title: "ID", field: "id", hidden: true },
                    {
                        title: "Date",
                        field: "date",
                        render: (row) => (row?.date != null ? dateFormat(row.date) : "-"),
                        type: "date",
                        initialEditValue: new Date(),
                    },
                    {
                        title: "Code",
                        field: "code",
                    },
                    {
                        title: "Description",
                        field: "description",
                        editComponent: (params) => <EditSelect params={params} options={descriptions} />,
                        render: (row) => row.description?.name,
                    },
                    {
                        title: "Duration (H)",
                        field: "duration",
                        type: "numeric",
                    },
                    {
                        title: "Amount",
                        field: "amount",
                        type: "currency",
                        currencySetting: MaterialTableCurrencySettings,
                    },
                    {
                        title: "Running Total",
                        field: "running_total",
                        type: "currency",
                        currencySetting: MaterialTableCurrencySettings,
                    },
                    {
                        title: "Type",
                        field: "type",
                        align: "center",
                        editComponent: (params) => <EditSelect params={params} options={types} />,
                        render: (row) => row.type?.name,
                    },
                    {
                        title: "Capture Date",
                        field: "capture_date",
                        render: (row) => (row?.capture_date != null ? dateFormat(row.capture_date) : "-"),
                        type: "date",
                    },
                ]}
                validationSchema={schema}
                data={remoteDataSource}
                editable={account_status === 1 && user_type_id !== 4 && EDITABLE}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    toolbarButtonAlignment: "left",
                }}
                icons={account_status === 1 && user_type_id !== 4 && ICONS}
                actions={actions}

            />
        </>
    );
}

export default FeesTable;
