import { useSelector, useDispatch } from "react-redux";
import { AuthService } from "../../api/services";
import { setToken } from "../../api";
import { loginSuccess } from "../../redux/actions/authAction";

const useAuth = () => {
    const agent_id = useSelector((state) => state.auth.id);
    const permissions = useSelector((state) => state.auth.permissions);
    const user_type_id = useSelector((state) => state.auth.user_type_id);
    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const worker_id = useSelector((state) => state.auth.worker_id);
    const first_log_in = useSelector((state) => state.auth.first_log_in);
    const dispatch = useDispatch();

    const login = async () => {
        setToken(sessionStorage.getItem("token"));
        const resp = await AuthService.getUserFromToken();
        let ret = false;
        console.log("resp", resp);
        if (resp?.status === "success" && resp.data) {
            console.log("setting success");
            const obj = {
                id: resp.data.user.id,
                email: resp.data.user.email,
                name: resp.data.user.name,
                user_type_id: resp.data.user.user_type_id,
                permissions: resp.data.user.permissions,
                token: resp.data.api_access_token,
                profile_pic_url: resp.data.user.profile_pic_url,
                worker_id: resp.data.user.worker_id,
                first_log_in: resp.data.user.first_log_in,
            };
            dispatch(loginSuccess(obj));
            ret = true;
        } else {
            sessionStorage.removeItem("token");
        }
        return ret;
    };

    const hasPermission = (permissionName) => {
        if (user_type_id <= 2) return true;
        if (!permissionName) return false;
        if (permissions.filter((permission) => permission.name === permissionName).length) {
            return true;
        }
        return false;
    };

    const isLoggedIn = async () => {
        if (loggedIn) {
            return true;
        } else {
            if (sessionStorage.getItem("token")) {
                const resp = await login();
                return resp;
            } else {
                return false;
            }
        }
    };

    return {
        hasPermission,
        isLoggedIn,
        agent_id,
        user_type_id,
        worker_id,
        first_log_in,
    };
};

export { useAuth };
