import * as api from "..";
const { EXPORTS } = require("constants/apis");

const ExportService = {
	async export({ client_id, year, type }) {
		const resp = await api.post(EXPORTS.REPORT, ...arguments);
		return resp;
	},
};

export default ExportService;