import styled from 'styled-components'
import { colors } from '../constants/styles';

export const TitleSection = styled.div`
  display: flex;
  text-transform: uppercase; 
  font-size: 1.7vw;
  font-weight: bold;
  font-family: 'Avenir Next Bold';
  margin: 20px 0px;
  span {
    font-weight: normal;
    margin-left: 10px; 
    @media (min-width:1920px) {
      margin-left: 14px; 
    }
  }
  @media (min-width:1920px) {
     font-size: 30px;
    }
  h4 {
    margin-right: 10px;
  }
`; 

export const TitleTable = styled.div`
  display: flex;
  text-transform: uppercase; 
  font-size: 1.7vw;
  font-weight: bold;
  font-family: 'Avenir Next Bold';
  margin: 20px 0px 0px 20px;
  color: ${colors.blue};
  @media (min-width:1920px) {
     font-size: 30px;
     margin: 20px 0px 0px 40px;
    }
`; 

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.height}
  ${props => props.customStyle}
`;

export const Card = styled.div`
  background-color: ${colors.white};
  padding: 28px 21px;
  width: 100%;
  height: 180px;
  @media (min-width:1920px) {
    height: 240px;
  }
  ${props => props.customStyle}
  ${props => props.margin}
  border-radius: 5px;
  box-sizing: border-box; 
`;

export const ArrowUP = styled.div`
  width: 0; 
  height: 0; 
  ${props => props.customStyle}
  border-left: ${props => props.side}px solid transparent;
  border-right: ${props => props.side}px solid transparent;
  border-bottom: ${props => props.base}px solid ${colors.blue};
`;

export const ArrowDown = styled.div`
  width: 0; 
  height: 0; 
  ${props => props.customStyle}
  border-left: ${props => props.side}px solid transparent;
  border-right: ${props => props.side}px solid transparent;
  border-top: ${props => props.base}px solid ${props => props.color ? props.color : colors.blue};
`;

export const ButtonBack = styled.button`
    border-radius: 36px;
    background-color: ${colors.blue};
    color: ${colors.white};
    height: ${props => props.isCustom ? props.height - props.height / 4 : props.height}px;   
    font-size: ${props => props.small_size}px; 
    width: ${ props => props.width - props.width / 4}px; 
    border: none;
    cursor: pointer;
    outline: none;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.customStyle}
    @media (min-width:1920px){
      height: ${props => props.height}px; 
      font-size: ${props => props.fontSize}px; 
      width: ${props => props.width}px; 
    };
    :hover {
     background-color: ${colors.lightGray};
    }
`;

export const Box = styled.div`
  background-color: ${colors.white};
  border-radius: 17px; 
  padding: ${props => props.paddingSmall};
  position: relative;
  ${props => props.customStyle}; 
  width: 100%;
  box-sizing: border-box;
  @media (min-width:1920px){
    padding: ${props => props.padding};
  };
`;
