import { useDispatch } from 'react-redux';
import { toggleLoading as toggleLoadingAction } from '../../redux/actions/navActions';

const useGlobalLoader = () => {
    const dispatch = useDispatch();

    const toggleLoading = () => {
        dispatch(toggleLoadingAction());
    }

    return {
        toggleLoading
    }
}

export { useGlobalLoader }