import { IconButton } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import reply_one_icon from "../../../assets/icons/Layer -23.png";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CaseService from "../../../services/caseService";
import { dateFormat, timeFormat } from "../../../utils/dateFormat";

const EmailsTable = ({ account_no }) => {
	const history = useHistory();
	const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.debtorCaseEmails(params).then((response) => {
					resolve({
						status: response.status,
						data: response.data.emails,
						count: response.data.count,
						pagination: response.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

	const handleEmailReply = (hash) => {
		history.push(`/dashboard/emails/view/${hash}`);
	};
	return (
		<CustomMaterialTable
			title={null}
			tableRef={tableRef}
			columns={[
				{
					title: "Date",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? dateFormat(row.date_time) : "-",
				},
				{
					title: "Time",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? timeFormat(row.date_time) : "-",
				},
				{ title: "Sender", field: "sender" },
				{ title: "Subject", field: "subject" },
				{
					render: (row) => (
						<IconButton
							onClick={() => handleEmailReply(row.unique_hash)}
						>
							<img src={reply_one_icon} alt='reply' />
						</IconButton>
					),
				},
			]}
			data={remoteDataSource}
			options={{
				actionsColumnIndex: -1,
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default EmailsTable;
