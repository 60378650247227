import React, { useState, useEffect } from 'react'
import Nawbar from '../../components/Nawbar'
import styled from 'styled-components'
import { colors } from '../../constants/styles';
import { Grid } from '@material-ui/core';
import Logout from '../../components/Logout';
import ActivityTracker from '../../components/activity/ActivityTracker';
import { useModal } from '../../providers/hooks/useModal';
import { AgentService } from '../../api/services';

const WrapperPage = styled.div`
  overflow: hidden;
  height: 100%;
`; 

const SectionPages= styled.div`
  position: relative;
  width: 100%; 
  height: 100%; 
  padding: 0 30px;
  padding-bottom: 40px;
  padding-right: ${window.innerWidth < 1300 ? 20 : 40}px; 
  margin-left: 100px;
  background-color: ${colors.gray};
  overflow-y: auto;
  @media (min-width:1921px) {
    margin-left: 129px;  
  }

  @media (min-width:1920px) {
    padding: 0 40px;
  }
`;

const SpacerBottom=styled.div`
  width: 100%;
  height: 50px;
  background-color: ${colors.gray};
`;

function DashboarLayout({children}) {

  const [openLogout, setOpenLogout] = useState(false);
  const [logoutOptions, setLogoutOptions] = useState([]);
  const { setModal } = useModal();

  const getLogoutOptions = async () => {
    const resp = await AgentService.getTimeLogBreakTypes();
    if (resp.status === 'success') {
      setLogoutOptions(resp.data.types);
    }
  }

  useEffect(() => {
    if (!logoutOptions.length) {
      getLogoutOptions();
    }
  }, [logoutOptions])

  const openLogoutModal = () => {
    setModal({
      Component: <Logout logoutOptions={logoutOptions} />,
      size: 'sml',
    })
  }
   
  return (
    <WrapperPage>
      <Grid style={{height: '100%'}} container>
        <Nawbar handleLogout={openLogoutModal}/>
        <SectionPages>
        {children}
        </SectionPages>
      </Grid>
      <SpacerBottom/>
      <ActivityTracker />
    </WrapperPage>
  )
}

export default DashboarLayout
