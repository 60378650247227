import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import authReducer from "./reducers/authReducer";
import userReducer from "../redux/reducers/userReducer";
import agentReducer from "../redux/reducers/agentReducer";
import clientsReducer from "../redux/reducers/clientsReducer";
import { setToken } from "../api/";
import { LOGIN_SUCCESS, LOGOUT } from "./types/auth.types";
import navReducer from "./reducers/navReducer";

let reducers = combineReducers({
  auth: authReducer,
  nav: navReducer,
  userReducer,
  agentReducer,
  clientsReducer,
});

const setFetchAuthToken = () => (next) => (action) => {
  if (action.type === LOGIN_SUCCESS) {
    sessionStorage.setItem("token", action.payload.token);
    setToken(action.payload.token);
  }
  if (action.type === LOGOUT) {
    sessionStorage.removeItem();
    setToken(null);
  }
  return next(action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunkMiddleware, setFetchAuthToken))
);

export default store;
