import React from "react";
import style from "../styles.module.css";

const TextArea = ({ name, value, onChange, error, label }) => {
  return (
    <div className={style.wrapper_input}>
      <div className={style.input_title}>{label}</div>
      <textarea
        name={name}
        type={"text"}
        value={value}
        onChange={onChange}
        style={{ resize: "none", paddingTop: 12 }}
      />
      {error && (
        <label style={{ textAlign: "center", marginTop: 10, width: "98%" }}>
          {error}
        </label>
      )}
    </div>
  );
};

export default TextArea;
