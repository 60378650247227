const BASE_AGENTS = "/agents";
const BASE_TIME_LOGS = "/agent_time_logs";
const BASE_DEBTORS = "/debtors";
const BASE_EVENTS = "/events";
const BASE_MANAGERS = "/managers";
const BASE_USERS = "/users";
const BASE_CLIENTS = "/clients";
const BASE_CLIENT_FEES = "/client_fees";
const BASE_EMAILS = "/emails";
const BASE_DEBTOR_CASES = "/debtor_cases";
const BASE_DEBTOR_CASES_DEBTOR = BASE_DEBTOR_CASES + "/debtor";
const BASE_DEBTOR_CASES_CASE = BASE_DEBTOR_CASES + "/case";
const BASE_DEBTOR_CASES_CASE_FEES = BASE_DEBTOR_CASES_CASE + "/fees";

const AUTH = {
	LOGIN: "login",
	FORGOT_PASSWORD: "forgot-password",
	RESET_PASSWORD: "reset-password",
	SET_PASSWORD: "set-password",
	LOGOUT: "logout",
};

const AGENTS = {
	EMAILS: BASE_AGENTS + "/emails",
	SEND_EMAIL_DEBTOR: BASE_AGENTS + "/send_email_to_debtor",
	SUMMARY: BASE_AGENTS + "/summary",
	BULK_ASSIGN_CLIENTS: +"/bulk_assign_clients",
	LOGOUT_BREAKS: +"/logout_breaks",
};

const TIME_LOGS = {
	START: BASE_TIME_LOGS + "/start",
	STOP: BASE_TIME_LOGS + "/stop",
};

const DEBTORS = {
	ASSIGN_AGENT: BASE_DEBTORS + "/assign-agent",
	CALLS: BASE_DEBTORS + "/calls",
};

const EVENTS = {
	CREATE: BASE_EVENTS + "/create",
	UPDATE: BASE_EVENTS + "/update",
	DELETE: BASE_EVENTS + "/delete",
};

const MANAGERS = {
	REGISTER_AGENT: BASE_MANAGERS + "/agent_register",
};

const USERS = {
	CREATE: BASE_USERS + "/get_user_admin",
	UPDATE: BASE_USERS + "/update",
	CREATE: BASE_USERS + "/create",
	DELETE: BASE_USERS + "/delete",
};

const CLIENTS = {
	CREATE: BASE_CLIENTS + "/create",
	UPDATE: BASE_CLIENTS + "/update",
	CLIENT_REGISTER: BASE_CLIENTS + "/client_register",
	DEBTOR_REGISTER: BASE_CLIENTS + "/debtor_register",
	DELETE: BASE_CLIENTS + "/delete",
};

const CLIENT_FEES = {
	LIST: BASE_CLIENT_FEES + "/crud_list",
	CREATE: BASE_CLIENT_FEES + "/create",
	UPDATE: BASE_CLIENT_FEES + "/update",
	DELETE: BASE_CLIENT_FEES + "/delete",
};

const EMAILS = {
	SHARE: BASE_EMAILS + "/share",
	DELETE: BASE_EMAILS + "/delete",
};

const CASE = {
	FEES: BASE_DEBTOR_CASES_CASE_FEES,
	NOTES: BASE_DEBTOR_CASES_CASE + "/notes",
	PTPS: BASE_DEBTOR_CASES_CASE + "/ptps",
	ADDITIONAL: BASE_DEBTOR_CASES_CASE + "/additional_data",
	SUMMARY: BASE_DEBTOR_CASES_CASE + "/debtor_summary",
	OPTIONS: BASE_DEBTOR_CASES_CASE + "/debtor_summary_options",
	CALLS: BASE_DEBTOR_CASES_CASE + "/calls",
	MESSAGES: BASE_DEBTOR_CASES_CASE + "/messages",
	EMAILS: BASE_DEBTOR_CASES_CASE + "/emails",
	EDIT_DEBTOR: BASE_DEBTOR_CASES_DEBTOR + "/details/edit",
};

const EXPORTS = {
	REPORT: "/exports/report",
};
export {
	AUTH,
	AGENTS,
	TIME_LOGS,
	DEBTORS,
	EVENTS,
	MANAGERS,
	USERS,
	CASE,
	EMAILS,
	CLIENT_FEES,
	CLIENTS,
	EXPORTS,
};
