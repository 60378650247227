import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import calendarTop from "../../../assets/icons/Group 407.png";
import styles from "./styles.module.css";

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      "& .MuiInput-underline:before": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        borderBottom: "none",
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "none",
      },
    },
    titleCalendar: {
      fontSize: "1.2vw",
      marginRight: "-10px",
      display: "flex",
      alignItems: "center",
      fontFamily: "Avenir Next",
      "@media (min-width:1920px)": {
        fontSize: "20px",
      },
    },
    icon_calendar: {
      width: `${props.size}px`,
    //   position: 'absolute',
    //   right: 0,
    },
  }));

export default function DatePicker({
  size = 22,
  isFee = false,
  updateTitle,
  label,
  value = null
}) {
  const classes = useStyles({ size })();

  const [selectedDate, setSelectedDate] = React.useState(value ? new Date(value) : new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (updateTitle) {
      updateTitle(date);
    }
  };

  return (
    <div className={styles.datePickerContainer}>
      <div className={styles.datePickerTitle}>{label}</div>
      <div className={styles.datePicker}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="none"
          style={{width: '100%'}}
          leftArrowButtonProps={{
            style: {
              /* display: 'none' */
            },
          }}
          rightArrowButtonProps={{
            style: {
              /* display: 'none' */
            },
          }}
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
            width: 300,
          }}
          InputProps={{
            style: {
              borderBottom: "none",
              width: "100%",
            },
            disableUnderline: true,
          }}
          keyboardIcon={
            <img className={classes.icon_calendar} src={calendarTop} alt="" />
          }
        />
      </div>
    </div>
  );
}
