// const UserService = {
//     _url: process.env.REACT_APP_API_BASE_URL,

//     _token(token) {
//         if (typeof token !== 'undefined') {
//             sessionStorage.setItem('token', token);

//             return;
//         }

//         return sessionStorage.getItem('token');
//     },

//     userManager() {
//         const config = {
//             headers: { Authorization: `Bearer ${this._token()}` }
//         };

//         return new Promise((resolve, reject) => {

//             fetch(`${this._url}/users/user_manager`, {
//                 method: 'GET',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 }
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data.users,
//                             count: res.data.count,
//                             status: res.status
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });

//     }
// }

// export default UserService;

import * as api from "..";
import { USER } from "../config/endpoints";

const UserService = {
    async getUserById({ user_id }) {
        const resp = await api.post(USER.GET_USER_BY_ID, { user_id });
        return resp;
    },
    async getUserList({ params = null, sort, filter, pagination }) {
        const resp = await api.post(USER.GET_USER_LIST, ...arguments);
        return resp;
    },
    async createUser(formData) {
        const resp = await api.asFormData(USER.CREATE_USER, formData);
        return resp;
    },
    async updateUser(formData) {
        const resp = await api.asFormData(USER.UPDATE_USER, formData);
        return resp;
    },
    async deleteUser({ user_id }) {
        const resp = await api.post(USER.DELETE_USER, { user_id });
        return resp;
    },
    async getNewUserDropdowns() {
        const resp = await api.get(USER.NEW_USER_DROPDOWNS);
        return resp;
    },
    async bulkCreateUserDebtor({ debtor_ids }) {
        const resp = await api.post(USER.BULK_CREATE_USER_DEBTOR, ...arguments);
        return resp;
    },
};

export default UserService;
