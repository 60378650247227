const AGENT = {
    SUMMARY: "agents/summary",
    BULK_ASSIGN_CLIENTS: "agents/bulk_assign_clients",
    ASSIGN_CLIENT_TO_AGENT: "agents/assign_client_to_agent",
    UNASSIGN_CLIENTS: "agents/unassign_clients_from_agent",
    LOGOUT_BREAKS: "agents/logout_breaks",
    AGENT_REGISTER: "managers/agent_register",
    TIME_LOG_START: "agents/time_logs/start",
    TIME_LOG_STOP: "agents/time_logs/stop",
    GET_TIME_LOG_TYPES: "agents/time_logs/types",
    CALLS: "agents/calls",
    MESSAGE: "sms/send",
    BULK_MESSAGE: "sms/send_bulk",
    CAMPAIGN_SUMMARY: "campaigns/summary",
    CHECK_AGENTS_CAMPAIGN_SUMMARY: "campaigns/next",
    ASSIGN_CAMPAIGN_TO_AGENT: "campaigns/assign",
    GET_AVAILABLE_CLIENTS_FROM_ACTIVE_CAMPAIGNS: "campaigns/avl-clients",
    GET_GRAPH_SUMMARY: "agents/graph-summary",
    GET_CAMPAIGN_STATISTICS: "campaigns/campaign-summary-stats",
};

const AUTH = {
    GET_USER_FROM_TOKEN: "users/get_user",
    REGISTER: "register",
    LOGIN: "login",
    LOGOUT: "logout",
    RESET_PASS_INIT: "forgot-password",
    RESET_PASS: "reset-password",
};

const CALL = {};

const CASE = {
    FEES: "debtor_cases/case/fees",
    NOTES: "debtor_cases/case/fees/CRUD",
    PTPS: "debtor_cases/case/fees/CRUD",
    SUMMARY: "debtor_cases/case/fees/debtor_summary",
    CALLS: "debtor_cases/case/calls",
    MESSAGES: "debtor_cases/case/messages",
    EMAILS: "debtor_cases/case/emails",
    EDIT_DEBTOR: "debtor_cases/case/fees/details/edit",
    HOSPITAL_VISITS: "debtor_cases/debtor_hospital_visits"
};

const DEBTOR_CASE_PTPS = {
    LIST: "debtor_cases/case/ptps",
    CREATE: "debtor_cases/case/ptps/create",
    MANGER_OVERRIDES_LIST: "debtor_cases/case/ptps/override/index",
    AGENT_OVERRIDES_STATUS_LIST: "debtor_cases/case/ptps/override/agent_status_list",
    CREATE_OVERRIDE: "debtor_cases/case/ptps/override/create",
    UPDATE_OVERRIDE: "debtor_cases/case/ptps/override/update",
    CHECK_OVERRIDES: "debtor_cases/case/ptps/override/available",
};

const CLIENT = {
    CREATE: "clients/create",
    UPDATE: "clients/update",
    CLIENT_REGISTER: "clients/client_register",
    DEBTOR_REGISTER: "clients/debtor_register",
    DELETE: "clients/delete",
    GET_CLIENT: "clients/get",
};

const CLIENT_FEE = {
    LIST: "clients/fees/crud_list",
    CREATE: "clients/fees/create",
    UPDATE: "clients/fees/update",
    DELETE: "clients/fees/delete",
};

const COMMS = {
    GET_AGENT_EMAILS: "agents/emails",
    AGENT_SEND_EMAIL_TO_DEBTOR: "agents/send_email_to_debtor",
    DEBTOR_SEND_EMAIL_TO_AGENT: "debtors/send_email_to_agent",
    AGENT_SEND_REPLY_EMAIL: "agents/send_reply_email",
    MANAGER_EMAIL_DELETE: "emails/delete",
    MANAGER_EMAIL_SHARE: "emails/share",
    GET_DEBTOR_CASE_EMAILS: "debtor_cases/case/emails",
    GET_POSSIBLE_RECIPIENTS: "emails/recipients",
    GET_DEBTOR_RECIPIENTS: "emails/debtor_recipients",
    GET_MESSAGE_TEMPLATES: "messages/templates",
    FLAG_EMAIL: "emails/flag",
    SET_READ_EMAIL: "emails/read",
    GET_EMAIL_THREAD: "emails/thread",
    AGENT_SEND_BULK_EMAILS_TO_DEBTORS: "agents/send_bulk_emails_to_debtors",
    EMAIL_STATEMENT: "debtors/statement/email",
    DOWNLOAD_STATEMENT: "debtors/statement/download",
};

const EVENT = {
    CREATE: "events/create",
    UPDATE: "events/update",
    DELETE: "events/delete",
    LIST: "events",
};

const TIME_LOG = {
    START_TIME: "",
    STOP_TIME: "",
};

const USER = {
    CREATE_USER: "users/create",
    GET_USER_BY_ID: "users/get_user_admin",
    GET_USER_LIST: "users/user_manager",
    UPDATE_USER: "users/update",
    DELETE_USER: "users/delete",
    NEW_USER_DROPDOWNS: "users/new_user",
    BULK_CREATE_USER_DEBTOR: "users/bulkcreateuserdebtor",
};

const DEBTOR_PORTAL = {
    PASSWORD_OTP: "users/password_otp",
    PASSWORD_UPDATE: "users/password_update",
    DEBTOR_CASE_REGISTER: "debtor_cases/case/debtor_case_registry",
};

export { AGENT, AUTH, CALL, CASE, DEBTOR_CASE_PTPS, CLIENT, CLIENT_FEE, COMMS, EVENT, TIME_LOG, USER, DEBTOR_PORTAL };
