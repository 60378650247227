import { CircularProgress, IconButton } from "@material-ui/core";
// import play_icon from "../../assets/icons/FontAwsome (play-circle).png";
import { styled } from "@material-ui/core/styles";
import { PauseCircleOutline, PlayCircleOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { colors } from "../../constants/styles";

const CustomProgress = styled(CircularProgress)({
	color: colors.orange,
	position: "absolute",
	// top: -6,
	// left: -6,
	zIndex: 1,
});
const CustomPlayIcon = styled(PlayCircleOutline)({
	color: colors.orange,
});
const CustomPauseIcon = styled(PauseCircleOutline)({
	color: colors.orange,
});
const useAudio = (url) => {
	const [audio] = useState(new Audio(url));
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);

	useEffect(() => {
		playing ? audio.play() : audio.pause();
	}, [playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		return () => {
			audio.removeEventListener("ended", () => setPlaying(false));
		};
	}, []);

	return [playing, toggle];
};

const PlayAudioButton = ({ url }) => {
	const [playing, toggle] = useAudio(url);

	return (
		<div>
			<IconButton onClick={toggle}>
				{playing ? (
					<>
						<CustomPauseIcon />
						<CustomProgress />
					</>
				) : (
					<CustomPlayIcon />
					// <img src={play_icon} alt='play' />
				)}
			</IconButton>
		</div>
	);
};

export default PlayAudioButton;
