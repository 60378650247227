import {
    Box,
    createTheme,
    MuiThemeProvider,
    Paper,
    TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { styled } from "@material-ui/styles";
import MaterialTable, {
    MTableToolbar,
    MTableCell,
    MTableHeader,
} from "@material-table/core";
import { forwardRef } from "react";
import cancel_icon from "../../assets/icons/Cancel.png";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import delete_icon from "../../assets/icons/Layer -14.png";
import save_icon_orange from "../../assets/icons/Layer -16.png";
import { colors } from "../../constants/styles";
import TableSearch from "./components/TableSearch";
import { MTableActions } from "material-table";

const tableTheme = createTheme({
    overrides: {
        MuiTable: {
            root: {
                minWidth: 650,
                borderLeft: `16px solid ${colors.white}`,
                borderRight: `16px solid ${colors.white}`,
            },
        },
        MuiTableCell: {
            root: {
                height: "38px",
                padding: 0,
                fontFamily: "Avenir Next",
            },
            body: {
                color: `${colors.blue} !important`,
                //padding: '6px 0px',

                fontSize: "12px !important",
                letterSpacing: "0px",
                fontFamily: "Avenir Next Medium !important",
                "@media (min-width:1920px)": {
                    fontSize: "15px",
                    //padding: '10px 2px',
                },
            },
            head: {
                color: colors.blue,
                fontWeight: "bold",
                fontSize: "12px",
                textTransform: "uppercase",
                letterSpacing: "0px",
                fontFamily: "Avenir Next Bold",
                minWidth: 100,
                overflow: "auto",
                whiteSpace: "nowrap",
                wordWrap: "normal",
                textOverflow: "ellipsis",
                "@media (min-width:1920px)": {
                    fontSize: "15px",
                },
            },
        },
        MuiPaper: {
            elevation1: {
                padding: "0px 20px",
            },
        },
        MuiTablePagination: {
            root: {
                float: "none !important",
                border: "none !important",
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: "18px",
            },
            input: {
                padding: "9px 12px",
            },
        },
        MuiPopover: {
            paper: {
                border: "1px solid #01579b !important",
                borderRadius: "15px !important",
            },
        },
    },
    palette: {
        primary: {
            main: "#002b64",
        },
        secondary: {
            main: "#ec7346",
        },
    },
});

const TablePaper = styled(Paper)({
    padding: "0 25px",
    borderRadius: "25px !important",
});

const CustomMaterialTable = ({ validationSchema = null, ...tableParams }) => {
    const validateRow = (field, row) => {
        if (validationSchema != null) {
            try {
                validationSchema.validateSyncAt(field, row);
                return true;
            } catch (e) {
                return {
                    isValid: false,
                    helperText: e.message,
                };
            }
        }

        // return undefined;
    };

    return (
        <MuiThemeProvider theme={tableTheme}>
            <MaterialTable
                {...tableParams}
                style={{ maxWidth: "100%" }}
                columns={
                    tableParams.columns != null
                        ? tableParams.columns.map((column) => ({
                              ...column,
                              width: column.width ?? "auto",
                              validate: (row) => validateRow(column.field, row),
                          }))
                        : []
                }
                icons={{
                    Check: forwardRef((props, ref) => (
                        <img
                            src={save_icon_orange}
                            alt="save"
                            ref={ref}
                            {...props}
                        />
                    )),
                    Clear: forwardRef((props, ref) => (
                        <img
                            src={cancel_icon}
                            alt="save"
                            ref={ref}
                            {...props}
                        />
                    )),
                    Edit: forwardRef((props, ref) => (
                        <img src={edit_icon} alt="edit" ref={ref} {...props} />
                    )),
                    Delete: forwardRef((props, ref) => (
                        <img
                            src={delete_icon}
                            alt="delete"
                            ref={ref}
                            {...props}
                        />
                    )),
                    ...tableParams.icons,
                }}
                components={{
                    Container: (props) => (
                        <TablePaper {...props} elevation={0} />
                    ),
                    EditField: (props) => {
                        switch (props.columnDef.type) {
                            case "date":
                                return (
                                    <KeyboardDatePicker
                                        {...props}
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        margin="none"
                                    />
                                );

                            case "textArea":
                                return (
                                    <TextField
                                        {...props}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                        multiline
                                        rows={2}
                                        maxRows={4}
                                        variant="outlined"
                                    />
                                );
                            default:
                                return (
                                    <TextField
                                        {...props}
                                        onChange={(e) =>
                                            props.onChange(e.target.value)
                                        }
                                        variant="outlined"
                                    />
                                );
                        }
                    },
                    Cell: (props) => (
                        <MTableCell {...props} style={{ paddingRight: 10 }} />
                    ),
                    Header: (props) => (
                        <MTableHeader
                            {...props}
                            style={{ paddingRight: "10px !important" }}
                        />
                    ),
                    Actions: (props) => <MTableActions {...props} />,
                    ...tableParams.components,
                }}
                options={{
                    ...tableParams.options,
                    pageSize: 10,
                }}
            />
        </MuiThemeProvider>
    );
};

export default CustomMaterialTable;
