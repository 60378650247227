import { CREATE_FEES, DELETE_FEES, GET_CLIENTS_REGISTER, GET_CLIENT_DETAILS, GET_CLIENT_FEES, GET_CLIENT_MANAGER, IS_LOADING, REFRESH_FEES, SET_NEW_FEES, UPDATE_FEES } from "../../constants/reduxConstants";

let initialState = {
    listClientsRegister: [], 
    isLoading: false, 
    fees: null, 
    client_details: null,
    client_manager: null,
  }
  
  const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLIENTS_REGISTER:
            return {...state, listClientsRegister: action.payload}
        case IS_LOADING:
            return {...state, isLoading: action.payload} 
        case GET_CLIENT_FEES:
            return {...state, fees: action.payload} 
        case GET_CLIENT_DETAILS:
            return {...state, client_details: action.payload} 
        case DELETE_FEES:
            return {
                ...state, fees: {
                ...state.fees, 
                data: state.fees.data.filter(item => item.id !== action.payload)
            }}    
         case UPDATE_FEES: 
            return {
                ...state, fees: {
                    ...state.fees,
                    data: state.fees.data.map(item => {
                        if(item.id === action.payload.id) {
                            return {...action.payload}
                        }
                        return item; 
                    })
                }
            }
        case SET_NEW_FEES: 
            return {
                ...state, fees: {
                    ...state.fees,
                    data: [...state.fees.data, action.payload]
                }
            }     
        case REFRESH_FEES: 
            return {...state, fees: action.payload}   
        case GET_CLIENT_MANAGER: 
            return {...state, client_manager: action.payload}            
      default:
        return state; 
    }
  }

  export const setListClientsRegister = (list) => ({type: GET_CLIENTS_REGISTER, payload: list}); 
  export const setIsLoadingClients = (value) => ({type: IS_LOADING, payload: value});  
  export const setClientFees = (list) => ({type: GET_CLIENT_FEES, payload: list});
  export const setNewFees = (param) => ({type: SET_NEW_FEES, payload: param});  
  export const setClientDetails = (details) => ({type: GET_CLIENT_DETAILS, payload: details}); 
  export const filterFees = (id) => ({type: DELETE_FEES, payload: id}); 
  export const setUpdateFees = (param) => ({type: UPDATE_FEES, payload: param});
  export const refreshFeesList = (param) => ({type: REFRESH_FEES, payload: param}); 
  export const setClientManager = (clients) => ({type: GET_CLIENT_MANAGER, payload: clients}); 


  export default clientsReducer; 
