const LocaleSettings = {
	locale: "en-ZA",
	currencyCode: "ZAR",
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
};

const MaterialTableCurrencySettings = LocaleSettings; // they are identical right now so just use as alias

export { LocaleSettings, MaterialTableCurrencySettings };
