import React, { useRef, useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { ArrowDown, Box, ButtonBack, TitleSection } from "../../common/Layouts";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core";
import icon_delete from "../../assets/icons/Layer -14.png";
import edit_icon from "../../assets/icons/Layer -16.png";
import style from "./Administration.module.css";
import { UserService } from "../../api/services";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { useModal } from "../../providers/hooks/useModal";
import { useGlobalLoader } from "../../providers/hooks/useGlobalLoader";

function EditUser() {
    const { id } = useParams();
    const [dropdowns, setDropdowns] = useState(null);
    const { toggleLoading } = useGlobalLoader();
    const { setToast } = useModal();
    const [user, setUser] = useState({
        name: "",
        surname: "",
        job_title: "",
        user_type: 1,
        manager_id: 1,
        telephone_number: "",
        email: "",
    });
    const [img, setImg] = useState(null);
    const [img64, setImg64] = useState();
    let imgRef;
    const history = useHistory();

    const AddUserSchema = Yup.object().shape({
        name: Yup.string().required("Field is required"),
        surname: Yup.string().required("Field is required"),
        job_title: Yup.string().required("Field is required"),
        user_type: Yup.number().required("Field is required"),
        manager_id: Yup.number().required("Field is required"),
        telephone_number: Yup.string().required("Field is required"),
        email: Yup.string().required("Field is required").email("This string must be email"),
    });

    const imgToBase64 = async (file) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImg64(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const setFormImg = (e) => {
        imgToBase64(e.target.files[0]);
        setImg(e.target.files[0]);
    };

    const getDropdowns = async () => {
        const resp = await UserService.getNewUserDropdowns();
        if (resp.status === "success") {
            setDropdowns(resp.data);
        }
    };

    useEffect(() => {
        getDropdowns();
    }, []);

    const initialValues = {
        name: "",
        surname: "",
        job_title: "",
        user_type: 1,
        manager_id: 1,
        telephone_number: "",
        email: "",
    };

    const formRef = useRef();
    let sizeArrow = window.innerWidth > 1400 ? 13 : 8;

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            formRef.current.handleSubmit();
        }
    };

    const getUser = async () => {
        const resp = await UserService.getUserById({ user_id: id });
        if (resp.status === "success") {
            setUser(resp.data.user);
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontFamily: "Avenir Next" }}>User manager / </span>
                {` edit user`}
            </TitleSection>
            <Box padding={"30px 37px"} paddingSmall={"23px 29px"}>
                <Grid container justifyContent="space-between">
                    <div className={style.title}>USER DETAILS</div>
                    <Grid className={style.images_wrapper}>
                        <img
                            onClick={formRef.current ? () => formRef.current.handleSubmit() : () => {}}
                            src={edit_icon}
                            alt=""
                        />
                        {/* <img src={icon_delete} alt="" /> */}
                    </Grid>
                    <Grid container justifyContent="flex-start">
                        <Grid item md={2}>
                            <Grid container justifyContent="flex-start">
                                <div>
                                    <input ref={(r) => (imgRef = r)} type="file" hidden onChange={setFormImg}></input>
                                    {img64 || user.profile_pic_url ? (
                                        <img
                                            onClick={() => imgRef?.click()}
                                            className={style.add_photo}
                                            src={img64 || user.profile_pic_url}
                                        />
                                    ) : (
                                        <div onClick={() => imgRef?.click()} className={style.add_photo}>
                                            + Add Photos
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item md={9}>
                            <Formik
                                innerRef={formRef}
                                initialValues={user}
                                enableReinitialize
                                validationSchema={AddUserSchema}
                                onSubmit={async (values) => {
                                    toggleLoading();
                                    const formData = new FormData();
                                    Object.keys(values).forEach((key) => {
                                        formData.append(key, values[key]);
                                    });
                                    formData.append("user_id", id);
                                    if (img) {
                                        formData.append("photo", img);
                                    }
                                    const resp = await UserService.updateUser(formData);
                                    console.log(resp);
                                    if (resp.status === "success") {
                                        toggleLoading();
                                        setToast("User updated successfully", "success");
                                        history.goBack();
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    setValues,
                                    setTouched,
                                    resetForm,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>First Name</div>
                                                <input
                                                    name={"name"}
                                                    type={"text"}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    error={errors.name}
                                                />
                                                {errors.name && touched.name && <label>{errors.name}</label>}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Last Name</div>
                                                <input
                                                    name={"surname"}
                                                    type={"text"}
                                                    value={values.surname}
                                                    onChange={handleChange}
                                                    error={errors.surname}
                                                />
                                                {errors.surname && touched.surname && <label>{errors.surname}</label>}
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                style={{ position: "relative" }}
                                                className={style.crapper_input}
                                            >
                                                <div className={style.input_title}>Manager Name</div>
                                                <select
                                                    name={"manager_id"}
                                                    className={"select-input"}
                                                    type={"text"}
                                                    value={values.manager_id}
                                                    onChange={handleChange}
                                                    error={errors.manager_id}
                                                >
                                                    {dropdowns?.managers?.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                {errors.manager_id && touched.manager_id && (
                                                    <label>{errors.manager_id}</label>
                                                )}
                                                <span style={{ position: "absolute" }} className={style.select_field}>
                                                    <ArrowDown
                                                        base={sizeArrow}
                                                        side={sizeArrow - 4}
                                                        color={"#959595"}
                                                    />
                                                </span>
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Telephone No.</div>
                                                <input
                                                    name={"telephone_number"}
                                                    type={"text"}
                                                    value={values.telephone_number}
                                                    onChange={handleChange}
                                                    error={errors.telephone_number}
                                                />
                                                {errors.telephone_number && touched.telephone_number && (
                                                    <label>{errors.telephone_number}</label>
                                                )}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Job Title</div>
                                                <input
                                                    name={"job_title"}
                                                    type={"text"}
                                                    value={values.job_title}
                                                    onChange={handleChange}
                                                    error={errors.job_title}
                                                />
                                                {errors.job_title && touched.job_title && (
                                                    <label>{errors.job_title}</label>
                                                )}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>User Type</div>
                                                <select
                                                    name={"user_type"}
                                                    className={"select-input"}
                                                    type={"text"}
                                                    value={values.user_type}
                                                    onChange={handleChange}
                                                    error={errors.user_type}
                                                >
                                                    {dropdowns?.user_types?.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>

                                                {errors.user_type && touched.user_type && (
                                                    <label>{errors.user_type}</label>
                                                )}
                                                <span style={{ position: "absolute" }} className={style.select_field}>
                                                    <ArrowDown
                                                        base={sizeArrow}
                                                        side={sizeArrow - 4}
                                                        color={"#959595"}
                                                    />
                                                </span>
                                            </Grid>
                                            <Grid item md={12} className={style.wrapper_input}>
                                                <div className={style.input_title}>Email Address</div>
                                                <input
                                                    name={"email"}
                                                    type={"text"}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    error={errors.email}
                                                />
                                                {errors.email && touched.email && <label>{errors.email}</label>}
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default EditUser;
