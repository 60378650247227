import React, { useState } from 'react';
import CommonModal from './CommonModal';
import ButtonComponent from './ButtonComponent';
import useAPIError from '../providers/hooks/useAPIError';
import styled from 'styled-components'
import { colors } from '../constants/styles';

export default function APIErrorNotification() {
  const { error, removeError } = useAPIError();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    removeError();
    handleClose();
  };

  const ModalContent = styled.div`
  padding: 34px 25px; 
  box-sizing: border-box;
  span {
   &.title {
    font-size: 30px;
    color: ${colors.blue};
    text-align: left;
    font-family: 'Avenir Next Bold';
    @media (min-width:1920px){
      font-size: 40px;
   };
  }

  }
`;

  return (
    <CommonModal
      open={!!error}
      show={show} 
      onHide={handleSubmit}
    >
      <ModalContent>
          <span className="title">An error occurred</span>
          {error && error.message && <p>({error.message})</p>}
        <ButtonComponent
            height={30}
            width={89} 
            fontSize={20}
            title="OK"
            handler={handleSubmit}
            />
        </ModalContent>
        
    </CommonModal>
  )
}

export { APIErrorNotification }