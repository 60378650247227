import * as api from "..";
import { AGENT } from "../config/endpoints";

const AgentService = {
	async agentsManager({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.AGENT_REGISTER, ...arguments);
		return resp;
	},
	async agentSummary({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.SUMMARY, ...arguments);
		return resp;
	},
	async assignClients({ agents_clients }) {
		const resp = await api.post(AGENT.BULK_ASSIGN_CLIENTS, ...arguments);
		return resp;
	},
	async assignClientToAgent({ agents_clients, request_by_agent }) {
		const resp = await api.post(AGENT.ASSIGN_CLIENT_TO_AGENT, ...arguments);
		return resp;
	},
	async unassignClients({ agent_id, client_ids }) {
		const resp = await api.post(AGENT.UNASSIGN_CLIENTS, ...arguments);
		return resp;
	},
	async timeLogStart({ type }) {
		const resp = await api.post(AGENT.TIME_LOG_START, ...arguments);
		return resp;
	},
	async timeLogStop({ type }) {
		const resp = await api.post(AGENT.TIME_LOG_STOP, ...arguments);
		return resp;
	},
	async getAgentsLogoutBreaks({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.LOGOUT_BREAKS, ...arguments);
		return resp;
	},
	async getTimeLogBreakTypes() {
		const resp = await api.get(AGENT.GET_TIME_LOG_TYPES);
		return resp;
	},
	async getAgentCalls({ agent_id, sort, filter, paginate }) {
		const resp = await api.post(AGENT.CALLS, ...arguments);
		return resp;
	},
	async sendMessage({ number, account_no, message }) {
		const resp = await api.post(AGENT.MESSAGE, ...arguments);
		return resp;
	},
	async sendBulkMessage({ account_no }) {
		const resp = await api.post(AGENT.BULK_MESSAGE, ...arguments);
		return resp;
	},
	async getAgentsCampaignSummary({client_id, sort, filter, paginate }) { //TODO add the right api call parameters and probably change service name too
		const resp = await api.post(AGENT.CAMPAIGN_SUMMARY, ...arguments);
		return resp;
	},

	async checkAgentsCampaignSummary() {
		const resp = await api.post(AGENT.CHECK_AGENTS_CAMPAIGN_SUMMARY);
		return resp;
	},

	async assignCampaignToAgent({client_id}) {
		const resp = await api.post(AGENT.ASSIGN_CAMPAIGN_TO_AGENT, ...arguments);
		return resp;
	},

	async getAvailableClientsFromActiveCampaigns() {
		const resp = await api.get(AGENT.GET_AVAILABLE_CLIENTS_FROM_ACTIVE_CAMPAIGNS);
		return resp;
	},

	async getGraphSummary({client_id}) {
		const resp = await api.post(AGENT.GET_GRAPH_SUMMARY, ...arguments);
		return resp;
	},

	async getCampaignStatistics({client_id}) {
		const resp = await api.post(AGENT.GET_CAMPAIGN_STATISTICS, ...arguments);
		return resp;
	}
};

export default AgentService;
