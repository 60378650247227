const PreferredLanguages = [
	"English",
	"isiNdebele",
	"isiXhosa",
	"isiZulu",
	"Sesotho",
	"Sesotho sa Leboa (Sepedi)",
	"Setswana",
	"siSwati",
	"Tshivenda",
	"Xitsonga",
	"Afrikaans",
];

export { PreferredLanguages };
