import * as api from "..";
import { DEBTOR_CASE_PTPS } from "../config/endpoints";

const DebtorCasePtpService = {
    async listPtps({ account_no, ...sort_filter_paginate }) {
        const resp = await api.post(DEBTOR_CASE_PTPS.LIST, {
            account_no,
            ...sort_filter_paginate,
        });
        return resp;
    },
    async create({ account_no, status, type, due_date, amount, frequency_id, period, discount }) {
        const resp = await api.post(DEBTOR_CASE_PTPS.CREATE, ...arguments);
        return resp;
    },

    async updatePtpOverride({ id, approved, notes }) {
        const resp = await api.post(DEBTOR_CASE_PTPS.UPDATE_OVERRIDE, ...arguments);
        return resp;
    },

    async createPtpOverride(override_request_reason) {
        const resp = await api.post(DEBTOR_CASE_PTPS.CREATE_OVERRIDE, ...arguments);
        return resp;
    },

    async managerPtpOverrides({ ptp_status_id, ...sort_filter_paginate }) {
        const resp = await api.post(DEBTOR_CASE_PTPS.MANGER_OVERRIDES_LIST, ...arguments);
        return resp;
    },

    async agentPtpOverrides({ ...sort_filter_paginate }) {
        const resp = await api.post(DEBTOR_CASE_PTPS.AGENT_OVERRIDES_STATUS_LIST, ...arguments);
        return resp;
    },

    async checkOverrides(ptp_status_id) {
        console.log(...arguments);
        const resp = await api.post(DEBTOR_CASE_PTPS.CHECK_OVERRIDES, ...arguments);
        return resp;
    },
};

export default DebtorCasePtpService;
