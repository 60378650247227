import { useDispatch, useSelector } from "react-redux";
import {
    setModal as setModalDispatch,
    setPersistent as setPersistentDispatch,
    setToast as setToastDispatch,
    unsetModal as unsetModalDispatch,
    unsetPersistent as unsetPersistentDispatch,
    unsetToast as unsetToastDispatch,
} from "../../redux/actions/navActions";

const useModal = () => {
    const dispatch = useDispatch();
    const currentModal = useSelector((state) => state.nav.modal);
    const setModal = ({ Component, size = "lrg", canClickAway = true, closeBtn = true }) => {
        dispatch(setModalDispatch({ Component, size, canClickAway, closeBtn }));
    };

    const unsetModal = () => {
        dispatch(unsetModalDispatch());
    };

    const setToast = (message, type) => {
        dispatch(setToastDispatch({ message, type }));
    };

    const unsetToast = () => {
        dispatch(unsetToastDispatch());
    };

    const setPersistent = ({ Component, position = { vertical: "bottom", horizontal: "right" } }) => {
        dispatch(setPersistentDispatch({ Component, position }));
    };

    const unsetPersistent = () => {
        dispatch(unsetPersistentDispatch());
    };

    const openShareByEmailModal = ({ action }) => {};

    return {
        currentModal,
        setModal,
        unsetModal,
        setToast,
        unsetToast,
        setPersistent,
        unsetPersistent,
    };
};

export { useModal };
