import React from "react";
import { Search as SearchIcon } from "@material-ui/icons";

const TableSearch = ({
  onChange = () => {},
  value,
  onSearchClick = () => {},
  onKeyDown = () => {},
}) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) onSearchClick();
  };

  return (
    <div className={"table-search rel"}>
      <input
        type="text"
        onChange={onChange}
        value={value}
        onKeyDown={handleKeyDown}
      />
      <div onClick={onSearchClick}>
        <SearchIcon style={{ color: "#fff" }} />
      </div>
    </div>
  );
};

export default TableSearch;
