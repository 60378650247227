import * as api from "..";
import { CASE } from '../config/endpoints';

const CaseService = {
    async getAccountHospitalVisitRecords({ account_no, ...sort_filter_paginate }) {
        const resp = await api.post(CASE.HOSPITAL_VISITS, ...arguments);
        return resp;
    },
}

export default CaseService;
