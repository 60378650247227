import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { colors } from "../constants/styles";
import Avatar from "@material-ui/core/Avatar";
import dashboard_icon from "../assets/icons/Layer -6.png";
import dashboard_icon_active from "../assets/icons/Layer -7.png";
import debtors_icon from "../assets/icons/$.png";
import debtors_icon_active from "../assets/icons/$-1.png";
import agent_icon from "../assets/icons/Group 51.png";
import agent_icon_active from "../assets/icons/Group 406.png";
import calendar_icon from "../assets/icons/FontAwsome (calendar-alt).png";
import calendar_icon_active from "../assets/icons/FontAwsome (calendar-alt)-1.png";
import email_icon from "../assets/icons/Layer 2.png";
import email_icon_active from "../assets/icons/Layer -1.png";
import phone_icon from "../assets/icons/Layer -2.png";
import phone_icon_active from "../assets/icons/Layer -3.png";
import administration_icon from "../assets/icons/Layer -4.png";
import administration_icon_active from "../assets/icons/Layer -5.png";
import logout_icon from "../assets/icons/FontAwsome (power-off).png";
import logo from "../assets/icons/ncpggif_Logo.gif";
import logo_small from "../assets/icons/ncpggif_Logo_small.png";
import close_icon from "../assets/icons/Group 414.png";
import open_icon from "../assets/icons/Group 413.png";
import { useHistory, useLocation } from "react-router-dom";
import {
    ADMINISTRATION,
    AGENT_MANAGER,
    CALENDAR,
    DASHBOARD,
    DEBTORS,
    EMAILS,
    MANAGER_DASHBOARD,
    AGENT_CAMPAIGN_DASHBOARD,
    PHONE,
} from "../constants/pathNames";
import { Grid } from "@material-ui/core";
import { useAuth } from "../providers/hooks/useAuth";
import { useSelector } from "react-redux";
import { useVoip } from "providers/hooks/useVoip";
import { useNotification } from "providers/hooks/useNotification";

const sidebarList = [
    {
        title: "Dashboard",
        path:
            useAuth.user_type_id === 4 ? `${DASHBOARD}${AGENT_CAMPAIGN_DASHBOARD}` : `${DASHBOARD}${MANAGER_DASHBOARD}`,
        icon: dashboard_icon,
        activeIcon: dashboard_icon,
        permission: "view-client-register", //TODO to change permission to include both agent and manager
    },
    {
        title: "Debtors",
        path: `${DASHBOARD}${DEBTORS}`,
        icon: debtors_icon,
        activeIcon: debtors_icon,
        permission: "view-client-register",
    },
    {
        title: "Agent Manager",
        path: `${DASHBOARD}${AGENT_MANAGER}`,
        icon: agent_icon,
        activeIcon: agent_icon,
        permission: "view-agent-register",
    },
    {
        title: "Calendar",
        path: `${DASHBOARD}${CALENDAR}`,
        icon: calendar_icon,
        activeIcon: calendar_icon,
        permission: "view-events",
    },
    {
        title: "Emails",
        path: `${DASHBOARD}${EMAILS}`,
        icon: email_icon,
        activeIcon: email_icon,
        permission: "view-emails",
    },
    {
        title: "Phone",
        path: `${DASHBOARD}${PHONE}`,
        icon: phone_icon,
        activeIcon: phone_icon,
        permission: "view-emails",
    },
    {
        title: "Administration",
        path: `${DASHBOARD}${ADMINISTRATION}`,
        icon: administration_icon,
        activeIcon: administration_icon,
        children: [
            {
                title: "Client manager",
                path: `${DASHBOARD}${ADMINISTRATION}/client_manager`,
            },
            {
                title: "User manager",
                path: `${DASHBOARD}${ADMINISTRATION}/user_manager`,
            },
        ],
    },
];

const useStyles = makeStyles({
    backDrop: {
        backdropFilter: "blur(2px)",
        backgroundColor: "rgba(0,0,0,0.1)",
    },
    list: {
        overflow: "hidden",
        overflowY: "hidden",
        fontFamily: "Avenir Next Medium !important",
        paddingTop: 50,
        width: window.innerWidth / 4,
        height: "100%",
        transition: "all 1s",
        "@media (min-width:1920px)": {
            width: "480px",
            paddingTop: 58,
        },
        "@media (max-height:800px)": {
            paddingTop: 20,
        },
        "& .MuiListItem-gutters": {
            paddingLeft: "35px",
        },
        "& .MuiTypography-body1": {
            fontSize: "1vw",
            color: colors.blue,
            fontFamily: "Avenir Next Medium",
            "@media (min-width:1920px)": {
                fontSize: "20px",
            },
        },
        "& .MuiListItemIcon-root": {
            display: "flex",
            justifyContent: "center",
        },
        "& .MuiListItem-button": {
            height: 50,
            borderTop: "1px solid #E3E3E3",
            "@media (min-width:1920px)": {
                height: 70,
            },
        },
        "& .MuiListItem-button:hover": {
            backgroundColor: colors.blue,
            "& .color_white": {
                color: colors.white,
            },
            "& .MuiTypography-body1": {
                color: colors.orange,
            },
        },
    },
    list2: {
        overflow: "hidden",
        overflowY: "hidden",
        paddingTop: 50,
        transition: "all 1s",
        width: 100,
        "@media (min-width:1921px)": {
            paddingTop: 58,
            width: 129,
        },
        "@media (max-height:800px)": {
            paddingTop: 20,
        },
        "& .MuiListItem-root": {
            justifyContent: "center",
            /* paddingTop: 58, */
        },
        "& .MuiListItem-button": {
            height: 50,
            "@media (min-width:1920px)": {
                height: 70,
            },
        },
        "& .MuiListItem-button:hover": {
            backgroundColor: colors.blue,
        },
        "& .MuiListItemIcon-root": {
            display: "flex",
            justifyContent: "center",
        },
    },
    fullList: {
        width: 30,
    },
    itemList: {
        padding: "16px",
    },
    avatar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        fontSize: "1.2vw",
        fontWeight: "bold",
        paddingLeft: "25px",
        marginBottom: 20,
        "@media (min-width:1920px)": {
            paddingLeft: "20px",
            marginTop: 50,
            fontSize: "25px",
        },
        "@media (max-height:800px)": {
            marginTop: 0,
        },
        color: colors.blue,
        "& .MuiAvatar-root": {
            width: 70,
            height: 70,
            marginRight: 20,
            "@media (min-width:1920px)": {
                marginRight: 26,
                width: 95,
                height: 95,
            },
        },
    },
    logo: {
        margin: "40px 0px",
        // paddingLeft: "35px",
        textAlign: "center",
        "@media (max-height:800px)": {
            margin: "10px 0px",
            paddingLeft: "45px",
        },
        "& img": {
            height: "100px",
            width: "100px",

            "@media (min-width:1920px)": {
                height: "150px",
                width: "150px",
            },
        },
    },
    logo_small: {
        margin: "40px 0px",
        textAlign: "center",
        "@media (max-height:800px)": {
            margin: "10px 0px",
        },
        "& img": {
            "@media (max-width:1920px)": {
                width: "60px",
            },
        },
    },
    wrapper_btn: {
        display: "flex",
        justifyContent: "flex-end",
        "& img": {
            width: "18px",
            "@media (min-width:1920px)": {
                width: "23px",
            },
        },
    },
    wrapper_btn_2: {
        display: "flex",
        justifyContent: "center",
        "& img": {
            width: "28px",
            "@media (min-width:1920px)": {
                width: "33px",
            },
        },
    },
    activeItem: {
        backgroundColor: colors.lightOrange,
        "& .MuiTypography-body1": {
            color: colors.darkBlue,
        },
        "& .color_white": {
            color: colors.white,
        },
    },
    logo_img: {
        width: "26px",
        "@media (min-width:1920px)": {
            width: "36px",
        },
    },
    debtors: {
        height: "35px",
        "@media (min-width:1920px)": {
            height: "40px",
        },
    },
    wrap_admin: {
        marginLeft: "180px",
    },
    admin_text: {
        textAlign: "left",
        fontSize: "1vw",
        color: colors.blue,
        fontFamily: "Avenir Next Medium",
        lineHeight: "30px",
        "@media (min-width:1920px)": {
            fontSize: "20px",
        },
    },
    active_adminText: {
        color: colors.white,
        cursor: "pointer",
    },
    color_active: {
        color: colors.orange,
    },
});

export default function Nawbar({ handleLogout }) {
    const { connection, handlePhone, reservation } = useVoip();
    const { handleCalendarEventNotification } = useNotification();
    const { auth } = useSelector((state) => state);
    const classes = useStyles();
    const { pathname } = useLocation();
    const { hasPermission } = useAuth();
    const history = useHistory();
    const [drawerState, setDrawerState] = useState({ left: false });
    const [isOpen, setIsOpen] = useState(false);
    const [itemActive, setItemActive] = useState("");
    let path = pathname.split(":");
    let formatPath = path.length > 1 ? path[0].slice(0, -1) : pathname;

    useEffect(() => {
        if (connection != null && reservation != null) {
            handlePhone();
        }
    }, [connection, reservation]);

    React.useEffect(() => {
        if (window.Echo) {
            try {
                window.Echo?.channel("calendar")
                    .subscribed(() => {
                        console.log("You are subscribed");
                    })
                    .listen(".event-notification", (data) => {
                        if (auth.id === data.user_id) {
                            handleCalendarEventNotification(data);
                        }
                    });
            } catch (e) {
                console.log(e + "event listener issue");
            }
        }
    }, [window.Echo]);

    useEffect(() => {
        if (isOpen) {
            setDrawerState({ left: true });
        }
    }, [isOpen]);

    useEffect(() => {
        setItemActive(formatPath);
    }, [pathname, formatPath]);

    const handlerMouseEnter = (el) => {
        setItemActive(el);
    };

    const generateCustomStyle = (item) => {
        if (item.children) {
            return {
                height: "120px",
                flexDirection: "column",
                justifyContent: "flex-start",
            };
        }
    };

    const generateStyleTextAdmin = (item, idx) => {
        let selectors = `${classes.admin_text} admin_hover`;

        if (formatPath.includes(item.path) && itemActive === formatPath) {
            selectors += `${classes.active_adminText}`;
        }
        if (idx === 0 && formatPath === "/dashboard/administration/client_manager") {
            selectors += ` ${classes.color_active}`;
        }
        if (idx === 0 && formatPath !== "/dashboard/administration/client_manager") {
            selectors += ` color_white`;
        }
        if (idx === 1 && formatPath.includes("/dashboard/administration/user_manager")) {
            selectors += ` ${classes.color_active}`;
        }
        if (idx === 1 && !formatPath.includes("/dashboard/administration/user_manager")) {
            selectors += ` color_white`;
        }

        return selectors;
    };

    const handleClick = (item) => {
        if (!item.children) {
            history.push(item.path);
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        if (isOpen) {
            setIsOpen(false);
        }
        setDrawerState({ left: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={classes.wrapper_btn}>
                <Button onClick={toggleDrawer(anchor, false)}>
                    <img src={close_icon} alt="" />
                </Button>
            </div>
            <List>
                <div className={classes.avatar}>
                    <ListItemIcon>
                        <Avatar src={auth.profile_pic_url}></Avatar>
                    </ListItemIcon>
                    <h3>{auth.name}</h3>
                </div>
                <div onMouseLeave={() => handlerMouseEnter(formatPath)}>
                    {sidebarList.map((item, index) =>
                        hasPermission(item.permission) ? (
                            <ListItem
                                button
                                key={index}
                                style={generateCustomStyle(item)}
                                className={
                                    formatPath.includes(item.path) && itemActive === formatPath
                                        ? classes.activeItem
                                        : ""
                                }
                                onClick={() => handleClick(item)}
                                onMouseEnter={() => handlerMouseEnter(item.path)}
                            >
                                {!item.children ? (
                                    <>
                                        <ListItemIcon>
                                            {
                                                <img
                                                    className={
                                                        item.title !== "Debtors" ? classes.logo_img : classes.debtors
                                                    }
                                                    src={!itemActive.includes(item.path) ? item.icon : item.activeIcon}
                                                    alt=""
                                                />
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </>
                                ) : (
                                    <>
                                        <Grid container onClick={() => history.push(item.children[0].path)}>
                                            <ListItemIcon>
                                                {
                                                    <img
                                                        className={
                                                            item.title !== "Debtors"
                                                                ? classes.logo_img
                                                                : classes.debtors
                                                        }
                                                        src={
                                                            !itemActive.includes(item.path)
                                                                ? item.icon
                                                                : item.activeIcon
                                                        }
                                                        alt=""
                                                    />
                                                }
                                            </ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </Grid>
                                        {item.children && (
                                            <Grid container className={classes.wrap_admin} direction="column">
                                                {item.children.map((child, idx) => (
                                                    <Grid
                                                        key={child.title}
                                                        onClick={() => history.push(child.path)}
                                                        className={generateStyleTextAdmin(item, idx)}
                                                    >
                                                        &middot; {`${child.title}`}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </ListItem>
                        ) : null,
                    )}
                    <ListItem
                        button
                        onMouseEnter={() => handlerMouseEnter("/logout")}
                        style={{ borderBottom: "1px solid #E3E3E3" }}
                        onClick={handleLogout}
                        className={classes.itemList}
                    >
                        <ListItemIcon>
                            <img className={classes.logo_img} src={logout_icon} alt="" />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                style: {
                                    color: colors.orange,
                                },
                            }}
                            primary={"Logout"}
                        />
                    </ListItem>
                </div>
                <div className={classes.logo}>
                    <img src={logo_small} alt="" />
                </div>
            </List>
        </div>
    );

    const list2 = (anchor) => (
        <div
            className={clsx(classes.list2)}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={classes.wrapper_btn_2}>
                <Button onClick={() => setIsOpen(true)}>
                    <img src={open_icon} alt="" />
                </Button>
            </div>
            <List>
                <div className={classes.avatar} style={{ visibility: "hidden" }}>
                    <ListItemIcon>{"14"}</ListItemIcon>
                    <h3>{"Name"}</h3>
                </div>
                <div onMouseLeave={() => handlerMouseEnter(formatPath)}>
                    {sidebarList.map((item, index) =>
                        hasPermission(item.permission) ? (
                            <ListItem
                                button
                                key={index}
                                className={
                                    formatPath.includes(item.path) && itemActive.includes(item.path)
                                        ? classes.activeItem
                                        : ""
                                }
                                onClick={() => history.push(item.path)}
                                onMouseEnter={() => handlerMouseEnter(item.path)}
                            >
                                <ListItemIcon>
                                    {
                                        <img
                                            className={item.title !== "Debtors" ? classes.logo_img : classes.debtors}
                                            src={!itemActive.includes(item.path) ? item.icon : item.activeIcon}
                                            alt=""
                                        />
                                    }
                                </ListItemIcon>
                            </ListItem>
                        ) : null,
                    )}
                    <ListItem
                        button
                        onMouseEnter={() => handlerMouseEnter("/logout")}
                        onClick={handleLogout}
                        className={classes.itemList}
                    >
                        <ListItemIcon>
                            <img className={classes.logo_img} src={logout_icon} alt="" />
                        </ListItemIcon>
                    </ListItem>
                </div>
                <div className={classes.logo_small}>
                    <img src={logo_small} alt="" />
                </div>
            </List>
        </div>
    );

    return (
        <div>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        variant="temporary"
                        anchor={anchor}
                        open={drawerState[anchor]}
                        BackdropProps={{
                            classes: {
                                root: classes.backDrop,
                            },
                        }}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>

                    <Drawer
                        variant="persistent"
                        anchor={anchor}
                        open={!drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list2(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
