import React from "react";
import DashboardRouter from "../../common/DashboardRouter";
import { PersistentContainer } from "../../components/modal/PersistentContainer";
import DashboarLayout from "./DashboarLayout";

function Dashboard() {
	return (
		<DashboarLayout>
			<DashboardRouter />
			<PersistentContainer />
		</DashboarLayout>
	);
}

export default Dashboard;
