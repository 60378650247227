import { Grid, TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BluePillButton from "components/buttons/BluePillButton";
import EditSelect from "components/table/components/EditSelect";
import { MaterialTableCurrencySettings } from "constants/locale";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ClientFeeService, ClientService } from "../../api/services";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import send_icon from "../../assets/icons/FontAwsome (external-link-alt).png";
import icon_delete from "../../assets/icons/Layer -14.png";
import save_icon_orange from "../../assets/icons/Layer -16.png";
import { Box, ButtonBack, TitleSection } from "../../common/Layouts";
import CommonModal from "../../components/CommonModal";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import useAPIError from "../../providers/hooks/useAPIError";
import { useGlobalLoader } from "../../providers/hooks/useGlobalLoader";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import { useModal } from "../../providers/hooks/useModal";
import { deleteClient } from "../../redux/actions/clientsAction";
import style from "./Administration.module.css";

const detailsField = {
    name: "CLIENT NAME",
    contact_name: "CONTACT NAME",
    contact_surname: "CONTACT SURNAME",
    email: "EMAIL ADDRESS",
    contact_number: "CONTACT NUMBER",
    address: "CLIENT ADDRESS",
};

const AddUserSchema = Yup.object().shape({
    name: Yup.string().required("Field is required"),
    contact_name: Yup.string().required("Field is required"),
    contact_surname: Yup.string().required("Field is required"),
    contact_number: Yup.string()
        .min(10)
        .matches(/^([0-9\s\-\+\(\)]*)$/, "Is not in correct format")
        .required("Field is required"),
    address: Yup.string().required("Field is required"),
    //logo: Yup.string().required('Field is required'),
    email: Yup.string().required("Field is required").email("This string must be email"),
});

const AddFeesSchema = Yup.object().shape({
    price: Yup.number().positive("A number can't start with a minus").required("Field is required"),
    code: Yup.string().required("Field is required"),
    activity_description: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .required("Field is required."),
});

function ClientDetails() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [addRow, setAddRow] = useState(false);
    const { addError } = useAPIError();
    const { id } = useParams();
    const [clientDetails, setClientDetails] = useState({
        client_id: -1,
        name: "",
        contact_name: "",
        contact_surname: "",
        email: "",
        contact_number: "",
        address: "",
    });
    const [clientFees, setClientFees] = useState([]);
    const [clientFeeTypes, setClientFeeTypes] = useState([]);
    const [feesList, setFeesList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const { toggleLoading } = useGlobalLoader();
    const [valuesRow, setValuesRow] = useState({});
    const [editId, setEditId] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [idFees, setIdFees] = useState(null);
    const [listIds, setListIds] = useState({});
    const [logo, setLogo] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openModalFees, setOpenModalFees] = useState(false);
    const [paramsFees, setParamsFees] = useState(null);
    const [previewLogo, setPreviewLogo] = useState(null);
    const { setToast } = useModal();

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                ClientFeeService.listClientFees(params).then((response) => {
                    if (response.data.client_fee_types != null) {
                        setClientFeeTypes([...response.data.client_fee_types]);
                    }
                    resolve({
                        status: response.status,
                        data: response.data.fees,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {
            client_id: id,
        },
    });

    React.useEffect(() => {
        setParams({ client_id: id });
    }, [id]);

    const [initialValuesFees, setInitialValuesFees] = useState({
        code: "",
        price: "",
        activity_description: "",
        client_id: -1,
        id: "",
    });

    const getClientDetails = async () => {
        toggleLoading();
        const resp = await ClientService.getClient(id);
        console.log(resp);
        if (resp.status === "success") {
            setClientDetails(resp.data);
            toggleLoading();
        }
    };

    const getClientFees = async () => {
        const resp = await ClientFeeService.listClientFees({ client_id: id });
        console.log(resp);
        if (resp.status === "success") {
            setClientFees(resp.data.fees);
            setClientFeeTypes(resp.data.client_fee_types);
        }
    };

    useEffect(() => {
        if (clientDetails.client_id === -1) {
            getClientDetails();
            getClientFees();
        }
    }, []);

    const handleClose = (type) => {
        if (type === "save") {
            formRefFees.current.handleSubmit();
        }
        if (type === "delete") {
            setAddRow(false);
            formRefFees.current.resetForm();
        }
    };

    const formRef = useRef();
    const formRefFees = useRef();

    const handlerClick = () => {
        if (editMode) {
            formRef.current.handleSubmit();
        } else {
            setEditMode(true);
        }
    };

    const handleEdit = (id, index) => {
        setEditId(index);
        setIdFees(id);
        setIsEdit(true);
        setAddRow(false);
        setValuesRow({});
    };

    const handleDelete = async (fees_id) => {
        const resp = await ClientFeeService.delete({ id: fees_id });
        if (resp.status === "success") {
            setToast("Fee deleted successfully", "success");
            getClientFees();
        }
    };

    const saveUpdates = (idx) => {
        formRefFees.current.handleSubmit();
    };

    const handlerRow = (e) => {
        setValuesRow({ ...valuesRow, [e.target.name]: e.target?.value });
    };

    useEffect(() => {
        console.log("update", clientFees);
        let result;
        let listIds = {};
        if (clientFees.length > 0) {
            result = clientFees.map((item, idx) => {
                listIds[idx] = item.id;
                return {
                    code: item.code,
                    "Activity/description": item.activity_description?.name,
                    price: item.price,
                    "": (
                        <span className={style.call_icon_section}>
                            <img
                                style={{ cursor: "pointer" }}
                                src={send_icon}
                                alt=""
                                onClick={() => handleEdit(item.id, idx)}
                            />
                            <img
                                style={{ cursor: "pointer" }}
                                src={icon_delete}
                                alt=""
                                onClick={() => {
                                    setParamsFees(item.id);
                                    setOpenModalFees(true);
                                    //handleDelete(item.id, false)}
                                }}
                            />
                        </span>
                    ),
                };
            });
            setListIds(listIds);
            setFeesList(result);
        } else {
            let data = [
                {
                    code: "NA",
                    "Activity/description": "NA",
                    price: "NA",
                    "": (
                        <span className={style.call_icon_section}>
                            <img style={{ cursor: "pointer" }} src={send_icon} alt="" onClick={() => {}} />
                            <img style={{ cursor: "pointer" }} src={icon_delete} alt="" onClick={() => {}} />
                        </span>
                    ),
                },
            ];
            setFeesList(data);
        }
    }, [clientFees]);

    const handleFeeTypeSelect = () => {};

    return (
        <div>
            <CommonModal open={openModal} handleClose={() => setOpenModal(false)}>
                <div className={style.wrapper_modal}>
                    <div>Are you sure?</div>
                    <Grid className={style.btn_modal}>
                        <button
                            onClick={() =>
                                dispatch(
                                    deleteClient(id, () => {
                                        setOpenModal(false);
                                        history.goBack();
                                    }),
                                )
                            }
                        >
                            yes
                        </button>
                        <button onClick={() => setOpenModal(false)}>no</button>
                    </Grid>
                </div>
            </CommonModal>

            <CommonModal open={openModalFees} handleClose={() => setOpenModalFees(false)}>
                <div className={style.wrapper_modal}>
                    <div>Are you sure?</div>
                    <Grid className={style.btn_modal}>
                        <button
                            onClick={() => {
                                setOpenModalFees(false);
                                handleDelete(paramsFees, false);
                            }}
                        >
                            yes
                        </button>
                        <button
                            onClick={() => {
                                setParamsFees(null);
                                setOpenModalFees(false);
                            }}
                        >
                            no
                        </button>
                    </Grid>
                </div>
            </CommonModal>

            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{
                        textTransform: "uppercase",
                        marginBottom: "5px",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontWeight: "bold" }}>CLIENT MANAGER</span>
            </TitleSection>
            <Box padding={"30px 37px"} paddingSmall={"23px 29px"}>
                <Grid container justifyContent="space-between">
                    <div className={style.title}>CLIENT DETAILS</div>
                    <Grid className={`${style.images_wrapper} ${style.client_manager}`}>
                        <img src={!editMode ? edit_icon : save_icon_orange} onClick={handlerClick} alt="" />
                        <img src={icon_delete} onClick={() => setOpenModal(true)} alt="" />
                    </Grid>
                </Grid>

                <Formik
                    initialValues={clientDetails}
                    validationSchema={AddUserSchema}
                    enableReinitialize={true}
                    innerRef={formRef}
                    onSubmit={async (values) => {
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key === "id" ? "client_id" : key, values[key]);
                        });
                        if (logo) {
                            formData.append("logo", logo[0]);
                        }
                        const resp = await ClientService.update(formData);
                        if (resp.status === "success") {
                            getClientDetails();
                            setToast("Client updated successfully", "success");
                            setEditMode(false);
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        setValues,
                        setTouched,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container justifyContent="space-between">
                                <Grid item md={2} alignItems="center">
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className={style.wrapper_logo}
                                    >
                                        {previewLogo ? (
                                            <img src={previewLogo} alt="" />
                                        ) : (
                                            <img className={style.hide_img} src={clientDetails.logo} alt="logo" />
                                        )}
                                    </Grid>
                                    {editMode && (
                                        <div className={style.text_replace}>
                                            <Grid container justifyContent="center" alignItems="center">
                                                <label htmlFor="fusk">Replace logo</label>
                                                <input
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={(e) => {
                                                        setLogo(e.target?.files);
                                                        setPreviewLogo(URL.createObjectURL(e.target.files[0]));
                                                    }}
                                                    id="fusk"
                                                    type="file"
                                                    name="photo"
                                                    style={{ display: "none" }}
                                                />
                                            </Grid>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item md={10} className={style.info_client}>
                                    <Grid container>
                                        <Grid item key={"name"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>CLIENT NAME</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["name"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    name={"name"}
                                                    error={errors["name"]}
                                                    value={values["name"]}
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item key={"contact_name"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>CONTACT NAME</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["contact_name"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    name={"contact_name"}
                                                    error={errors["contact_name"]}
                                                    value={values["contact_name"]}
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item key={"contact_surname"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>CONTACT SURNAME</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["contact_surname"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    name={"contact_surname"}
                                                    error={errors["contact_surname"]}
                                                    value={values["contact_surname"]}
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item key={"email"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>EMAIL ADDRESS</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["email"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    name={"email"}
                                                    error={errors["email"]}
                                                    value={values["email"]}
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item key={"contact_number"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>CONTACT NUMBER</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["contact_number"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    variant="outlined"
                                                    name={"contact_number"}
                                                    error={errors["contact_number"]}
                                                    value={values["contact_number"]}
                                                    onChange={handleChange}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item key={"address"} md={4} className={style.wrapper_section}>
                                            <div className={style.title_inner}>CLIENT ADDRESS</div>
                                            {!editMode ? (
                                                <div
                                                    style={{
                                                        width: "80%",
                                                        whiteSpace: "pre-line",
                                                    }}
                                                    className={style.text}
                                                >
                                                    {clientDetails["address"]}
                                                </div>
                                            ) : (
                                                <TextField
                                                    name={"address"}
                                                    variant="outlined"
                                                    error={errors["address"]}
                                                    value={values["address"]}
                                                    onChange={handleChange}
                                                    multiline
                                                    minRows={4}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>

                <Grid container justifyContent="center" className={style.table_section}>
                    <Grid item md={10}>
                        <CustomMaterialTable
                            tableRef={tableRef}
                            title={null}
                            data={remoteDataSource}
                            validationSchema={AddFeesSchema}
                            columns={[
                                {
                                    title: "Code",
                                    field: "code",
                                },
                                {
                                    title: "Activity/Description",
                                    field: "activity_description",
                                    editComponent: (params) => <EditSelect params={params} options={clientFeeTypes} />,
                                    render: (rowData) => rowData.activity_description?.name,
                                },
                                {
                                    title: "Price",
                                    field: "price",
                                    type: "currency",
                                    currencySetting: MaterialTableCurrencySettings,
                                    align: "left",
                                },
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                search: false,
                                toolbarButtonAlignment: "left",
                            }}
                            icons={{
                                Add: (props, ref) => (
                                    <BluePillButton ref={ref} variant="contained" style={{ overflow: "hidden" }}>
                                        + Fee
                                    </BluePillButton>
                                ),
                            }}
                            editable={{
                                onRowUpdate: (newRow) =>
                                    ClientFeeService.update({
                                        client_id: id,
                                        ...newRow,
                                        activity_description: newRow.activity_description?.id,
                                    }),
                                onRowDelete: (oldRow) =>
                                    ClientFeeService.delete({
                                        id: oldRow.id,
                                    }),
                                onRowAdd: (newRow) =>
                                    ClientFeeService.create({
                                        client_id: id,
                                        ...newRow,
                                        activity_description: newRow.activity_description?.id,
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default ClientDetails;
