import { Box, Tab, Tabs } from "@material-ui/core";
import { colors } from "constants/styles";

const TabsComponent = ({ currentItem, handler, configList }) => {
  return (
    <Box
      style={{
        borderRadius: "16px 16px 0 0",
        borderTop: `1px solid ${colors.blue}`,
        borderLeft: `1px solid ${colors.blue}`,
        borderRight: `1px solid ${colors.blue}`,
        overflow: "hidden",
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {configList.map((configItem) => (
          <Tab
            onClick={() => handler(configItem)}
            key={configItem}
            label={configItem}
            style={{
              fontWeight: "bold",
              fontSize: "1vw",
              fontFamily: "Avenir Next Bold",
              backgroundColor:
                currentItem === configItem ? `${colors.blue}` : "",
              color: currentItem === configItem ? `${colors.white}` : "",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsComponent;
