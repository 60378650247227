import { useEffect, useState } from "react";
import { AgentService } from "../../api/services";

const useCampaign = () => {
  // const [campaignClients, setCampaignClients] = useState(null);
  const [clients, setClients] = useState(null);

  // useEffect(() => {
  //   AgentService.getAvailableClientsFromActiveCampaigns().then((response) => {
  //     setCampaignClients(response.data.clients);
  //   });
  // });

  const getClientById = (name, clients) => {
    let client = clients.find((item) => item.name === name);
    return { client_id: client.id };
  };

  const getClients = async () => {
    const resp = await AgentService.getAvailableClientsFromActiveCampaigns();
    if (resp.status === "success") {
      setClients(resp.data.clients);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return {
    clients,
    getClientById
  };
};

export { useCampaign };
