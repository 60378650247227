import format from "date-fns/format";
import { CASE } from "../constants/apis";

const _url = process.env.REACT_APP_API_BASE_URL;

const _token = (token) => {
	if (typeof token !== "undefined") {
		sessionStorage.setItem("token", token);

		return;
	}

	return sessionStorage.getItem("token");
};
const formatDebtorCaseFee = (params) => {
	return {
		id: params.id,
		account_no: params.account_no,
		agent_id: params.agent_id,
		date: params.date != null
			? format(new Date(params.date), "yyyy-MM-dd")
			: null,
		code: params.code,
		duration: params.duration,
		amount: params.amount,
		description_id: params.description?.id,
		type_id: params.type?.id,
		capture_type: "manual", // manual input, not auto
	};
};
const formatDebtorCaseNote = (params) => {
	return {
		account_no: params.account_no,
		agent_id: params.agent_id,
		category_id: params.category.id,
		status_id: params.status.id,
		type_id: params.type.id,
		amount: params.amount,
		note: params.note,
		note_date:
			params.note_date != null
				? format(new Date(params.note_date), "yyyy-MM-dd")
				: null,
		attachment: params.attachment,
		id: params.id,
		tracing: params.tracing ?? 0
	};
};
const CaseService = {
	debtorCaseSummary(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.SUMMARY}?` + new URLSearchParams(params), {
				method: "GET",
				// body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					// return a table format
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseSummaryOptions() {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.OPTIONS}`, {
				method: "GET",
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					// return a table format
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	updateDebtorCase(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.EDIT_DEBTOR}`, {
				method: "POST",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					// return a table format
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseFees(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.FEES}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					// return a table format
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	addDebtorCaseFee(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		const body = formatDebtorCaseFee(params);

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.FEES}/create`, {
				method: "post",
				body: JSON.stringify(body),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(true);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	updateDebtorCaseFee(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		const body = formatDebtorCaseFee(params);

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.FEES}/update`, {
				method: "post",
				body: JSON.stringify(body),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(true);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	deleteDebtorCaseFee(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.FEES}/delete`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(true);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseCalls(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.CALLS}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseEmails(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.EMAILS}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseMessages(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.MESSAGES}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCasePTP(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.PTPS}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseNotes(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};
		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.NOTES}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	addDebtorCaseNote(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		const body = formatDebtorCaseNote(params);

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.NOTES}/create`, {
				method: "post",
				body: JSON.stringify(body),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(true);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	updateDebtorCaseNote(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};

		const body = formatDebtorCaseNote(params);

		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.NOTES}/update`, {
				method: "post",
				body: JSON.stringify(body),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(true);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
	debtorCaseAdditional(params) {
		const config = {
			headers: {
				Authorization: `Bearer ${_token()}`,
				"content-type": "application/json",
			},
		};
		return new Promise((resolve, reject) => {
			fetch(`${_url}${CASE.ADDITIONAL}`, {
				method: "post",
				body: JSON.stringify(params),
				headers: config.headers,
			})
				.then((res) => res.json())
				.then((res) => {
					if (res.status == "success") {
						resolve(res);
					} else {
						reject(res.message);
					}
				})
				.catch((message) => reject(message));
		});
	},
};

export default CaseService;
