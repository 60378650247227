import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AgentService } from "../../api/services";
import { withStyles } from "@material-ui/core/styles";
import { Section, TitleSection } from "../../common/Layouts";
import SwitchComponent from "../../components/SwitchComponent";
import TimeFilter from "../../components/TimeFilter";
import NextCallTable from "../Debtors/tables/NextCallTable";
import {
  Grid,
  Paper,
  Tooltip,
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import ButtonComponent from "components/ButtonComponent";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { MTableToolbar } from "material-table";
import { useHistory, useLocation } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../constants/pathNames";
import Graph from "components/Graph";
import { CAMPAIGN_FIELDS } from "../../constants/tableColumns";
import { useCampaign } from "providers/hooks/useCampaign";
import { useModal } from "providers/hooks/useModal";
import PtpOverridesList from "./PtpOverridesList";

const SectionTable = styled.div`
  margin: 20px 0px;
  .MuiPaper-rounded {
    border-radius: 15px !important;
    box-sizing: border-box;
  }
`;

const GraphPaper = styled(Paper)({
  padding: "40px",
  borderRadius: "25px !important",
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function extractNextDebtor(response) {
  return response.reduce((acc, item) => {
    if (item.next === true) {
      acc.push(item);
    }
    return acc;
  }, []);
}

const getGraphData = async (localParams) => {
  if (localParams) {
    const response = await AgentService.getGraphSummary(localParams);
    return response?.data;
  }
};

const getClientById = (name, clients) => {
  let client = clients?.find((item) => item.name === name);
  return { client_id: client?.id };
};

function AgentCampaignDashboard() {
  const { setModal } = useModal();
  const { clients } = useCampaign();
  const [graphData, setGraphData] = useState({});
  const [localParams, setLocalParams] = useState({});
  const { pathname, state: locationState } = useLocation();
  const [configLoaded, setConfigLoaded] = useState(false);
  const [configList, setConfigList] = useState([]);
  const [currentTable, setCurrentTable] = useState(null);
  const history = useHistory();
  const [nextDebtor, setNextDebtor] = useState([]);
  let isBack = false;

  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) =>
      new Promise((resolve, reject) => {
        AgentService.getAgentsCampaignSummary(params).then((response) => {
          if (response.data?.debtors?.length > 0) {
            setNextDebtor(extractNextDebtor(response.data.debtors));
          }
          resolve({
            status: response.status,
            data: response.data?.debtors?.filter(
              (debtors) => debtors.next !== true
            ),
            count: response.data?.count - 1,
            pagination: response.pagination,
          });
        });
      }),
    initParams: {},
  });


  useEffect(() => {
    (async () => {
      if (configLoaded && localParams) {
        setGraphData(await getGraphData(localParams));
      }
    })();
  }, [localParams, configLoaded]);

  const handlerSelectTable = (data) => {
    setCurrentTable(data);
  };

  useEffect(() => {
    if (clients !== null) {
      setConfigList((configList) => [
        ...configList,
        ...clients.map((item) => item.name),
      ]);
      setConfigLoaded(true);
    }
  }, [clients]);

  useEffect(() => {
    if (configList.length > 0) {
      handlerSelectTable(configList[0]);
      setParams(getClientById(currentTable, clients));
    }
  }, [configLoaded]);

  useEffect(() => {
    setParams(getClientById(currentTable, clients));
    setLocalParams(getClientById(currentTable, clients));
  }, [currentTable]);

  const handleRowClick = (event, row) => {
    const prms = getClientById(currentTable, clients);
    const location = {
      pathname: `${DASHBOARD}${DEBTOR}/${row.debtor_name}`,
      state: {
        debtor_name: row.debtor_name,
        account_number: row.account_number,
        client_id: prms
      },
    };
    history.push(location);
  };

  const showTable = (clients) => {

    if(clients !== null && clients.length !== 0){
      return ( 
      <CustomMaterialTable
      title={null}
      tableRef={tableRef}
      columns={CAMPAIGN_FIELDS}
      data={remoteDataSource}
      onRowClick={handleRowClick}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        search: true,
      }}
      components={{
        Toolbar: (props) => (
          <div>
            <MTableToolbar {...props} />
            <NextCallTable
              tableRef={tableRef}
              data={nextDebtor}
              client={getClientById(currentTable, clients)}
            />
          </div>
        ),
      }}
    />);
    }
    
  };

  const openPtpOverride = () => {
    setModal({
      Component: <PtpOverridesList />,
      size: "sml",
    });
  };

  return (
    <div>
      <TitleSection>
        {isBack && <h4>Back</h4>}
        <p
          style={{
            marginRight: 10,
            marginBottom: 0,
            marginTop: 5,
          }}
        >
          Agent Dashboard
        </p>
        <div>
          <ButtonComponent
            title={"Ptp Overrides Status"}
            handler={openPtpOverride}
            // width={240}
            style={{
              "> span": {
                marginLeft: "60px",
              },
            }}
          />
        </div>
      </TitleSection>
      <GraphPaper>
        <Section>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Typography variant="h5" style={{ fontWeight: 800 }} gutterBottom>
                LAST 10 DAYS SUMMARY
              </Typography>
            </Grid>
          </Grid>
        </Section>
        <Box component="Grid" sx={{ display: "flex" }}>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={4}>
            <Graph data_set={graphData?.calls} color="#fe2900" type="calls" />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={4}>
            <Graph data_set={graphData?.ptps} color="#ffe302" type="ptps" />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={4}>
            <Graph data_set={graphData?.money} color="#00fb84" type="money" />
          </Grid>
        </Box>
      </GraphPaper>
      <SectionTable>
        <Section>
          <SwitchComponent
            currentItem={currentTable}
            configList={configList}
            handler={handlerSelectTable}
          />
        </Section>

        {showTable(clients)}
      </SectionTable>
    </div>
  );
}

export default AgentCampaignDashboard;
