//Example: 15 JAN 2021
const dateFormat = dateInput => {
    const formatDate = new Date(dateInput).toLocaleDateString('en-GB', {
        day : 'numeric',
        month : 'short',
        year : 'numeric'
    });

    return formatDate;
};

const timeFormat = timeInput => {
    const dateFormat = new Date(timeInput);
    let returnTime = dateFormat.getHours();
    if (returnTime < 10) {
        returnTime = `0${returnTime}`;
    }
    let min = dateFormat.getMinutes();
    if (min < 10) {
        min = '0' + min;
    }
    returnTime += ":" + min;

    return returnTime;
};

export { dateFormat, timeFormat};