const SET_MODAL = "SET_MODAL";
const UNSET_MODAL = "UNSET_MODAL";
const SET_TOAST = "SET_TOAST";
const UNSET_TOAST = "UNSET_TOAST";
const TOGGLE_LOADING = "TOGGLE_LOADING";
const SET_PERSISTENT = "SET_PERSISTENT";
const UNSET_PERSISTENT = "UNSET_PERSISTENT";

export {
	SET_MODAL,
	UNSET_MODAL,
	SET_TOAST,
	UNSET_TOAST,
	SET_PERSISTENT,
	UNSET_PERSISTENT,
	TOGGLE_LOADING
};
