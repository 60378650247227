import { Menu, MenuItem, Toolbar } from "@material-ui/core";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import BluePillButton from "components/buttons/BluePillButton";
import PtpSubmit from "components/modal/content/PtpSubmit";
import { MaterialTableCurrencySettings } from "constants/locale";
import { useModal } from "providers/hooks/useModal";
import React from "react";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";

function Ptp({ account_status, account_no, account_settlement, ptp_minimum }) {
  const { setModal, unsetModal } = useModal();
  const [menuAnchor, setMenuAnchor] = React.useState();
  const [frequencies, setFrequencies] = React.useState([]);
  const [periods, setPeriods] = React.useState([]);
  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) =>
      new Promise((resolve, reject) => {
        DebtorCasePtpService.listPtps(params).then((response) => {
          if (response.data.frequencies != null) {
            setFrequencies([...response.data.frequencies]);
          }
          if (response.data.periods != null) {
            setPeriods([...response.data.periods]);
          }
          // resolve promise with table data
          resolve({
            status: response.status,
            data: response.data.ptps,
            count: response.data.count,
            pagination: response.pagination,
          });
        });
      }),
    initParams: {
      account_no,
    },
  });

  const handleModalEvent = ({ type, payload = undefined }) => {
    console.log("handleModalEvent", type);
    switch (type) {
      case "close":
        unsetModal();
        break;
      case "save":
        console.log(tableRef);
        if (tableRef.current != null) {
          tableRef.current.onQueryChange();
        }
        setParams({ account_no });
        unsetModal();
        break;
      default:
        unsetModal();
    }
  };
  const handleNewPtp = (e) => {
    setMenuAnchor(e.target);
  };
  const handlePtpTypeSelect = (e) => {
    setMenuAnchor(false);
    switch (e.target.id) {
      case "once-off":
        setModal({
          Component: (
            <PtpSubmit.OnceOff
              account_no={account_no}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              dispatch={handleModalEvent}
            />
          ),
          size: "sml",
        });
        break;
      case "instalment-plan":
        setModal({
          Component: (
            <PtpSubmit.InstalmentPlan
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "once-off-instalment":
        setModal({
          Component: (
            <PtpSubmit.OnceOffInstalment
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "special-instalments":
        setModal({
          Component: (
            <PtpSubmit.SpecialInstalment
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "full-final-settlements":
        setModal({
          Component: (
            <PtpSubmit.FullFinalSettlement
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "short-settlement":
        setModal({
          Component: (
            <PtpSubmit.ShortSettlement
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "attorney-client-settlement":
        setModal({
          Component: (
            <PtpSubmit.AttorneyClientSettlement
              account_no={account_no}
              dispatch={handleModalEvent}
              account_settlement={account_settlement}
              ptp_minimum={ptp_minimum}
              frequencies={frequencies}
              periods={periods}
            />
          ),
          size: "sml",
        });
        break;
      case "other":
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (account_no != null) {
      setParams({ account_no });
    }
  }, [account_no]);

  return (
    <div>
      <Toolbar>
      { account_status === 1 ? <BluePillButton onClick={handleNewPtp}>+ New PTP</BluePillButton> : null }
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          keepMounted
          onClose={() => setMenuAnchor(undefined)}
        >
          <MenuItem id="once-off" onClick={handlePtpTypeSelect}>
            Once Off Payment
          </MenuItem>
          <MenuItem id="instalment-plan" onClick={handlePtpTypeSelect}>
            Instalment Plan
          </MenuItem>
          <MenuItem id="once-off-instalment" onClick={handlePtpTypeSelect}>
            Once Off + Instalment
          </MenuItem>
          <MenuItem id="special-instalments" onClick={handlePtpTypeSelect}>
            Special Instalments
          </MenuItem>
          <MenuItem id="full-final-settlements" onClick={handlePtpTypeSelect}>
            Full and Final Settlements
          </MenuItem>
          <MenuItem id="short-settlement" onClick={handlePtpTypeSelect}>
            Short Settlement
          </MenuItem>
          <MenuItem
            id="attorney-client-settlement"
            onClick={handlePtpTypeSelect}
          >
            Attorney-Client Settlement
          </MenuItem>
          <MenuItem id="other">Other</MenuItem>
        </Menu>
      </Toolbar>
      <CustomMaterialTable
        title={null}
        tableRef={tableRef}
        columns={[
          { title: "PTP Due Date", field: "due_date" },
          {
            title: "Pay Date",
            field: "pay_date",
            type: "date",
            dateSetting: {
              format: "yyyy-MM-dd",
            },
          },
          { title: "Status", field: "status" },
          {
            title: "PTP Amount",
            field: "amount",
            type: "currency",
            currencySetting: MaterialTableCurrencySettings,
          },
          {
            title: "Pay Amount",
            field: "pay_amount",
            type: "currency",
            currencySetting: MaterialTableCurrencySettings,
          },
        ]}
        data={remoteDataSource}
        options={{
          toolbar: false,
          search: false,
        }}
      />
    </div>
  );
}

export default Ptp;
