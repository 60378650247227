import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';

const GlobalLoader = ({ loading }) => {
    console.log('global loader');
    return loading ? (
        <div className={'flex ai-c jc-c'} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 9999, }}>
            <CircularProgress style={{color: '#fff'}} />
        </div>
    ) : null
}

const mapStateToProps = (state) => {
    return {
        loading: state.nav.loading,
    }
}

export default connect(mapStateToProps, null)(GlobalLoader);
