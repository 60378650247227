import clsx from "clsx";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";

export const PersistentContainer = () => {
	const { Component, position } = useSelector(
		(state) => state.nav.persistent
	);

	if (!Component) {
		return null;
	}
	return (
		<div
			className={clsx(styles.persistentContainer, {
				[styles.verticalTop]: position.vertical === "top",
				[styles.verticalBottom]: position.vertical === "bottom",
				[styles.horizontalLeft]: position.horizontal === "left",
				[styles.horizontalRight]: position.horizontal === "right",
			})}
		>
			{Component}
		</div>
	);
};
