import { createTheme } from "@material-ui/core";
import AvenirNext from "../assets/fonts/AvenirNextLTPro-Regular.otf";

const avenir_next_bold = {
	fontFamily: "Avenir Next Bold",
	fontWeight: "bold",
	src: `url(./assets/fonts/AvenirNextLTPro-Bold.otf) format('opentype')`,
};
const avenir_next_demi_bold = {
	fontFamily: "Avenir Next Demi Bold",
	src: `url(./assets/fonts/Avenir\ Next-SemiBold.otf) format('opentype')`,
};
const avenir_next_it = {
	fontFamily: "Avenir Next It",
	src: `url(./assets/fonts/AvenirNextLTPro-It.otf) format('opentype')`,
};
const avenir_next_medium = {
	fontFamily: "Avenir Next Medium",
	src: `url(./assets/fonts/Avenir\ Next-Medium.otf) format('opentype')`,
};
const avenir_next = {
	fontFamily: "Avenir Next",
	src: `url(${AvenirNext}) format('opentype')`,
};

const CollectIQBasic = createTheme({
	typography: {
		fontFamily: "Avenir Next, Arial, Helvetica, sans-serif",
	},
	overrides: {
		MuiCssBaseline: {
			"@global": {
				"@font-face": [avenir_next],
			},
		},
		MuiPopover: {
			paper: {
				border: "1px solid #01579b !important",
				borderRadius: "15px !important",
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: "18px",
			},
			input: {
				padding: "9px 12px",
			},
		},
	},
	palette: {
		primary: {
			main: "#002b64",
		},
		secondary: {
			main: "#ec7346",
		},
	},
});

export default CollectIQBasic;
