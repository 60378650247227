import React from "react";
import style from "../styles.module.css";

const TextInput = ({
  name,
  value,
  onChange,
  error,
  label = null,
  placeholder = null,
  className = '',
  onKeyDown = (e) => {},
  touched
}) => {
  return (
    <div className={`${style.wrapper_input} ${className ? className : ''}`}>
      {label ? <div className={style.input_title}>{label}</div> : null}
      <input
        name={name}
        type={"text"}
        value={value}
        onChange={onChange}
        {...(placeholder && { placeholder: placeholder })}
        onKeyDown={onKeyDown}
      />
      {error && touched && <p className={'ta-c w-100'}>{error}</p>}
    </div>
  );
};

export default TextInput;
