//agents actions
export const GET_LIST_AGENTS = "GET_LIST_AGENTS"; 
export const GET_AGENTS_SUMMARY = "GET_AGENTS_SUMMARY";
export const GET_AGENTS_LOGOUT_BREAKS = "GET_AGENTS_LOGOUT_BREAKS";
export const IS_LOADING = "IS_LOADING"; 

//clients actions
export const GET_CLIENTS_REGISTER = "GET_CLIENTS_REGISTER"; 
export const CREATE_CLIENT = "CREATE_CLIENT";
export const GET_CLIENT_FEES = "GET_CLIENT_FEES"; 
export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const CREATE_FEES = "CREATE_FEES"; 
export const UPDATE_FEES = "UPDATE_FEES"; 
export const DELETE_FEES = "DELETE_FEES";  
export const UPDATE_FEED = "UPDATE_FEED"; 
export const SET_NEW_FEES = "SET_NEW_FEES"; 
export const REFRESH_FEES = "REFRESH_FEES"; 
export const GET_CLIENT_MANAGER = "GET_CLIENT_MANAGER"; 

//debtors actions
export const GET_DEBTOR_REGISTER = "GET_DEBTOR_REGISTER";


//call actions
export const GET_CALL = "GET_CALL"; 

