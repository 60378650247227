import axios from "axios";
import * as api from "..";
import { COMMS } from "../config/endpoints";

const CommsService = {
    async getAgentEmails({ filter, pagination, sort }) {
        const resp = await api.post(COMMS.GET_AGENT_EMAILS, ...arguments);
        return resp;
    },
    async agentSendEmailToDebtor(formData) {
        const resp = await api.asFormData(COMMS.AGENT_SEND_EMAIL_TO_DEBTOR, formData);
        return resp;
    },
    async debtorSendEmailToAgent(formData) {
        const resp = await api.asFormData(COMMS.DEBTOR_SEND_EMAIL_TO_AGENT, formData);
        return resp;
    },
    async agentSendReplyEmail(formData) {
        const resp = await api.asFormData(COMMS.AGENT_SEND_REPLY_EMAIL, formData);
        return resp;
    },
    async managerEmailDelete({ email_id }) {
        const resp = await api.post(COMMS.MANAGER_EMAIL_DELETE, ...arguments);
        return resp;
    },
    async managerEmailShare({ subject, recipient_email, body, file_name, data }) {
        const resp = await api.post(COMMS.MANAGER_EMAIL_SHARE, ...arguments);
        return resp;
    },
    async getDebtorCaseEmails({ account_id }) {
        const resp = await api.post(COMMS.GET_DEBTOR_CASE_EMAILS, ...arguments);
        return resp;
    },
    async getPossibleRecipients({ agent_id }) {
        const resp = await api.post(COMMS.GET_POSSIBLE_RECIPIENTS, ...arguments);
        return resp;
    },
    async getDebtorRecipients() {
        const resp = await api.post(COMMS.GET_DEBTOR_RECIPIENTS);
        return resp;
    },
    async flagEmail({ email_id }) {
        const resp = await api.post(COMMS.FLAG_EMAIL, ...arguments);
        return resp;
    },
    async setReadEmail({ email_id }) {
        const resp = await api.post(COMMS.SET_READ_EMAIL, ...arguments);
        return resp;
    },
    async getEmailThread({ thread }) {
        const resp = await api.post(COMMS.GET_EMAIL_THREAD, ...arguments);
        return resp;
    },
    async getMessageTemplates({ communication_type_id }) {
        const resp = await api.post(COMMS.GET_MESSAGE_TEMPLATES, ...arguments);
        return resp;
    },
    async agentSendBulkEmailsToDebtors(formData) {
        const resp = await api.asFormData(COMMS.AGENT_SEND_BULK_EMAILS_TO_DEBTORS, formData);
        return resp;
    },
    async emailStatement() {
        const resp = await api.post(COMMS.EMAIL_STATEMENT);
        return resp;
    },
    async downloadStatement() {
        const resp = await api.get(COMMS.DOWNLOAD_STATEMENT, { responseType: "blob" });
        return resp;
    },
};

export default CommsService;
