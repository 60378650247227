import React from "react";
const convertQueryToApi = (query, tableRef) => {
	let sort = undefined;
	if (query.orderBy != null) {
		sort = {
			column_name: String(query.orderBy?.field),
			order: String(query.orderDirection).toUpperCase(),
		};
	} else {
		sort = {
			column_name: 'id',
			order: 'DESC',
		}
	}

	let filter = [];
	if (query.filters != null) {
		query.filters.forEach((_filter) => {
			filter.push({
				column_name: String(_filter.column.field),
				search_term: _filter.value,
			});
		});
	}

	if (query.search) {
		const columns = tableRef.current?.props?.columns;
		if (columns) {
			columns.forEach((column) => {
				if (!filter.filter(item => item.column_name === column.field).length && column.field !== 'created_at') {
					filter.push({
						column_name: column.field,
						search_term: query.search,
					})
				}
			})
		}
	}

	let pagination = {
		page: 1,
		rows_per_page: 10,
	};
	if (query.page != null && query.pageSize != null) {
		pagination = {
			page: query.page + 1,
			rows_per_page: query.pageSize,
		};
	}
	return {
		sort,
		filter,
		pagination,
	};
};

const useMaterialTable = ({
	endpoint, // promise which returns standard laravel response
	initParams, // initial data for endpoint params
	actions, // table actions config (work in progress)

}) => {
	const tableRef = React.createRef();
	const [params, setParams] = React.useState(initParams);

	const remoteDataSource = React.useCallback(
		(query /* material table query object */) => {
			const sort_filter_page = convertQueryToApi(query, tableRef);

			if (tableRef?.current != null && params != null) {
				console.log('params', params);
				return new Promise((resolve, reject) => {
					endpoint({
						...sort_filter_page,
						...params,
					})
						.then((response) => {
							if (response.status == "success") {
								console.log('response', response);
								// the data here must be configured in the endpoint promise (eg. response.data = res.data.data.fees )
								resolve({
									data: response.data ?? [],
									page: response.pagination?.page - 1 ?? 0,
									totalCount: response.count ?? 0,
								});
							} else {
								reject(response.message);
							}
						})
						.catch((message) => {
							reject(message);
						});
				});
			}
			return Promise.resolve({
				data: [],
				page: 0,
				totalCount: 0,
			});
		},
		[tableRef]
	);

	React.useEffect(() => {
		if (
			tableRef.current != null &&
			params != null &&
			!tableRef.current.isLoading
		) {
			tableRef.current.onQueryChange();
		}
	}, [params]);

	return {
		remoteDataSource,
		setParams,
		tableRef,
	};
};

export { useMaterialTable };
