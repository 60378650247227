import React, { useState } from "react";
import styles from "./styles.module.css";
import { IconButton } from "@material-ui/core";
import close_icon from "../../../../assets/icons/Group 414.png";
import calendar_icon from "../../../../assets/icons/FontAwsome (calendar-alt)-1.png";
import { useModal } from "providers/hooks/useModal";

const EventNotification = ({ data }) => {
  const { unsetPersistent } = useModal();
  const closeNotification = () => {
    unsetPersistent();
  };
  return (
    <>
      <div className={styles.reminderContainer}>
        <>
          <IconButton
            className={styles.reminderClose}
            onClick={closeNotification}
          >
            <img src={close_icon} alt="close" width={10} />
          </IconButton>
        </>

        <div className={styles.reminderInfo}>
          <p className={styles.reminderTitle}>CALENDAR REMINDER</p>
          <span> {data?.event}</span>
        </div>
        <div className={styles.reminderActionsContainer}>
          <img src={calendar_icon} alt="calendar icon" width={25} />
        </div>
      </div>
    </>
  );
};

export default EventNotification;
