import React from "react";
import { TimePicker as TimePickerBase } from "@material-ui/pickers";
import styles from "./styles.module.css";

const TimePicker = ({ onChange, name, value, label, ...props }) => {
  return (
    <div className={styles.timePickerContainer}>
      <div className={styles.timePickerTitle}>{label}</div>
      <div className={styles.timePicker}>
        <TimePickerBase
          onChange={onChange}
          name={name}
          value={value}
          style={{width: "100%"}}
          sx={{ width: "100%" }}
          InputProps={{
              disableUnderline: true,
          }}
          {...props}
        />
      </div>
    </div>
  );
};

export default TimePicker;
