import axios from 'axios';

let token = null;

export const setToken = (t) => {
    token = t;
};

const axiosDefault = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosDefault.interceptors.request.use(
    (request) => {
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosDefault.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log('interceptor failure', error);
        return error.response;
    },
);

const get = async (url) => {
    const resp = await axiosDefault.get(url);
    if (resp && resp.data) {
        return resp.data;
    }
    return false;
};

const post = async (url, body) => {
    const resp = await axiosDefault.post(url, body);
    if (resp && resp.data) {
        return resp.data;
    }
    return false;
};

const asFormData = async (url, formData) => {
    const resp = axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log('failute', error);
            return error;
        });
    return resp;
};

export { get, post, asFormData };
