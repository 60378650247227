import { createTheme } from "@material-ui/core";
import { colors } from "../../../constants/styles";

const tableTheme = createTheme({
	overrides: {
		MuiTable: {
			root: {
				minWidth: 650,
				borderLeft: `16px solid ${colors.white}`,
				borderRight: `16px solid ${colors.white}`,
			},
		},
		MuiTableCell: {
			root: {
				height: "38px",
				padding: 0,
				fontFamily: "Avenir Next",
			},
			body: {
				color: `${colors.blue} !important`,
				// margin: '0px 25px',

				fontSize: "12px !important",
				letterSpacing: "0px",
				fontFamily: "Avenir Next Medium !important",
				"@media (min-width:1920px)": {
					fontSize: "15px",
					//padding: '10px 2px',
				},
			},
			head: {
				color: colors.blue,
				fontWeight: "bold",
				fontSize: "12px",
				textTransform: "uppercase",
				letterSpacing: "0px",
				fontFamily: "Avenir Next Bold",
				minWidth: 100,
				overflow: "auto",
				whiteSpace: "nowrap",
				wordWrap: "normal",
				textOverflow: "ellipsis",
				"@media (min-width:1920px)": {
					fontSize: "15px",
				},
			},
		},
		MuiPaper: {
			elevation1: {
				padding: "0px 20px",
			},
		},
		MuiTablePagination: {
			root: {
				float: "none !important",
				border: "none !important",
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: "18px",
			},
			input: {
				padding: "9px 12px",
			},
		},
		MuiPopover: {
			paper: {
				border: "1px solid #01579b !important",
				borderRadius: "15px !important",
			},
		},
	},
	palette: {
		primary: {
			main: "#002b64",
		},
		secondary: {
			main: "#ec7346",
		},
	},
});

export default tableTheme;