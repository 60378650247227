import React, { useState, useEffect } from "react";
import TextArea from "components/inputs/TextArea";
import TextInput from "components/inputs/TextInput";
import styles from "./styles.module.css";
import Select from "../../../inputs/Select";
import { Formik, Form } from "formik";
import { ClientService, EventService } from "api/services";
import { useModal } from "providers/hooks/useModal";
import { useAuth } from "providers/hooks/useAuth";
import * as Yup from "yup";
import ButtonLoader from "components/buttons/ButtonLoader";
import TimePicker from "components/inputs/TimePicker";
import moment from "moment";
import DatePicker from "components/inputs/DatePicker";

const timeOptions = [
    {
        name: "15 Min.",
        value: 15,
    },
    {
        name: "30 Min.",
        value: 30,
    },
    {
        name: "45 Min.",
        value: 45,
    },
    {
        name: "1 Hour",
        value: 60,
    },
    {
        name: "1 Hour 15 Min.",
        value: 75,
    },
    {
        name: "1 Hour 30 Min.",
        value: 90,
    },
    {
        name: "1 Hour 45 Min.",
        value: 105,
    },
    {
        name: "2 Hours",
        value: 120,
    },
    {
        name: "2 Hours 15 Min.",
        value: 135,
    },
    {
        name: "2 Hours 30 Min.",
        value: 150,
    },
    {
        name: "2 Hours 45 Min.",
        value: 165,
    },
    {
        name: "3 Hours",
        value: 180,
    },
];

const calendarValidationSchema = {
    title: Yup.string().required("Please enter a title"),
    duration: Yup.string()
        .matches(/^((\d{1,2}h\s?)?(\d{1,2}m\s?)?(\d{1,2}s\s?)?)|\d{1,2}$/g, "Invalid duration (eg. 1h30m)")
        .required("Please enter a duration"),
    date: Yup.string().required("Please select a date").typeError("Please select a valid date"),
    time: Yup.string().required("Please enter a time").typeError("Please select a valid time"),
    clients: Yup.number().required("Please select a client").typeError("Please select a client"),
    debtors: Yup.number().required("Please select a debtor").typeError("Please select a debtor"),
    note: Yup.string().required("Please enter a note"),
};

const notesValidationSchema = {
    ...Object.fromEntries(
        Object.entries(calendarValidationSchema).filter(([key]) => ["time", "date", "duration"].includes(key)),
    ),
};

const AddEvent = ({ onSuccess, event = null, source, notes_data = null }) => {
    const [clients, setClients] = useState([]);
    const [notesEventStyle, setNotesEventStyle] = useState({});
    const [selectedClient, setSelectedClient] = useState(event ? event.client_participants[0]?.id : 0);
    const [debtors, setDebtors] = useState([{ name: "Please select a client", value: 0 }]);
    const [loading, setLoading] = useState(false);
    const { agent_id } = useAuth();
    const { setToast, unsetModal } = useModal();
    const getClients = async () => {
        const resp = await ClientService.getClientManager({});
        if (resp.status === "success") {
            const options = resp.data.clients?.map((item) => {
                return { name: item.name, value: item.id };
            });
            options.unshift({ name: "Please select a client", id: 0 });
            setSelectedClient(options[0]?.value);
            setClients(options);
        } else {
            setToast("Error getting clients", "error");
        }
    };

    useEffect(() => {
        getClients();
        if (source === "notes") {
            setNotesEventStyle({ height: "280px" });
        }
    }, []);

    const getDebtors = async () => {
        const resp = await ClientService.getClientDebtorRegister({
            client_id: selectedClient,
        });
        if (resp.status === "success") {
            const options = resp.data.debtors?.map((item) => {
                return { name: item.debtor_name, value: item.id };
            });
            options.unshift({ name: "Please select a debtor", id: 0 });
            setDebtors(options);
        } else {
            setToast("Error getting debtors", "error");
        }
    };

    useEffect(() => {
        if (selectedClient) {
            getDebtors();
        }
    }, [selectedClient]);

    const addEventTitle = () => {
        if (source === "calendar") {
            return (event ? "View" : "Add") + " Event";
        }
        return "Add Note To Calendar";
    };

    return (
        <div className={styles.addEventContainer} style={{ ...notesEventStyle }}>
            <h2 className={styles.addEventHeader}>{addEventTitle()}</h2>
            <div>
                <Formik
                    initialValues={{
                        title: event ? event.title : "",
                        clients: event ? event.client_participants[0]?.id : null,
                        debtors: event ? event.debtor_participants[0]?.id : null,
                        date: event ? event.start : moment().toISOString(),
                        time: event ? event.start : null,
                        duration: event ? Number(moment(event.end).diff(moment(event.start), "minutes")) : 15,
                        note: event ? event.note : "",
                    }}
                    enableReinitialize={Boolean(event)}
                    validationSchema={Yup.object().shape(
                        source === "calendar" ? calendarValidationSchema : notesValidationSchema,
                    )}
                    onSubmit={async (values) => {
                        setLoading(true);
                        let end = moment(values.time);
                        const start_time = moment(values.date.toString())
                            .set({
                                hours: end.hours(),
                                minutes: end.minutes(),
                                seconds: end.seconds(),
                            })
                            .format("yyyy-MM-DD HH:mm:ss");
                        const end_time = moment(values.date.toString())
                            .set({
                                hours: end.hours(),
                                minutes: end.minutes() + values.duration,
                                seconds: end.seconds(),
                            })
                            .format("yyyy-MM-DD HH:mm:ss");
                        const c = notes_data === null ? [values.clients] : [notes_data.client_id];
                        const d = notes_data === null ? [values.debtors] : [notes_data.debtor_id];
                        const note_data = notes_data === null ? values.note : notes_data.note;
                        const note_title = notes_data === null ? values.title : notes_data.title;

                        let resp = null;
                        if (!event) {
                            resp = await EventService.create({
                                start_time,
                                end_time,
                                clients: c,
                                debtors: d,
                                note: note_data,
                                title: note_title,
                                users: [agent_id],
                            });
                        } else {
                            resp = await EventService.update({
                                event_id: event.id,
                                start_time,
                                end_time,
                                clients: c,
                                debtors: d,
                                note: values.note,
                                title: values.title,
                                users: [agent_id],
                            });
                        }
                        if (resp.status === "success") {
                            unsetModal();
                            setToast("Event added successfully", "success");
                            onSuccess();
                        } else {
                            setToast("Error adding event", "error");
                        }
                        setLoading(false);
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        setValues,
                        setTouched,
                        isSubmitting,
                        touched,
                        values,
                    }) => (
                        <Form className={styles.form}>
                            {source === "calendar" ? (
                                <div className={styles.inputs}>
                                    <TextInput
                                        label={"Title"}
                                        name={"title"}
                                        onChange={handleChange}
                                        value={values.title}
                                        error={errors.title}
                                        touched={touched.title}
                                    />
                                    <Select
                                        options={clients}
                                        error={errors.clients}
                                        label={"Client"}
                                        name={"clients"}
                                        value={values.clients}
                                        onChange={(e) => {
                                            setDebtors([]);
                                            setSelectedClient(e.target.value);
                                            handleChange(e);
                                        }}
                                    />
                                    <Select
                                        name={"debtors"}
                                        options={debtors}
                                        error={errors.debtors}
                                        label={"Debtor"}
                                        value={values.debtors}
                                        onChange={handleChange}
                                    />
                                </div>
                            ) : null}
                            <div className={styles.inputs}>
                                <div>
                                    <DatePicker
                                        updateTitle={(date) => {
                                            handleChange({ target: { value: date, name: "date" } });
                                        }}
                                        label={"Date"}
                                        value={values.date}
                                        title={
                                            values.date && values.date !== ""
                                                ? moment(values.date.toString()).format("DD/MM/yyyy")
                                                : "Date"
                                        }
                                    />
                                    {errors.date && touched.date ? (
                                        <p style={{ textAlign: "center", color: "red" }}>{errors.date}</p>
                                    ) : null}
                                </div>

                                <div>
                                    <TimePicker
                                        onChange={(time) =>
                                            handleChange({
                                                target: { value: time, name: "time" },
                                            })
                                        }
                                        name={"time"}
                                        value={values.time}
                                        label={"Time"}
                                    />
                                    {errors.time && touched.time ? (
                                        <p style={{ textAlign: "center", color: "red" }}>{errors.time}</p>
                                    ) : null}
                                </div>
                                <Select
                                    name={"duration"}
                                    options={timeOptions}
                                    label={"Duration"}
                                    value={values.duration}
                                    onChange={handleChange}
                                />
                            </div>
                            {source === "calendar" ? (
                                <div style={{ marginBottom: 20 }}>
                                    <TextArea
                                        name={"note"}
                                        label={"Note"}
                                        value={values.note}
                                        onChange={handleChange}
                                        error={errors.note}
                                    />
                                </div>
                            ) : null}
                            <div className={styles.addEventSubmit}>
                                <ButtonLoader
                                    title={event ? "Save" : "Add"}
                                    variant={"outlined"}
                                    handler={handleSubmit}
                                    loading={loading}
                                    disable={!clients.length || !debtors.length}
                                    width={200}
                                    height={50}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddEvent;
