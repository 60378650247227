import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../providers/hooks/useAuth';

const PrivateRoute = ({ children, redirectTo, ...rest }) => {
    const { isLoggedIn } = useAuth();
    const [path, setPath] = useState(null);

    const direct = async () => {
        const loggedIn = await isLoggedIn();
        if (loggedIn) {
            setPath(children);
        } else {
            setPath(<Redirect to={redirectTo} />);
        }
    }

    useEffect(() => {
        if (!path) {
            direct();
        }
    }, [path])

    return (
        <Route
            {...rest}
            render={() => path}
        />
    );
};

export default PrivateRoute;
