import React, { useState, useEffect } from "react";
import { useActivityTracker } from "../../../providers/hooks/useActivityTracker";
import { useStopwatch } from 'react-timer-hook';
import { useModal } from '../../../providers/hooks/useModal';

const ActivityTracker = () => {
  const { seconds, reset } = useActivityTracker();

  useEffect(() => {
    document.addEventListener("click", reset);
    document.addEventListener("mousemove", reset);
    document.addEventListener("input", reset);
  }, []);

  return <div></div>;
};

export default ActivityTracker;
