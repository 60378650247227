import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExportService from "api/services/exportService";
import BluePillButton from "components/buttons/BluePillButton";
import { MaterialTableCurrencySettings } from "constants/locale";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ClientService, AgentService } from "../../../../api/services";
import file_icon from "../../../../assets/icons/FontAwsome (link).png";
import cancel_icon from "../../../../assets/icons/Group 414.png";
import search_icon from "../../../../assets/icons/Group 435.png";
import { ButtonBack, Section, TitleSection } from "../../../../common/Layouts";
import AssignDebtors from "../../../../components/modal/content/AssignDebtors";
import CustomMaterialTable from "../../../../components/table/CustomMaterialTable";
import { DASHBOARD, DEBTORS } from "../../../../constants/pathNames";
import { useAuth } from "../../../../providers/hooks/useAuth";
import { useMaterialTable } from "../../../../providers/hooks/useMaterialTable";
import { useModal } from "../../../../providers/hooks/useModal";
import styles from "./styles.module.css";
import NextDebtorCallAvailable from "components/modal/NextDebtorCallAvailable";
import ReportsDownloadMenu from "../../../../components/ReportsDownloadMenu";
import DebtorRegisterActionMenu from "components/DebtorRegisterActionMenu";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        width: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const getClientById = (id, clients) => {
    let client = clients.find((item) => item.client_id === id);
    console.log("testing", client);
    return { client_id: client.id };
};

const CustomToolbar = (props) => {
    const [advancedSearch, setAdvancedSearch] = React.useState(false);
    const { tableRef, dispatch, selection } = props;
    const handleTableSelectionClick = () => {
        if (selection) {
            if (props.selectedRows?.length > 0) {
                dispatch({
                    type: "selection-assign",
                    selected: props.selectedRows,
                });
            } else {
                dispatch({ type: "selection-disable" });
            }
        } else {
            dispatch({ type: "selection-enable" });
        }
    };
    const handleCloseAdvancedSearch = () => {
        if (tableRef.current != null) {
            var query = tableRef.current.state.query;
            query.filters = [];
            tableRef.current.onQueryChange(query);
        }
        setAdvancedSearch(false);
    };
    const handleAdvancedSearch = (e) => {
        e.preventDefault();

        var filters = [];
        var advanced_columns = [
            "account_number",
            "identification_number",
            "name",
            "reference_number",
            "phone_home",
            "phone_work",
            "phone_cell",
            // "hide_owned_debtors", this needs to be handled separately
        ];

        advanced_columns.forEach((column) => {
            if (column in e.target && !!e.target[column].value) {
                // !! to check for "" or false
                filters.push({
                    column_name: column,
                    search_term: e.target[column].value,
                });
            }
        });
        if (e.target.hide_owned_debtors?.checked) {
            filters.push({
                column_name: "hide_owned_debtors",
                search_term: "true",
            });
        }
        if (tableRef.current != null) {
            var query = tableRef.current.state.query;
            query.filters = filters.map((filter) => ({
                column: {
                    field: filter.column_name,
                },
                value: filter.search_term,
            }));
            tableRef.current.onQueryChange(query);
        }
    };
    return (
        <div className={styles.toolbarContainer}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" gridGap={10} alignItems="center" alignSelf="flex-start">
                    <TitleSection>Debtor Register</TitleSection>

                    {props.selectedRows != null && props.selectedRows.length > 0 ? (
                        <DebtorRegisterActionMenu agent_id={null} accounts={props.selectedRows} />
                    ) : (
                        <BluePillButton variant="contained" onClick={handleTableSelectionClick}>
                            Bulk Actions
                        </BluePillButton>
                    )}
                </Box>
                {/* <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gridGap={10}
        >
          <TextField
            placeholder="Search..."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={search_icon} alt="search" width={25} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box> */}
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <div>
                        <BluePillButton variant="contained" onClick={() => setAdvancedSearch(!advancedSearch)}>
                            Advanced Search
                        </BluePillButton>
                    </div>
                </Box>
            </Box>
            {advancedSearch ? (
                <form onSubmit={handleAdvancedSearch}>
                    <div className={styles.advancedSearch}>
                        <div className={styles.advancedSearchFields}>
                            <TextField
                                id="account_number"
                                label="Account No."
                                variant="outlined"
                                name="account_number"
                            />
                            <TextField
                                id="identification_number"
                                label="ID No."
                                variant="outlined"
                                name="identification_number"
                            />
                            <TextField id="name" label="Name" variant="outlined" name="name" />
                            <TextField
                                id="reference_number"
                                label="Reference No."
                                variant="outlined"
                                name="reference_number"
                            />
                            <TextField id="phone_home" label="Home Tel" variant="outlined" name="phone_home" />
                            <TextField id="phone_cell" label="Cell" variant="outlined" name="phone_cell" />
                            <TextField id="phone_work" label="Work Tel" variant="outlined" name="phone_work" />
                        </div>
                        <FormControlLabel control={<Checkbox name="hide_owned_debtors" />} label="Hide Owned Debtors" />
                        <IconButton type="submit">
                            <img src={search_icon} alt="search" width={50} />
                        </IconButton>
                        <IconButton className={styles.advancedClose} onClick={handleCloseAdvancedSearch}>
                            <img src={cancel_icon} alt="search" width={20} />
                        </IconButton>
                    </div>
                </form>
            ) : null}
        </div>
    );
};

function DebtorCaseRegister() {
    const history = useHistory();
    const [campaignExistence, setCampaignExistence] = React.useState(null); // TODO - replace with real check for next call
    const { agent_id, user_type_id } = useAuth();
    const { pathname, state: locationState } = useLocation();
    const { setModal, unsetModal } = useModal();
    const [selection, setSelection] = React.useState(false); // have to manually control table selection state (use header dispatch for control)
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                ClientService.getClientDebtorRegister(params).then((response) => {
                    console.log({ response });
                    resolve({
                        status: response.status,
                        data: response.data.debtors,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: null,
    });

    const getClients = async () => {
        const resp = await AgentService.getAvailableClientsFromActiveCampaigns();
        if (resp.status === "success") {
            const client_name = resp.data?.clients?.find((c) => c.id === locationState?.client_id);
            setCampaignExistence(client_name);
        }
    };

    React.useEffect(() => {
        getClients();
    }, []);

    const handleRowClick = (event, row) => {
        const location = {
            pathname: `${pathname}/${row.debtor_name}`,
            state: {
                debtor_name: row.debtor_name,
                account_number: row.account_number,
            },
        };
        history.push(location);
    };

    const dispatch = (params) => {
        if (params.type != null) {
            switch (params.type) {
                case "selection-assign":
                    if (params?.selected != null && params.selected.length > 0) {
                        setModal({
                            Component: (
                                <AssignDebtors
                                    agent_id={agent_id}
                                    debtors={params.selected}
                                    onClose={() => {
                                        unsetModal();
                                        setSelection(false);
                                    }}
                                />
                            ),
                            size: "sml",
                        });
                    }
                    break;
                case "selection-enable":
                    setSelection(true);
                    break;
                case "selection-disable":
                    setSelection(false);
                    break;
                default:
                    break;
            }
        }
    };

    React.useEffect(() => {
        if (locationState?.client_id != null) {
            setParams({
                client_id: locationState.client_id,
            });
        }
    }, [locationState]);

    React.useEffect(() => {
        if (campaignExistence && user_type_id === 4) {
            // TODO - replace with real check for next call
            setModal({
                Component: <NextDebtorCallAvailable client={locationState} />,
                size: "sml",
                canClickAway: false,
                closeBtn: false,
            });
            // }
        }
    }, [campaignExistence]);

    return (
        <div>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    isCustom
                    fontSize={15}
                    small_size={12}
                    customStyle={{
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.push(`${DASHBOARD}${DEBTORS}`)}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontFamily: "Avenir Next" }}>Client register</span>
                {`/${locationState?.client_name}`}
                <div className={styles.exportIcon}>
                    <ReportsDownloadMenu client_id={locationState?.client_id} />
                </div>
            </TitleSection>
            <Section>
                <CustomMaterialTable
                    tableRef={tableRef}
                    title={null}
                    columns={[
                        { title: "ID", field: "id", hidden: true },
                        { title: "Debtor Name", field: "debtor_name" },
                        { title: "Account Type", field: "account_type" },
                        { title: "Account Number", field: "account_number" },
                        {
                            title: "Reference Number",
                            field: "reference_number",
                        },
                        {
                            title: "Capital",
                            field: "capital",
                            type: "currency",
                            currencySetting: MaterialTableCurrencySettings,
                        },
                        {
                            title: "Interest",
                            field: "interest",
                            type: "currency",
                            currencySetting: MaterialTableCurrencySettings,
                        },
                        {
                            title: "Fees",
                            field: "fees",
                            type: "currency",
                            currencySetting: MaterialTableCurrencySettings,
                        },
                        {
                            title: "Legal Fees",
                            field: "legal_fees",
                            type: "currency",
                            currencySetting: MaterialTableCurrencySettings,
                        },
                        {
                            title: "Settlement",
                            field: "settlement",
                            type: "currency",
                            currencySetting: MaterialTableCurrencySettings,
                        },
                        {
                            title: "Agent",
                            field: "agent",
                            align: "center",
                            render: (row) =>
                                row.agent != null && Array.isArray(row.agent) && row.agent.length > 0 ? (
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                {row.agent.map((agent) => (
                                                    <p key={`agent-${agent.id}`}>
                                                        {agent.name}
                                                        {agent.surname != null ? " " + agent.surname : null}
                                                    </p>
                                                ))}
                                            </React.Fragment>
                                        }
                                        arrow
                                    >
                                        <Chip label={`${row.agent.length} Agent${row.agent.length > 1 ? "s" : ""}`} />
                                    </HtmlTooltip>
                                ) : (
                                    "-"
                                ),
                        },
                        { title: "Last Activity", field: "last_activity", align: "right" },
                    ]}
                    data={remoteDataSource}
                    onRowClick={handleRowClick}
                    options={{
                        selection,
                        pageSize: 10,
                        pageSizeOptions: [10, 15, 20],
                        search: false,
                    }}
                    components={{
                        Toolbar: (props) => (
                            <CustomToolbar tableRef={tableRef} dispatch={dispatch} selection={selection} {...props} />
                        ),
                    }}
                />
            </Section>
        </div>
    );
}

export default DebtorCaseRegister;
