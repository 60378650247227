import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = props => makeStyles((theme) => ({
  paper: {
    position: 'absolute',
  /*   width: props.width, */
    backgroundColor: '#fff',
    borderRadius: '12px',
    outline: 'none',
    border: 'none',
    fontFamily: 'Avenir Next Bold',
  }
}));

export default function CommonModal({
  children,
  open, 
  handleClose,
  width='100%' 
}) {
  const classes = useStyles({width})();

  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {children}
    </div>
  );


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{
          style: {
            backdropFilter: "blur(3px)",
            backgroundColor:'rgba(0, 0, 0, 0.6)',
            //backgroundColor:'rgba(0,0,0,0.1)'

          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          {body}
        </Fade>
      </Modal>
    </div>
  );
}