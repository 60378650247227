import React from "react";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CaseService from "../../../services/caseService";
import { dateFormat, timeFormat } from "../../../utils/dateFormat";

const MessagesTable = ({ account_no }) => {
	const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.debtorCaseMessages(params).then((response) => {
					resolve({
						status: response.status,
						data: response.data.messages,
						count: response.data.count,
						pagination: response.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

	return (
		<CustomMaterialTable
			title={null}
			tableRef={tableRef}
			columns={[
				{
					title: "Date",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? dateFormat(row.date_time) : "-",
				},
				{
					title: "Time",
					field: "date_time",
					render: (row) =>
						row.date_time != null ? timeFormat(row.date_time) : "-",
				},
				{ title: "Sender", field: "sender" },
				{ title: "Recipient", field: "recipient" },
				{ title: "Message", field: "message" },
			]}
			data={remoteDataSource}
			options={{
				search: false,
				toolbar: false,
			}}
		/>
	);
};

export default MessagesTable;
