import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import calendarTop from '../assets/icons/Group 407.png';


const useStyles = props => makeStyles((theme) => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }, 
  },
  titleCalendar: {
    fontSize: '1.2vw',
     marginRight: '-10px',
     paddingTop: '5px',
     display: 'flex',
     alignItems: "center",
     fontFamily: 'Avenir Next',
     '@media (min-width:1920px)': {
      fontSize: '20px',
    },
  }, 
  icon_calendar: {
    width: `${props.size}px`
  }
})); 

export default function SelectDate({title, size=22, isFee=false, updateTitle}) {
  const classes = useStyles({size})();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if(updateTitle) {
      updateTitle(date); 
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid 
        container 
        justifyContent={!isFee ? "space-around" : "center"} 
        alignItems="center" className={classes.root}>
        <div className={classes.titleCalendar}>{title}</div>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="none"
          leftArrowButtonProps={{
            style: {
              /* display: 'none' */
            }
          }}
          rightArrowButtonProps={{
            style: {
              /* display: 'none' */
            }
          }}
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',  
            width: 300,
          }}
          InputProps={{
            style: {
               borderBottom: 'none',
               width: '50px',
            }
          }}
          keyboardIcon={<img className={classes.icon_calendar} src={calendarTop} alt=""/>}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
