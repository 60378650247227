import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useModal } from "../../../providers/hooks/useModal";
import ButtonComponent from "../../ButtonComponent";

const BulkAssignAgents = ({ clients, onAssign, selectedAgents }) => {
  const { unsetModal } = useModal();
  const [selected, setSelected] = useState([]);

  const toggleItemSelected = (item) => {
    let s = [...selected];
    if (selected.filter((i) => i.id === item.id).length) {
      const temp = s.filter((i) => i.id !== item.id);
      s = temp;
    } else {
      s.push(item);
    }
    setSelected(s);
  };

  return (
    <div className={"flex col jc-sb h-100 p-2"} style={{minWidth: 300}}>
      <div>
        <h2 className={"bold m-0 color-primary_dark"}>Bulk Assign</h2>
        <h5>{selectedAgents?.length > 1 ? `You have selected ${selectedAgents?.length} agents` : `Assign ${selectedAgents[0].name} to the following clients`}</h5>
      </div>
      <div>
        {clients?.map((client) => (
          <div className={"flex roc ai-c"}>
            <Checkbox onClick={() => toggleItemSelected(client)} />
            <h5 className={"m-0"} style={{ paddingTop: 5 }}>
              {client.name}
            </h5>
          </div>
        ))}
      </div>
      <div className={"w-100 ta-c mt-2"}>
        <ButtonComponent variant={'outlined'} width={280} title={"Assign"} handler={() => onAssign(selected)} />
      </div>
    </div>
  );
};

export default BulkAssignAgents;
