import React from "react";
import { Snackbar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useModal } from "../../providers/hooks/useModal";
import { Alert } from "@material-ui/lab";

const BasicToast = () => {
    const message = useSelector((state) => state.nav.toast.message);
    const type = useSelector((state) => state.nav.toast.type);

    const { unsetToast } = useModal();
    return message ? (
        <>
            <Snackbar open={!!message} autoHideDuration={6000} onClose={unsetToast} message={message} action={() => {}}>
                <Alert onClose={unsetToast} severity={type} variant="filled">
                    {message}
                </Alert>
            </Snackbar>
        </>
    ) : null;
};

export default BasicToast;
