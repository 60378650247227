import React from 'react'
import styled from 'styled-components'
import { colors } from '../constants/styles';
import search_icon from '../assets/icons/search_icon.png'; 

const SearchFieldWrap = styled.div`
  display: flex;
  justify-content: ${props => (props.isSearch || props.isCustom) ? 'flex-end' : 'center'};
  ${props => props.customStyle};
`;

const SearchField = styled.input`
  width: ${props => props.width ? props.width : '140px'};
  height: ${props => props.height ? props.height : '30px'};
  border: 1px solid ${props => !props.error ? colors.blue : "red"};
  border-radius: 45px; 
  outline: none;
  padding: 0px 10px;
  font-family: 'Avenir Next Medium';
  font-size: ${props => props.fontSize ? props.fontSize : '12px'};
  color: ${colors.blue};
  box-sizing: border-box;
  text-align: ${props => props.isSearch || props.isLeft ? 'left' : 'center'};
  ::placeholder {
    font-size: 12px; 
    font-family: 'Avenir Next';
    color: ${props => props.isSearch ? 'rgba(0, 43, 100, 0.59)' : '#AAAAAA'};
    @media (min-width:1920px) {
      font-size: 15px; 
  }
  }
  @media (min-width:1920px) {
    width: ${props => props.width ? props.width : '166px'};
    padding: 0px 15px;
    padding-top: 2px;
    font-size: ${props => props.fontSize ? props.fontSize : '15px'};
  }
`; 

const SearchFieldLarge = styled.textarea`
  width: ${props => props.width ? props.width : '140px'};
  border: 1px solid ${props => !props.error ? colors.blue : 'red'};
  border-radius: 19px; 
  outline: none; 
  padding: 10px;
  font-family: 'Avenir Next Medium';
  line-height: 1.2;
  font-size: ${props => props.fontSize ? props.fontSize : '12px'};
  color: ${colors.blue};
  box-sizing: border-box;
  text-align: 'left';
  ::placeholder {
    font-size: 12px; 
    font-family: 'Avenir Next';
    color: ${props => props.isSearch ? 'rgba(0, 43, 100, 0.59)' : '#AAAAAA'};
    @media (min-width:1920px) {
      font-size: 15px; 
  }
  }
  @media (min-width:1920px) {
    width: ${props => props.width ? props.width : '166px'};
    font-size: ${props => props.fontSize ? props.fontSize : '15px'};
  }
`; 

const WrapperIcon = styled.div`
  width: 26px;
  height: 26px; 
  width: ${props => !props.positionIcon ? '28px' : '26px'};
  height: ${props => !props.positionIcon ? '28px' : '26px'};
  border-radius: 45px; 
  margin-left: -29px;
  background-color: ${colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => !props.positionIcon ? '1px' : '2px'};
  cursor: pointer;
  @media (min-width:1920px) {
    width: ${props => props.size};
    height: ${props => props.size};
    margin-top: ${props => !props.positionIcon ? props.position : '2px'};
    margin-left: ${props => !props.positionIcon ? props.mgLeft : '-28px'};
  }
  img {
    width: 15px;
  }
`;

function SearchTextField({
  handleChange=null, 
  searchValue, 
  value="",
  isSearch=true, 
  width=null, 
  customStyle=null,
  height=null, 
  title="Search...", 
  positionIcon=false,
  fontSize="",
  isFee=false,
  name='',
  isClientManager=false,
  isLarge=false,
  isLeft=false,
  handler=()=>{},
  error=false,
  handleBlur=null
}) {
  let position = '2px'
  let size='26px'; 
  let mgLeft = '-28px'; 
  if(isFee) {
    height = '23px'
  }
  if(isClientManager) {
    position = '1px'
    size="28px"
    mgLeft = '-29px'
  }

  const handleChangeWithValidate = (e) => {
    if(e.target.value.length < 30) {
      handleChange(e); 
    }
  }

  const listenForEnter = (e) => {
    if (e.keyCode == 13 ) {
			handler(searchValue); 
		}
  }

  return (
    <SearchFieldWrap isSearch={isSearch} customStyle={customStyle}>
    {!isLarge && <SearchField
        value={searchValue ? searchValue :  value }
        width={width}
        height={height}
        name={name}
        isSearch={isSearch}
        isLeft={isLeft}
        error={error}
        fontSize={fontSize}
        onKeyDown={listenForEnter}
        onChange={handleChange ? handleChange : handleChangeWithValidate}
        placeholder={title}
        {...(handleBlur && { onBlur: handleBlur })} 
        type='text'/> }

    { isLarge && <SearchFieldLarge
          value={value ? value : searchValue}
          width={width}
          height={height}
          rows="5" 
          name={name}
          error={error}
          isSearch={isSearch}
          fontSize={fontSize}
          onChange={handleChange ? handleChange : handleChangeWithValidate}
          onKeyDown={listenForEnter}
          placeholder={title} 
          {...(handleBlur && { onBlur: handleBlur })}
          type='text'/> }
      
       {isSearch && <WrapperIcon
         mgLeft={mgLeft} 
         position={position} 
         size={size} 
         onClick={()=> handler(searchValue)}
         positionIcon={positionIcon}>
         <img src={search_icon} alt=""/>
       </WrapperIcon>}
    </SearchFieldWrap>
  )
}

export default SearchTextField
