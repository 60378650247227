import { GET_AGENTS_LOGOUT_BREAKS, GET_AGENTS_SUMMARY, GET_LIST_AGENTS, IS_LOADING } from "../../constants/reduxConstants";

let initialState = {
    listAgents: [], 
    agentsSummary: [],
    agentLogoutBreaks: [], 
    isLoading: false,
  }
  
  const agentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_AGENTS:
            return {...state, listAgents: action.payload }
        case GET_AGENTS_SUMMARY:
            return {...state, agentsSummary: action.payload } 
        case GET_AGENTS_LOGOUT_BREAKS:
            return {...state, agentLogoutBreaks: action.payload }  
        case IS_LOADING: 
            return {...state, isLoading: action.payload}         
      default:
        return state; 
    }
  }

  export const setListAgents = (list) => ({type: GET_LIST_AGENTS, payload: list}); 
  export const setListAgentsSummary = (list) => ({type: GET_AGENTS_SUMMARY, payload: list}); 
  export const setListAgentsLogoutBreaks = (list) => ({type: GET_AGENTS_LOGOUT_BREAKS, payload: list}); 
  export const setIsLoading = (val) => ({type: IS_LOADING, payload: val}); 

  
  export default agentReducer; 
  