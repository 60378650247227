import React from "react";
import { useSelector } from "react-redux";

const usePrevious = (value) => {
	const ref = React.useRef();
	React.useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const usePersistentTransistion = () => {
	const [status, setStatus] = React.useState();
	const { Component } = useSelector((state) => state.nav.persistent);
	const oldState = usePrevious(Component);

	React.useEffect(() => {
		if (oldState == null && Component != null) {
			setStatus("opened");
		}
		if (oldState != null && Component == null) {
			setStatus("closed");
		}
	}, [Component, oldState]);
	return {
		status,
	};
};

export default usePersistentTransistion;
