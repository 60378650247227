import React from 'react'
import styled from 'styled-components'
import { Section } from '../common/Layouts';
import { colors } from '../constants/styles';

const SwitcherWrap = styled.div`
  border-radius: 16px 16px 0px 0px; 
  border-top: 1px solid ${colors.blue}; 
  border-left: 1px solid ${colors.blue}; 
  border-right: 1px solid ${colors.blue}; 
  cursor: pointer;
  margin-left: 10px;
  width: max-content;
  height: 100%;
  text-transform: uppercase;
 
  @media (min-width:1920px) {
    margin-left: 15px;
  }
  
`;

const Item = styled.div`
  &.inner-switch {
    height: 100%;
    text-align: center;
    font-weight: bold; 
    box-sizing: border-box;
    font-family: 'Avenir Next Bold';
    font-size: 1.2vw;
    padding: 10px 14px;
    @media (min-width:1920px) {
      font-size: 20px;
      padding: 18px 20px;
    }
  }
  &.active {
    transition: color, background-color 1s; 
    background-color: ${colors.blue};
    color: ${colors.white}; 
    border-radius: 14px 14px 0px 0px; 
    height: 100%;
    
    padding: ${ props => props.isContacts ? 10 : 8}px 14px;
    @media (min-width:1920px) {
      padding: ${ props => props.isContacts ? 18 : 16}px 20px;
    }
  }
`;


function SwitchComponent({currentItem, handler, configList}) {
  return (
    <SwitcherWrap>
      <Section>
        {configList.map(item => <Item 
          key={item} 
         /*  isContacts={item === 'contacts' || item === 'agents summary' || item ==='logout/breaks summary'} */
         isContacts
          className={`inner-switch ${currentItem === item ? 'active' : ''}`}
          onClick={() => handler(item)}>
         {item}
        </Item>)}
      </Section>
    </SwitcherWrap>
  )
}

export default SwitchComponent