import React from "react";
import style from "../styles.module.css";
import { ArrowDown } from "../../../common/Layouts";

const Select = ({ options, value, onChange, error, label, name }) => {
  return (
    <div className={style.wrapper_input}>
      <div className={style.input_title}>{label ? label : "Manager Name"}</div>
      <div style={{ position: "relative" }}>
        <select
          name={name ? name : "manager_id"}
          type={"text"}
          className={"select-input"}
          value={value}
          onChange={onChange}
          error={error}
        >
          {options?.map((item) => (
            <option value={item.value}>{item.name}</option>
          ))}
        </select>
        <span className={style.select_field}>
          <ArrowDown base={13} side={9} color={"#959595"} />
        </span>
      </div>

      {error && (
        <label style={{ textAlign: "center", marginTop: 10, width: '95%' }}>{error}</label>
      )}
    </div>
  );
};

export default Select;
