import React from "react";
import ButtonComponent from "../../ButtonComponent";
import { useModal } from "../../../providers/hooks/useModal";
import { Grid, Typography } from "@material-ui/core";

const ConfirmAction = ({ onConfirm, message }) => {
  const { unsetModal } = useModal();
  return (
    <div className={"p-3 flex col ai-c jc-sb"} style={{ minWidth: 300 }}>
      <Grid container spacing={1} direction={"column"} alignItems={"center"}>
        <Grid item>
          <Typography variant="h5">
            <strong>Confirm</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {message ? message : "Are you sure?"}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3} direction={"row"} alignItems={"center"}>
            <Grid item xs={6}>
              <ButtonComponent title={"Confirm"} handler={onConfirm} />
            </Grid>
            <Grid item xs={6}>
              <ButtonComponent title={"Cancel"} handler={unsetModal} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmAction;
