import React, { useRef, useState, useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { ArrowDown, Box, ButtonBack, TitleSection } from "../../common/Layouts";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Grid } from "@material-ui/core";
import icon_delete from "../../assets/icons/Layer -14.png";
import edit_icon from "../../assets/icons/Layer -16.png";
import style from "./Administration.module.css";
import { UserService } from "../../api/services";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { useModal } from "../../providers/hooks/useModal";
import { useGlobalLoader } from "../../providers/hooks/useGlobalLoader";

function NewUser() {
    const [dropdowns, setDropdowns] = useState(null);
    const { toggleLoading } = useGlobalLoader();
    const [initialValues, setInitialValues] = useState({
        name: "",
        surname: "",
        job_title: "",
        user_type: -1,
        manager_id: 1,
        telephone_number: "",
        email: "",
    });
    const [img, setImg] = useState(null);
    const [img64, setImg64] = useState(null);
    const { setToast } = useModal();
    let imgRef;
    const history = useHistory();

    const AddUserSchema = Yup.object().shape({
        name: Yup.string().required("Please enter a name"),
        surname: Yup.string().required("Please enter a surname"),
        job_title: Yup.string().required("Please enter a job title"),
        user_type: Yup.number().required("Please select a user type"),
        manager_id: Yup.number().required("Please select a manager"),
        telephone_number: Yup.string().required("Please enter a contact number"),
        email: Yup.string().required("Pleaase enter an email address").email("Please enter a valid email address"),
    });

    const imgToBase64 = async (file) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImg64(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const setFormImg = async (e) => {
        imgToBase64(e.target.files[0]);
        setImg(e.target.files[0]);
    };

    const getDropdowns = async () => {
        const resp = await UserService.getNewUserDropdowns();
        if (resp.status === "success") {
            setDropdowns(resp.data);
            setInitialValues({
                ...initialValues,
                manager_id: -1,
            });
        }
    };

    useEffect(() => {
        getDropdowns();
    }, []);

    const formRef = useRef();
    let sizeArrow = window.innerWidth > 1400 ? 13 : 8;

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <div>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontFamily: "Avenir Next" }}>User manager / </span>
                {` new user`}
            </TitleSection>
            <Box padding={"30px 37px"} paddingSmall={"23px 29px"}>
                <Grid container justifyContent="space-between">
                    <div className={style.title}>USER DETAILS</div>
                    <Grid className={style.images_wrapper}>
                        <img
                            onClick={
                                formRef.current
                                    ? () => {
                                          console.log("hit");
                                          formRef.current.handleSubmit();
                                      }
                                    : () => {}
                            }
                            src={edit_icon}
                            alt=""
                        />
                        <img src={icon_delete} onClick={() => history.goBack()} alt="" />
                    </Grid>
                    <Grid container justifyContent="flex-start">
                        <Grid item md={2}>
                            <Grid container justifyContent="flex-start">
                                <div>
                                    <input ref={(r) => (imgRef = r)} type="file" hidden onChange={setFormImg}></input>
                                    {img64 ? (
                                        <img onClick={() => imgRef?.click()} className={style.add_photo} src={img64} />
                                    ) : (
                                        <div onClick={() => imgRef?.click()} className={style.add_photo}>
                                            + Add Photo
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item md={9}>
                            <Formik
                                innerRef={formRef}
                                initialValues={initialValues}
                                validationSchema={AddUserSchema}
                                enableReinitialize
                                onSubmit={async (values) => {
                                    toggleLoading();
                                    const formData = new FormData();
                                    console.log(Object.keys(values));
                                    Object.keys(values).forEach((key) => {
                                        formData.append(key, values[key]);
                                    });
                                    if (img) {
                                        formData.append("photo", img);
                                    }

                                    const resp = await UserService.createUser(formData);
                                    if (resp.status === "success") {
                                        history.goBack();
                                        setToast(resp.message, "success");
                                    } else {
                                        setToast(resp.message, "error");
                                    }
                                    toggleLoading();
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    setValues,
                                    setTouched,
                                    resetForm,
                                    isSubmitting,
                                    touched,
                                    values,
                                }) => (
                                    <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                        <Grid container spacing={3} justifyContent="center">
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>First Name</div>
                                                <input
                                                    name={"name"}
                                                    type={"text"}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    error={errors.name}
                                                />
                                                {errors.name && touched.name && <label>{errors.name}</label>}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Last Name</div>
                                                <input
                                                    name={"surname"}
                                                    type={"text"}
                                                    value={values.surname}
                                                    onChange={handleChange}
                                                    error={errors.surname}
                                                />
                                                {errors.surname && touched.surname && <label>{errors.surname}</label>}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Manager Name</div>
                                                <select
                                                    name={"manager_id"}
                                                    className={"select-input"}
                                                    type={"text"}
                                                    value={values.manager_id}
                                                    onChange={handleChange}
                                                    error={errors.manager_id}
                                                    {...(values.user_type < 4 && {
                                                        disabled: true,
                                                        style: { opacity: 0.4 },
                                                    })}
                                                >
                                                    {(() => {
                                                        const el = [
                                                            <option value={-1}>Please select a manager</option>,
                                                        ];
                                                        dropdowns?.managers?.forEach((item) => {
                                                            el.push(<option value={item.id}>{item.name}</option>);
                                                        });
                                                        return el;
                                                    })()}
                                                </select>
                                                <span className={style.select_field}>
                                                    <ArrowDown
                                                        base={sizeArrow}
                                                        side={sizeArrow - 4}
                                                        color={"#959595"}
                                                    />
                                                </span>
                                                {errors.manager && touched.manager && <label>{errors.manager}</label>}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Telephone No.</div>
                                                <input
                                                    name={"telephone_number"}
                                                    type={"text"}
                                                    value={values.telephone_number}
                                                    onChange={handleChange}
                                                    error={errors.telephone_number}
                                                />
                                                {errors.telephone_number && touched.telephone_number && (
                                                    <label>{errors.telephone_number}</label>
                                                )}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>Job Title</div>
                                                <input
                                                    name={"job_title"}
                                                    type={"text"}
                                                    value={values.job_title}
                                                    onChange={handleChange}
                                                    error={errors.job_title}
                                                />
                                                {errors.job_title && touched.job_title && (
                                                    <label>{errors.job_title}</label>
                                                )}
                                            </Grid>
                                            <Grid item md={6} className={style.wrapper_input}>
                                                <div className={style.input_title}>User Type</div>
                                                <select
                                                    className={"select-input"}
                                                    name={"user_type"}
                                                    type={"text"}
                                                    value={values.user_type}
                                                    onChange={handleChange}
                                                    error={errors.user_type}
                                                >
                                                    {dropdowns?.user_types?.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                <span className={style.select_field}>
                                                    <ArrowDown
                                                        base={sizeArrow}
                                                        side={sizeArrow - 4}
                                                        color={"#959595"}
                                                    />
                                                </span>
                                                {errors.user_type && touched.user_type && (
                                                    <label>{errors.user_type}</label>
                                                )}
                                            </Grid>
                                            <Grid item md={12} className={style.wrapper_input}>
                                                <div className={style.input_title}>Email Address</div>
                                                <input
                                                    name={"email"}
                                                    type={"text"}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    error={errors.email}
                                                />
                                                {errors.email && touched.email && <label>{errors.email}</label>}
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default NewUser;
