import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { colors } from "../constants/styles";

const useStyles = (props) =>
  makeStyles((theme) => ({
    btn: {
      borderRadius: "36px",
      backgroundColor:
        props.variant === "outlined" ? "transparent" : colors.blue,
      color: props.variant === "outlined" ? props.color : colors.white,
      textTransform: "none",
      height: props.height - props.height / 5,
      fontSize: props.small_size,
      width: props.width - props.width / 5,
      "@media (min-width:1920px)": {
        height: props.height,
        fontSize: props.fontSize,
        width: props.width,
      },
      "&:hover": {
        backgroundColor:
          props.variant === "outlined" ? colors.blue : colors.lightGray,
        color: props.variant === "outlined" ? colors.white : colors.blue,
      },
    },
  }));

function ButtonComponent({
  title,
  handler = null,
  type = "",
  disable = false,
  width = "150px",
  color = null,
  height = "auto",
  fontSize = null,
  small_size = "1vw",
  variant = "contained",
  style = null,
}) {
  const classes = useStyles({
    width,
    color,
    height,
    fontSize,
    small_size,
    variant,
  })();
  const handlerClick = () => {
    if (handler) {
      handler();
    }
  };
  return (
    <Button
      type={type}
      disabled={disable}
      className={classes.btn}
      onClick={handlerClick}
      variant={variant}
      {...(style && { style: style })}
    >
      {title}
    </Button>
  );
}

export default ButtonComponent;
