import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {colors, statusPendingColor } from '../constants/styles'; 
import SelectDate from './SelectDate';
import SearchTextField from './SearchTextField';
import { ArrowDown, ArrowUP, ButtonBack } from '../common/Layouts';
import _ from 'lodash'; 
import save_icon from '../assets/icons/Save Icon.png'
import save_icon_orange from '../assets/icons/Layer -16.png'
import delete_icon from '../assets/icons/Layer -14.png'
import message_icon from '../assets/icons/Layer -1.png'; 
import send_icon from '../assets/icons/FontAwsome (external-link-alt).png';
import new_send_icon from '../assets/icons/Layer 2111.png';
import file_icon from '../assets/icons/FontAwsome (file-pdf).png'; 
import edit_icon from '../assets/icons/FontAwsome (edit).png'; 
import attach_icon from '../assets/icons/Layer -25.png'; 
import SelectComponent from './SelectComponent';
import CheckboxBase from './CheckboxBase';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650, 
    borderLeft: `16px solid ${colors.white}`,
    borderRight: `16px solid ${colors.white}`,
    '& .MuiTableCell-root': {
      //padding: '10px',
      height: '38px',
      padding: 0,
      fontFamily: 'Avenir Next',
    },
    '& .MuiPaper-elevation1': {
      padding: '0px 20px',
    },
    '& .MuiTableCell-body': {
      color: colors.blue,
      //padding: '6px 0px',
  
      fontSize: '12px',
      letterSpacing: '0px',
      fontFamily: 'Avenir Next Medium',
      '@media (min-width:1920px)': {
        fontSize: '15px',
        //padding: '10px 2px',
      },
    },
    '& .MuiTableCell-head': {
      color: colors.blue,
      fontWeight: 'bold',
      fontSize: '12px', 
      textTransform: 'uppercase',  
      letterSpacing: '0px',
      fontFamily: 'Avenir Next Bold',
      minWidth: 100,
      overflow: 'auto',
      whiteSpace: 'nowrap',
      wordWrap: 'normal',
      textOverflow: 'ellipsis',
      '@media (min-width:1920px)': {
        fontSize: '15px',
      },
    },
  },
  wrapperSearch: {
    textAlign: 'right',
    padding: '20px 20px 0px 0px',
    position: 'relative',
  },
  search_admin_wrap: {
    textAlign: 'right',
    padding: '0px 2px 0px 0px',
    position: 'relative',
  
  },
  cellInner: {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
  },
  cellInner_fee: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
   },
   cellInner_right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
   },
  filter: {
    marginLeft: '5px',
    cursor: 'pointer',
    marginBottom: '5px'
  },
  lastEl: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset'
    }
  },
  innerTable: {
    overflowX: 'auto',
    width: window.innerWidth - 230
  },
  fee_icons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& img': {
      cursor: 'pointer',
      //transform: 'scale(0.8)',
      marginBottom: '5px',
    }
  },

  root: {
    paddingBottom: '50px'
  },
  amount: {
   fontWeight: 'bold',
  },
  checkbox: {
    textAlign: 'left'
  },
  select_cell: {

    '@media (min-width:1920px)': {
      width: '150px',
    },
  },
  mark_plus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontWeight: "bold",
    width: "10px",
    height: "10px",
    backgroundColor: "#ffffff", 
    color: "#002B64",
    marginRight: "5px",
    marginLeft: "0 !important",
    marginBottom: "3px",
  },
  title_btn: {
    marginLeft: "0 !important",
    fontFamily: 'Avenir Next Bold',
    fontSize: '12px',
    lineHeight: 1,
    '@media (min-width:1920px)': {
      fontSize: '15px',
    }
  },
  padding_cell: {
    paddingBottom: '10px',
  },
  agent_custom: {
    width: '150px',
    '@media (min-width:1920px)': {
      width: '250px',
      textAlign: 'right',
      margin: '0 auto', 
      /* justifyContent: 'flex-start' */
    },
  },
  agent_custom_first: {
    marginLeft: '20px',
  },
  schedule_text: {
    fontSize: '15px',
    '@media (min-width:1920px)': {
      fontSize: '20px',
    },
    '& img': {
      width: '15px',
      height: '15px',
      marginLeft: '5px',
      '@media (min-width:1920px)': {
        width: '20px',
        height: '20px',
      }
    }
  },
  wrapNotes: {
    position: 'absolute',
    width: '110px',
    top: '4px',
    marginLeft: '-4px',
    '@media (min-width:1920px)': {
      width: '140px',
      top: '4px',
      left: '26px'
    }
  },
  wrap_select: {
    width: '100%', 
    marginLeft: '-3px',
    '@media (min-width:1920px)': {
      paddingLeft: '40px'
    }
  }, 
  wrap_select_20: {
    width: '100%', 
    marginLeft: '-3px',
    '@media (min-width:1920px)': {
      paddingLeft: '20px'
    }
  },
  spacing_left: {
    marginLeft: '40px',
    '@media (min-width:1920px)': {
      marginLeft: '57px',
    }
  },
  spacing_left_body: {
    marginLeft: '20px',
    '@media (min-width:1920px)': {
      marginLeft: '37px',
    }
  }
  

});


export default function TableUI({
  data,
  isSearch=false,
  isFilter=false,
  currentTable,
  handlerSelectRow=null,
  isFee=false,
  isCustom=false,
  isOpenFee=false, 
  updateFee=null,
  handleClose=null,
  isAddRow=false,
  title_btn="",
  addRowHandler=null,
  addRow=false,
  isAmount,
  color_btn=colors.orange,
  isNoteType=false,
  listFeeSelect=[],
  handlerSelect=null,
  handleAssign=null,
  align='center',
  type="base",
  isCheckbox=false,
  handleChecked=null,
  isAdministration=false,
  isAdminSearch=true,
  isTopText=false,
  isClientManager=false,
  handlerClick=()=>{},
  handleSort=()=>{},
  listFeeDescOptions=[],
  isFilterOne=false,
  isBaseText=false,
  isEdit=false,
  selectId=null,
  valuesRow={},
  errors={},
  values={},
  touched={},
  handleSearch=()=>{},
  handleChange=()=>{},
  closeRow=()=>{},
  saveUpdates=()=>{},
  rowDelete=()=>{}, 
}) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(''); 
  const [listRows, setListRows] = useState([]); 
  const uploadRef = useRef(); 

  const selectRow = (val, id) => {
    if(handlerSelectRow) {
      handlerSelectRow({...val, idx: id}); 
    }
  }

  const handleUpload = () => {
    let elem = uploadRef.current; 
    elem.click();
  }

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value); 
  }
  
  let listName = []; 

  if(data?.length) {
     listName = Object.keys(data[0]);
  }

  const createData = (data) => ({ ...data }); 

  const createList = (data) => {
    let arr = []; 
    data.map(item => arr.push(createData(item))); 
    return arr; 
  }

  const formatName = (name) => {
    return name.split('_').join(' ');
  }

  const getColor = (data, idx) => {
    if(isAmount && idx === listRows.length - 1) {
      return colors.orange;
    }
    if(!isFee) {
      return colors.blue; 
    }
    if(idx === 3 || idx === 7) {
      return colors.green
    }
    if(idx === 1 || idx === 5) {
      return colors.purple
    }
    return colors.blue; 
  }

  const generateClassName = ({idx, item, idx2, align}) => {
    if(isAmount && idx === listRows.length - 1) {
      return classes.amount; 
    }
    if(type === 'agent') {
      return classes.checkbox
    }
    if(idx2 === 0 && isCheckbox && type === "base") {
      return classes.checkbox   
    }


    return '';
  }

  const generateClassNameRow = ({idx}) => {
    if(idx === listRows.length - 1) {
      return classes.lastEl; 
    }
    return '';
  }

  const generateAlignAgent = (idx, length) => {
    if(idx === length) {
      return "right"; 
    }
    if(idx === 2 ) {
      return "left"; 
    }
    return 'center'; 
  }



  const sortData = (name) => {
    let sortedList = _.orderBy(listRows, [name], ['asc']); 
    setListRows(sortedList);  
  }

  useEffect(() => {
    let listRows = createList(data); 
    setListRows(listRows); 
  }, [data])

  const setCustomName = (name, exception, customName) => {
    return name === exception ? customName : name
  }

/*   if(!listRows.length) {
    let listRows = createList(data); 
    setListRows(listRows); 
  } */

  const [isUpload, setIsUpload]=useState(""); 

  useEffect(() => {
    setListRows(createList(data)); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable]); 

  return (
    <TableContainer component={Paper} elevation={0} className={classes.root}>
      {isSearch && <div className={classes.wrapperSearch}>
        <SearchTextField 
          searchValue={searchValue} 
          handler={handlerClick}
          isClientManager={isClientManager}
          handleChange={handleChangeSearch}/>
      </div>}
      <div className={!isCustom ? classes.innerTable : ''}>
         
        <Table className={classes.table} aria-label="simple table">
      
          <TableHead>
            
            {isFee && <TableRow>
              {listName.map((item, idx) => <TableCell 
                 className={item === 'description' || item === 'duration(h)' ? classes.select_cell : ''} 
                key={item} style={{borderBottom: 'none'}} align={align}>
              <div className={idx !== listName.length - 1 ? classes.cellInner : classes.cellInner_fee}>
                {item === "date" && !isOpenFee && <ButtonBack
                    small_size={12} 
                    fontSize={15} 
                    height={25}
                    width={66}
                    isCustom
                    onClick={updateFee}
                    customStyle={{
                    textTransform: 'uppercase',
                    backgroundColor: `${colors.orange}`, 
                    fontWeight: 'bold'}}>
                    + Fee
                  </ButtonBack>}
                  {item === " " &&  <div className={classes.fee_icons}>
                    <img src={message_icon} alt="" />
                    <img src={file_icon} alt="" />
                  </div>}
              </div>
              </TableCell>)}
            </TableRow>}

            {isAddRow && !addRow && <TableRow>
              {listName.map((item, idx) => <TableCell key={item} style={{borderBottom: 'none'}} align={align}>
              <div className={`${align === 'center' ? classes.cellInner : classes.spacing_left_body}`}>
                {item === listName[0] && <ButtonBack
                    small_size={12} 
                    fontSize={15} 
                    height={25}
                    width={115}
                    onClick={addRowHandler}
                    customStyle={{
                    textTransform: 'uppercase',
                    backgroundColor: color_btn, 
                    fontWeight: 'bold'}}>
                     {title_btn}
                  </ButtonBack>}
              </div>
              </TableCell>)}
            </TableRow>}

            {isTopText && <TableRow >
              {listName.map((item, idx) => <TableCell key={item} style={{borderBottom: 'none'}} align={idx === listName.length -1 ? "right" : "center"}>
              <div style={{paddingTop:'16px'}} className={idx === listName.length -1 ? classes.cellInner_right : classes.cellInner}>
                {item === listName[0] && <div className={classes.schedule_text}>FEE SCHEDULE <img height={21} src={new_send_icon} alt=""/> </div>}
              </div>
              </TableCell>)}
            </TableRow>}

            {isAdministration && <TableRow >
              {listName.map((item, idx) => <TableCell key={item} style={{borderBottom: 'none'}} align={idx === listName.length -1 ? "right" : "center"}>
              <div style={{paddingTop:'16px'}} className={idx === listName.length -1 ? classes.cellInner_right : classes.cellInner}>
                {item === listName[0] && <ButtonBack
                    small_size={12} 
                    fontSize={15} 
                    height={34}
                    width={105}
                    isCustom
                    type="button"
                    onClick={() => handlerClick()}
                    customStyle={{
                    fontFamily: 'Avenir Next Bold',
                    textTransform: 'uppercase',
                    backgroundColor: color_btn, 
                    fontWeight: 'bold'}}>
                    <span className={"mark_plus"}><span className="inner_mark">+</span></span>
                    <span className={classes.title_btn}>{title_btn}</span>
                  </ButtonBack>}
                  {idx === listName.length - 1 &&  isAdminSearch && <div className={classes.search_admin_wrap}>
                    <SearchTextField 
                      isClientManager={isClientManager}
                      searchValue={searchValue} 
                      handler={handleSearch}
                      handleChange={handleChangeSearch}/>
                  </div>}
              </div>
              </TableCell>)}
            </TableRow>}


          { type === 'agent' && <TableRow >
              {listName.map((item, idx) => <TableCell style={{paddingTop: '31px'}} align={idx === 1 ? "left" : "center"} key={item} >
               {item !==  " " 
                ? <div className={`${(idx === 0 || idx === 2) ? classes.cellInner_fee : classes.cellInner} ${idx === 1 ? classes.agent_custom : ''} ${idx === 0 ? classes.agent_custom_first : ''}`}>
                    {formatName(item)}
                    <span className={classes.filter} onClick={() => handleSort(item)}>
                      {isFilter && <>
                        <ArrowUP 
                           side={2} 
                           base={4} 
                           customStyle={{marginBottom: '1px'}}/>
                        <ArrowDown 
                          side={2} 
                          base={4} 
                          customTop={{marginBottom: '1px'}}/> 
                        </>}
                      </span>
                    </div> 
                  :  <div className={`${classes.cellInner_right} ${classes.padding_cell}`}>        
                      <SearchTextField 
                        searchValue={searchValue} 
                        isClientManager={isClientManager}
                        handler={handlerClick}
                        handleChange={handleChangeSearch}/> 
                      <ButtonBack
                        height={30}
                        width={163}
                        fontSize={15}
                        customStyle={{marginLeft: '15px'}}
                        onClick={handleAssign}
                      >
                        Bulk Assign
                      </ButtonBack>  
                      </div> }        
                </TableCell>)}
            </TableRow>}

            { type === 'base' && <TableRow>
              {listName.map((item, idx) => <TableCell align={align} key={item} >
                <div className={`${align === 'center' ? classes.cellInner : classes.spacing_left} ${isBaseText ? 'baseText' : ''}`}>
                  {formatName(item)}
                  <span className={classes.filter} onClick={() => handleSort(item)} >
                    {isFilter && item !== "" && item !== " " && <>
                      <ArrowUP 
                        side={3} 
                        base={5} 
                        customStyle={{marginBottom: '1px'}}/>
                      <ArrowDown 
                        side={3} 
                        base={5} 
                        customTop={{marginBottom: '1px'}}/> 

                      </>}
                      {isFilterOne && idx === 0 && <>
                      <ArrowUP side={3} base={5} customStyle={{marginBottom: '1px'}}/>
                      <ArrowDown side={3} base={5} customTop={{marginBottom: '1px'}}/> 
                      </>}
                    </span>
                </div>              
                </TableCell>)}
            </TableRow>}

          </TableHead>


          <TableBody>

            {isFee && isOpenFee && <TableRow  
              style={{
               cursor: handlerSelectRow ? 'pointer' : ''}}>
              {listName.map(name => <TableCell
                 align={align} 
                 key={name}>
                 {name === 'date' &&  <SelectDate isFee title="" size={30}/>}
                 {name === 'Activity/description' && <SelectComponent
                   width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   handlerSelect={handlerSelect} 
                   selected={values.activity_description}
                   list={listFeeDescOptions}/>}
                 {name === 'duration(h)' && <SearchTextField 
                   title="Type"
                   isFee
                   width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   isSearch={false}/>}
                  {name === ' ' && <div className={classes.fee_icons}>
                     <img src={save_icon_orange} alt="" size={22} onClick={() => console.log('sacvin')}/>
                     <img src={delete_icon} alt="" size={22} onClick={handleClose}/>
                   </div>}
                   {(name === 'code' || name === 'price') &&  <SearchTextField 
                   title=""
                   //isFee
                   name={name === 'Activity/description' ? "activity_description" : name}
                   error={errors[setCustomName(name, 'Activity/description', "activity_description")] && touched[setCustomName(name, 'Activity/description', "activity_description")]}
                   value={name === 'Activity/description' ? values.activity_description :  values[name]}
                   handleChange={handleChange}
                   //width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   isSearch={false}/>}
          
                   {name === '' && <div >
                     <img src={save_icon_orange} style={{margin: "0 5px", marginRight: "3px"}} height="25px" width="25px"  alt="" onClick={addRowHandler}/>
                     <img style={{margin: "0 5px"}} src={delete_icon} alt=""  onClick={()=> closeRow("delete")}/>
                   </div> }
                </TableCell>)}
              </TableRow>}

              {/* {isAddRow && addRow && isFee && <TableRow  
                style={{
               cursor: handlerSelectRow ? 'pointer' : ''}}>
              {listName.map((name, idx) => <TableCell  

                align={align} key={name}>
                {idx !== 1 ? <SearchTextField 
                    title="add row"
                    isSearch={false}/> : null}
                 
                 {name === ' ' && <div className={classes.fee_icons}>
                     <img src={save_icon_orange} alt="" onClick={handleClose}/>
                     <img src={delete_icon} alt="" onClick={handleClose}/>
                   </div>}
                </TableCell>)}
              </TableRow>} */}

              {isAddRow && addRow && !isNoteType && !isFee && <TableRow  
                style={{
               cursor: handlerSelectRow ? 'pointer' : ''}}>
              {listName.map((name, idx) => <TableCell  

                align={align} key={name}>
                {name !== ' ' && <SearchTextField 
                    title="add row"
                    isSearch={false}/>}
                 
                 {name === ' ' && <div className={classes.fee_icons}>
                     <img src={save_icon_orange} alt="" onClick={handleClose}/>
                     <img src={delete_icon} alt="" onClick={handleClose}/>
                   </div>}
                </TableCell>)}
              </TableRow>}

              {isAddRow && addRow && isNoteType && !isFee && <TableRow  
                style={{
               cursor: handlerSelectRow ? 'pointer' : ''}}>
              {listName.map((name, idx) => <TableCell 
                style={{position: 'relative', paddingRight: '10px'}} 
                align={align} key={name}>
                {idx === 0 && <div className={classes.wrap_select_20}>
                  <SelectComponent
                   width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   color={colors.blue}
                   handlerSelect={handlerSelect} 
                   list={listFeeSelect}/> 
                </div>
                }
                {idx === 1 && <div className={classes.wrap_select_20}>
                  <SelectComponent
                   width={window.innerWidth >= 1920 ? '140px' : '80%'}
                   color={colors.blue}
                   handlerSelect={handlerSelect} 
                   list={listFeeSelect}/>
                </div>  }
                {idx === 2 && <Grid container justifyContent="flex-start">
                  <SearchTextField 
                    customStyle={{marginLeft: '-2px'}}
                    title="date"
                    searchValue={listRows[0][name]}
                    isSearch={false}/>
                </Grid> }
                {idx === 3 && <div className={classes.spacing_left_body}>{listRows[0][name]}</div>}
                {idx === 4 && <div className={classes.wrapNotes}>
                  <SearchTextField 
                    title="type"
                    isLeft
                    searchValue={'Type'}
                    customStyle={{marginRight: '-50px'}}
                    width={'100%'}
                    isSearch={false}/>
                  </div>}
                 
                 {idx === listName.length - 1 && <div className={classes.fee_icons} style={{justifyContent: 'flex-end'}}>
                 <span className="upload_name">{isUpload}</span>
                     <img 
                       src={attach_icon} 
                       alt="" 
                       onClick={handleUpload}/>
                     <input 
                       className="upload" 
                       type="file" 
                       ref={uploadRef}
                       onChange={()=> setIsUpload(uploadRef.current.value)}
                       name="somename" 
                       size="chars"/>
                     <img 
                       height={21} 
                       src={save_icon} 
                       alt="" 
                       onClick={handleClose}
                       style={{transform: 'none', height: '23px'}}
                       />
                   </div>}
                </TableCell>)}
              </TableRow>}

            {type === "base" && listRows.map((item, idx) => <TableRow
             onClick={() => selectRow(item, idx)} 
             style={{
               cursor: handlerSelectRow ? 'pointer' : '',
               backgroundColor: isNoteType && statusPendingColor[item.status]
              }}
             key={idx} 
             className={generateClassNameRow({idx})}>
              {idx !== selectId && listName.map((name, index) => <TableCell 
                align={align} 
                className={`${generateClassName({idx, item, idx2: index})}`}
                style={{
                  color: getColor(name, idx),
                }}
                key={name}>
     
               

                  <div className={`${align === 'left' ? classes.spacing_left_body : ''}`}>
                  {index === 0 && isCheckbox && <CheckboxBase/>}
                    {typeof item[name] === 'number' ?       
                    item[name].toFixed(2) : item[name]}</div>

                  {isFee && item[name] === "" && name !==  'duration(h)' && <div className={classes.fee_icons}>
                     <img src={edit_icon} alt="" onClick={() => handlerClick(idx)}/>
                     <img src={delete_icon} alt=""/>
                   </div>}
                </TableCell>)}


              {idx === selectId && isFee && isEdit && listName.map(name => <TableCell
                 align={align} 
                 key={name}>
                {name !== "id" && name !== " " && name !== '' && item[name] && name !== 'Activity/description' && <SearchTextField
                      name={ name}
                      error={errors[name] && touched[name]}
                      //width={'100%'}
                    /*   customStyle={{justifyContent: 'flex-start', marginLeft: '10px', marginRight: '10px'}} */
                      value={values[name] === '' ? data[idx][name] : values[name]}
                      handleChange={handleChange}
                      title={name === 'Activity/description' ? values.activity_description :  values[name]}
                      isSearch={false}/>}
                {name !== "id" && name !== " " && name !== '' && item[name] && name === 'Activity/description' && <SelectComponent
                   width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   handlerSelect={handlerSelect} 
                   selected={name === 'Activity/description' ? values.activity_description :  values[name]}
                   list={listFeeDescOptions}/>}
                 {name === ' ' || name === '' && <div className={classes.fee_icons}>
                     <img 
                       src={save_icon_orange} 
                       alt="" 
                       style={{margin: "0 5px", marginRight: "3px"}} 
                       height="25px" 
                       width="25px" 
                       onClick={() => saveUpdates(selectId)}/>
                     <img
                       onClick={() => rowDelete(selectId)} 
                       style={{margin: "0 5px"}} 
                       src={delete_icon} 
                       alt=""/>
                   </div>}
                </TableCell>)}

                {idx === selectId && isNoteType && isEdit && listName.map((name, idx) => <TableCell  
                style={{position: 'relative', paddingRight: '10px', textAlign: 'left'}}
                align={align} key={name}>
                {idx === 0 && <div className={classes.wrap_select_20}> 
                  <SelectComponent
                   width={window.innerWidth >= 1920 ? '117px' : '80%'}
                   handlerSelect={handlerSelect} 
                   title={listRows[idx][name]}
                   color={colors.blue}
                   list={listFeeSelect}/>
                </div>  }
                {idx === 1 && <div className={classes.wrap_select_20}>
                  <SelectComponent
                   width={window.innerWidth >= 1920 ? '140px' : '80%'}
                   handlerSelect={handlerSelect} 
                   title={listRows[idx][name]}
                   color={colors.blue}
                   list={listFeeSelect}/> 
                </div> }
                {idx === 2 &&
                <Grid container justifyContent="flex-start">
                 <SearchTextField 
                    title="date"
                    customStyle={{marginLeft: '-2px'}}
                    searchValue={listRows[0][name]}
                    isSearch={false}/>
                </Grid>
           }
                {idx === 3 && <div className={classes.spacing_left_body}>{listRows[0][name]}</div>}
                {idx === 4 && <div className={classes.wrapNotes}>
                  <SearchTextField 
                    title="type"
                    isLeft
                    searchValue={listRows[0][name]}
                    customStyle={{marginRight: '-50px'}}
                    width={'100%'}
                    isSearch={false}/>
                </div> }
                 
                 {idx === listName.length - 1 && <div className={classes.fee_icons} style={{justifyContent: 'flex-end'}}>
                    <span className="upload_name">{isUpload}</span>
                     <img 
                       src={attach_icon} 
                       alt="" 
                       onClick={handleUpload}/>
                     <input 
                       className="upload" 
                       type="file" 
                       ref={uploadRef}
                       onChange={()=> setIsUpload(uploadRef.current.value)}
                       name="somename" 
                       size="chars"/>
                     <img
                      style={{transform: 'none', height: '23px'}} 
                      src={save_icon} 
                      alt="" 
                      onClick={handleClose}/>
                   </div>}
                </TableCell>)}
                
            </TableRow>)}

            {type === "agent" && listRows.map((item, idx) => <TableRow
             onClick={() => selectRow(item, idx)} 
             style={{
               cursor: handlerSelectRow ? 'pointer' : ''}}
             key={idx} 
             className={generateClassNameRow({idx})}>
              {listName.map((name, index) => <TableCell 
                align={generateAlignAgent(index, listName.length - 1)}
                className={index === 0 ? classes.checkbox : ''}
                key={name}>
                  {index=== 0 && item[name] && <CheckboxBase onChange={handleChecked} name={item.agents_name}/>}
                  {typeof item[name] === 'number' ? item[name].toFixed(2) : item[name]}
                  
                </TableCell>)}
            </TableRow>)}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
