import { useEffect } from "react";
import { useModal } from "./useModal";
import { useStopwatch } from "react-timer-hook";
import ActiveBreak from "../../components/modal/ActiveBreak";
import { useVoip } from "./useVoip";
import { useSelector } from "react-redux";

const useActivityTracker = () => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    reset,
  } = useStopwatch({ autoStart: true });
  const { updateWorkerAvailability } = useVoip();
  const { setToast, setModal, currentModal } = useModal();
  const user_type_id = useSelector((state) => state.auth.user_type_id);


  const openModal = () => {
    if(user_type_id === 4){
      updateWorkerAvailability("Idle");
      setModal({
        Component: <ActiveBreak breakType={{ id: 8, name: 'Idle User' }} />,
        size: 'sml',
        canClickAway: false,
        closeBtn: false,
      })
    }
  }

  useEffect(() => {
    if (seconds >= 300 && !currentModal.Component) {
      openModal();
    }
  }, [seconds]);

  return {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset
  };
};

export { useActivityTracker };
