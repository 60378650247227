import { AuthService } from "../../api/services";
import * as ACTION_TYPES from "../types/auth.types";

const initialState = {
    id: null,
    isLoggedIn: AuthService.isLoggedIn(),
    name: "",
    email: "",
    password: "",
    user_type_id: 4,
    profile_pic_url: "",
    error: "",
    permissions: [],
    worker_id: "",
    first_log_in: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_SUCCESS:
            return { ...state, ...action.payload, isLoggedIn: true };
        case ACTION_TYPES.UNSET_PERMISSIONS:
            return { ...state, permissions: [] };
        default:
            return state;
    }
};

export default authReducer;
